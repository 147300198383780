import React from 'react';

//MATERIAL-UI
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

//CSS
import styles from './loading.module.css';

function LoadingPage(props) {
  return (
    <Backdrop className={styles.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingPage;
