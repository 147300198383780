import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

export const depositSlice = createSlice({
  name: "deposit",
  initialState: initialState.deposit,
  reducers: {
    setData: (state, action) => {
      state.product = { ...action.payload };
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setChanges: (state, action) => {
      state.changes.splice(0, state.changes.length);
      state.changes.push(...action.payload);
    },
    clearChanges: (state) => {
      state.changes.splice(0, state.changes.length);
    },
    setFilters: (state, action) => {
      state.order = action.payload.order;
      state.orderBy = action.payload.orderBy;
      state.page = action.payload.page;
      state.rowsPerPage = action.payload.rowsPerPage;
    },
  },
});

export const { setData, setMode, setChanges, setFilters, clearChanges } =
  depositSlice.actions;

export const selectDeposit = (state) => state.deposit;
export const selectModeDeposit = (state) => state.deposit.mode;
export const selectProductDeposit = (state) => state.deposit.product;
export const selectChangesDeposit = (state) => state.deposit.changes;
export const selectOrderDeposit = (state) => state.deposit.order;
export const selectOrderByDeposit = (state) => state.deposit.orderBy;
export const selectPageDeposit = (state) => state.deposit.page;
export const selectRowsPerPageDeposit = (state) => state.deposit.rowsPerPage;

export default depositSlice.reducer;
