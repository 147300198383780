import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const UsersSettingsPage = lazy (() =>
  import ('../components/settings/users/users.component')
);

export default function PickersSettingsRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <UsersSettingsPage />
    </Suspense>
  );
}
