import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const OrdersPage = lazy(() => import("../containers/orders"));

const OrderInfoPage = lazy(() => import("../components/orders/info.component"));

const CreateOrderPage = lazy(() =>
  import("../components/orders/createOrder.component")
);

export function OrdersRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <OrdersPage />
    </Suspense>
  );
}

export function OrderInfoRoute({ path }) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <OrderInfoPage />
    </Suspense>
  );
}

export function CreateOrderRoute({ path }) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <CreateOrderPage />
    </Suspense>
  );
}
