import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const Page = lazy (() => import ('../containers/inventary'));

function InventaryRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default InventaryRoute;
