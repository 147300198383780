import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadingOff, loadingOn } from "../../reducers/uiSlice";

const useLoadingState = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => {
    dispatch(loadingOn());
    setIsLoading(true);
  };

  const stopLoading = () => {
    dispatch(loadingOff());
    setIsLoading(false);
  };

  return { isLoading, startLoading, stopLoading };
};

export default useLoadingState;
