import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const Page = lazy (() => import ('../containers/subsidiary/subsidiary'));

export default function SubsidiaryRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}
