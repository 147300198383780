import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";
const Page = lazy(() => import("../components/dnd"));

function DndRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default DndRoute;
