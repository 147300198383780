import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';

export const billingSlice = createSlice({
  name: 'billing',
  initialState: initialState.billing,
  reducers: {},
});

export const selectBilling = (state) => state.billing;

export default billingSlice.reducer;
