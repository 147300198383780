import config from "../config";
import { get, post } from "../http";

const getHeaders = (token = "") => {
  const headers = {};
  headers["Content-Type"] = "application/json";
  if (token !== "") headers["Authorization"] = `Bearer ${token}`;
  return headers;
};
export async function getHistory(token, companyId, loadingLogo = false) {
  return await get(
    `${config.companyMicroserviceUrlApi}/imgHistory/?companyId=${companyId}&loadingLogo=${loadingLogo}`,
    getHeaders(token)
  );
}

export async function create({ url, companyId, alt }) {
  return await post(
    `${config.companyMicroserviceUrlApi}/imgHistory/`,
    getHeaders,
    {
      data: {
        Url: url,
        CompanyId: companyId,
        CreatedDate: new Date(),
        Alt: alt,
      },
    }
  );
}
