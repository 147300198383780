import { verify, sign } from "jsonwebtoken";
import config from "./config";

export const set = (data) => {
  const localData = get(false);
  var token = sign({ ...localData, ...data }, config.jwtSecret);
  localStorage.setItem("lumarketo", JSON.stringify({ token }));
};

export const get = (decryptUser = false) => {
  try {
    const lumarketo = JSON.parse(localStorage.getItem("lumarketo"));
    const result = verify(lumarketo.token, config.jwtSecret);
    const user = decryptUser
      ? result.user
        ? verify(result.user, config.jwtSecret)
        : undefined
      : result.user;
    return { ...result, user };
  } catch (error) {
    return null;
  }
};

const storage = {
  get,
  set,
};

export default storage;
