import { useState, useRef, Fragment } from "react";
import SideBySideMagnifier from "react-image-magnifiers/dist/SideBySideMagnifier";

//MATERIAL-UI
import Skeleton from "@material-ui/lab/Skeleton";
import Hidden from "@material-ui/core/Hidden";

//IMPORTS
import ImageLoader from "react-load-image";
import useIntersectionObserver from "../../utils/hooks/useIntersectionObserver";

const ImageSkeleton = ({
  styles,
  src,
  alt,
  handleClick,
  useZoom = false,
  width,
  skeletonWidth = 150,
  skeletonHeight = 150,
  height,
  noImg = "/",
}) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const alwaysInPlace = false,
    overlayOpacity = 0.6,
    switchSides = false,
    fillAvailableSpace = false,
    fillAlignTop = false,
    fillGapLeft = 0,
    fillGapRight = 10,
    fillGapTop = 10,
    fillGapBottom = 10;

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting && ref.current) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

  const Preloader = () => (
    <Skeleton
      className={styles}
      animation="wave"
      height={skeletonHeight}
      width={skeletonWidth}
    />
  );

  const Error = () => (
    <img src={noImg} alt="" className={styles} width={width} height={height} />
  );

  const Image = ({ src, alt } = {}) =>
    useZoom ? (
      <Fragment>
        <Hidden xsDown>
          <SideBySideMagnifier
            className="input-position"
            style={{ order: switchSides ? "1" : "0" }}
            imageSrc={src}
            imageAlt={alt}
            alwaysInPlace={alwaysInPlace}
            overlayOpacity={overlayOpacity}
            switchSides={switchSides}
            zoomPosition="left"
            inPlaceMinBreakpoint={641}
            fillAvailableSpace={fillAvailableSpace}
            fillAlignTop={fillAlignTop}
            fillGapTop={fillGapTop}
            fillGapRight={fillGapRight}
            fillGapBottom={fillGapBottom}
            fillGapLeft={fillGapLeft}
            zoomContainerBorder="1px solid #ccc"
            zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
            cursorStyle="zoom-in"
          />
        </Hidden>
        <Hidden smUp>
          <img
            src={src}
            alt={alt}
            className={styles}
            width={width}
            height={height}
          />
        </Hidden>
      </Fragment>
    ) : (
      <img
        src={src}
        alt={alt}
        className={styles}

        width={width}
        height={height}
      />
    );

  return (
    <div ref={ref} onClick={() => (handleClick ? handleClick() : null)}>
      {isVisible ? (
        <ImageLoader src={src}>
          <Image alt={alt} />
          <Error />
          <Preloader />
        </ImageLoader>
      ) : (
        <Preloader />
      )}
    </div>
  );
};
export default ImageSkeleton;
