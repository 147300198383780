import { lazy, Suspense } from 'react';
import LoadingPage from '../containers/loading';
const Page = lazy(() => import('../components/settings/companyPayment'));

function CompanyPaymentSettingsRoute() {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page />
        </Suspense>
    );
}

export default CompanyPaymentSettingsRoute;
