import config from "../config";
import { get, put, Delete, post } from "../http";

async function getByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.customerServiceMicroserviceUrlApi}/customer-service/getByCompany?companyId=${id}`,
    headers
  );
}

async function create(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...data },
  };
  return await post(
    `${config.customerServiceMicroserviceUrlApi}/customer-service/`,
    headers,
    body
  );
}

async function update(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data,
  };
  return await put(
    `${config.customerServiceMicroserviceUrlApi}/customer-service/`,
    headers,
    body
  );
}

async function DeleteBrand(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...data },
  };
  return await Delete(
    `${config.customerServiceMicroserviceUrlApi}/customer-service/`,
    headers,
    body
  );
}

async function getStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.customerServiceMicroserviceUrlApi}/system/status`,
    headers
  );
}
const functions = {
  getByIdCompany,
  create,
  DeleteBrand,
  update,
  getStatus,
};

export default functions;
