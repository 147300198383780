import Axios from "axios";
import uuid from "react-uuid";
import config from "../config";
import { get, put, post, Delete, patch } from "../http";

const getHeaders = (token = "") => {
  const headers = {};
  headers["Content-Type"] = "application/json";
  if (token !== "") headers["Authorization"] = `Bearer ${token}`;
  return headers;
};

const getSKUNumber = async (idCompany, token) =>
  await get(
    `https://api.lumarketo.cl/getNewSKU?companyId=${idCompany}`,
    getHeaders(token)
  );

const getAWSConfig = async (id, token) =>
  await get(
    `${config.companyMicroserviceUrlApi}/company/getAWSConfig?id=${id}`,
    getHeaders(token)
  );

async function setSKUNumber(idCompany, SKUNumber, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    SKUNumber,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/company/setSKUNumber?id=${idCompany}`,
    headers,
    body
  );
}

async function setShippingCost(idCompany, ShippingCost, FreeShipping, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ShippingCost,
    FreeShipping,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/company/setShippingCost?id=${idCompany}`,
    headers,
    body
  );
}

export async function setSEO(idCompany, SEO, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ...SEO,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/company/setSEO?id=${idCompany}`,
    headers,
    body
  );
}

export async function updateCompany(idCompany, body, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/company/update?id=${idCompany}`,
    headers,
    body
  );
}

export async function getCertificateStatus(id, arn, token) {
  return await get(
    `${config.companyMicroserviceUrlApi}/company/getCertificateStatus?id=${id}&arn=${arn}`,
    getHeaders(token)
  );
}

export async function validateDNS(idCompany, domain, token) {
  return await post(
    `${config.companyMicroserviceUrlApi}/company/validateDNS?domain=${domain}&companyId=${idCompany}`,
    getHeaders(token),
    {}
  );
}

export async function validateChangesOnTemplate(idCompany, token) {
  return await get(
    `${config.companyMicroserviceUrlApi}/template/validateChangesOnTemplate?companyId=${idCompany}`,
    getHeaders(token),
    {}
  );
}

export async function createClientDistribution(idCompany, domain, token) {
  return await post(
    `${config.companyMicroserviceUrlApi}/company/createClientDistribution?domain=${domain}&companyId=${idCompany}`,
    getHeaders(token),
    {}
  );
}

export async function deleteDemoDistribution(idCompany, domain, token) {
  return await post(
    `${config.companyMicroserviceUrlApi}/company/deleteDemoDistribution?domain=${domain}&companyId=${idCompany}`,
    getHeaders(token),
    {}
  );
}

export async function getCompanyStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.companyMicroserviceUrlApi}/system/status`,
    headers
  );
}

export async function update(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/company/`,
    headers,
    body
  );
}

export async function getByIdCompany(id, expirationFilter = false) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.companyMicroserviceUrlApi}/company/getByIdCompany?id=${id}&expirationFilter=${expirationFilter}`,
    headers
  );
}

export async function setColors(idCompany, Colors, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    Colors,
  };
  return await post(
    `${config.companyMicroserviceUrlApi}/company/setColors?id=${idCompany}`,
    headers,
    body
  );
}

export async function setTypography(idCompany, typography, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    Typography: typography,
  };
  return await post(
    `${config.companyMicroserviceUrlApi}/company/setColors?id=${idCompany}`,
    headers,
    body
  );
}

export const getSubsidiary = async (subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.companyMicroserviceUrlApi}/subsidiary/?id=${subsidiaryId}`,
    headers
  );
};

export const getFonts = async (cancelToken) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.companyMicroserviceUrlApi}/font/all`,
    headers,
    {},
    true,
    cancelToken
  );
};

export const createFont = async (data, token) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await post(`${config.companyMicroserviceUrlApi}/font/`, headers, {
    ...data,
  });
};

export const getCompanyBySubdomain = async (name, cancelToken) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/subdomain/?name=${name}`,
    headers,
    cancelToken
  );
};

export const setTemplate = async (companyId, templateId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `${config.companyMicroserviceUrlApi}/template/setTemplateDataToCompany?companyId=${companyId}&templateId=${templateId}`,
    headers
  );
};

export const setOldTemplate = async (companyId, templateHistoryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `${config.companyMicroserviceUrlApi}/template/setOldTemplateFromHistoryToCompany?companyId=${companyId}&templateHistoryId=${templateHistoryId}`,
    headers
  );
};

export const getTemplateHistory = async (templateHistoryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/template/getTemplateHistoryData?templateHistoryId=${templateHistoryId}`,
    headers,
    {}
  );
};

export const updateTemplateHistory = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(
    `${config.companyMicroserviceUrlApi}/template/updateTemplateHistoryData`,
    headers,
    { ...data }
  );
};

export const getTemplates = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(`${config.companyMicroserviceUrlApi}/template/all`, headers);
};

export const getAllHistory = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/template/allHistory?companyId=${companyId}`,
    headers
  );
};

export const availableDynamicPageName = async (
  name,
  companyId,
  cancelToken = uuid()
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/dynamicPage/available?companyId=${companyId}&name=${name}`,
    headers,
    {},
    true,
    cancelToken
  );
};

export const createDynamicPage = async ({
  companyId,
  mode,
  name,
  url,
  components,
  margin,
  padding,
  gridGap,
  brand,
  skus,
  allSkus,
  activate,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `${config.companyMicroserviceUrlApi}/dynamicPage`,
    headers,
    {
      data: {
        companyId,
        mode,
        name,
        url,
        components,
        margin,
        padding,
        gridGap,
        brand,
        skus,
        allSkus,
        activate,
      },
    }
  );
};

export const createTemplateHistory = async (companyId, templateId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `${config.companyMicroserviceUrlApi}/template/createTemplateHistoryData?companyId=${companyId}&templateId=${templateId}`,
    headers
  );
};

export const getPlans = async (type) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(`https://api.lumarketo.cl/getPlans?type=${type}`, headers);
};

export const getPlan = async (id) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(`https://api.lumarketo.cl/getPlan?id=${id}`, headers);
};

export const createSuscriptionPlan = async ({
  companyId,
  userId,
  method,
  planId,
  externalId,
  json,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/createSuscriptionPlan`, headers, {
    companyId,
    userId,
    method,
    planId,
    externalId,
    json,
  });
};

export const deleteSuscriptionPlan = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(
    `https://api.lumarketo.cl/deleteSuscriptionPlan`,
    headers,
    {
      companyId,
    }
  );
};

export const getSuscriptionPlan = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/getSuscriptionPlan?id=${companyId}`,
    headers
  );
};

export const getFlowConfig = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/getFlowConfig?id=${companyId}`,
    headers
  );
};

export const getDynamicPages = async (
  companyId,
  cancelToken = Axios.CancelToken.source().token
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/dynamicPage/getByIdCompany?id=${companyId}`,
    headers,
    {},
    true,
    cancelToken
  );
};

export const getDynamicPage = async (url, companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/dynamicPage/getFromUrl?url=${url}&companyId=${companyId}`,
    headers
  );
};

export const updateDynamicPage = async ({
  companyId,
  mode,
  name,
  url,
  components,
  margin,
  padding,
  gridGap,
  brand,
  skus,
  allSkus,
  activate,
  _id,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`${config.companyMicroserviceUrlApi}/dynamicPage`, headers, {
    data: {
      companyId,
      mode,
      name,
      url,
      components,
      margin,
      padding,
      gridGap,
      _id,
      brand,
      skus,
      allSkus,
      activate,
    },
  });
};
export const deleteDynamicPage = async ({ _id }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(
    `${config.companyMicroserviceUrlApi}/dynamicPage`,
    headers,
    {
      data: {
        _id,
      },
    }
  );
};

export const createCustomWebComponent = async (component, token) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `https://api.lumarketo.cl/createCustomWebComponent`,
    headers,
    {
      component,
      token,
    }
  );
};

export const getCustomWebComponents = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(`https://api.lumarketo.cl/getCustomWebComponents`, headers);
};

export const verifyCustomWebComponentToken = async (token) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/verifyCustomWebComponentToken?token=${token}`,
    headers
  );
};

export const createTemplate = async ({ Name, Img, Home }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `https://api.lumarketo.cl/serverless-company/company-ms/template`,
    headers,
    {
      data: {
        Name,
        Img,
        Home,
      },
    }
  );
};

export const createHeader = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/header`, headers, {
    data,
  });
};

export const getHeader = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(`https://api.lumarketo.cl/header?id=${companyId}`, headers);
};

export const getAllHeader = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/header/all?id=${companyId}`,
    headers
  );
};

export const deleteHeader = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`https://api.lumarketo.cl/header`, headers, {
    data,
  });
};

export const updateHeader = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`https://api.lumarketo.cl/header`, headers, {
    data,
  });
};

export const createOrUpdateShutdownSubsidiary = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `https://api.lumarketo.cl/createOrUpdateShutdownSubsidiary`,
    // "https://api.lumarketo.cl/createOrUpdateShutdownSubsidiary",
    headers,
    {
      ...data,
    }
  );
};

export const getShutdownSubsidiary = async (subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/getShutdownBySubsidiary?subsidiaryId=${subsidiaryId}`,
    headers
  );
};

export const createFudo = async ({
  companyId,
  clientId,
  clientSecret,
  productIDForShippingCost,
  useShippingCost,
  paymentId,
  isTest,
  subsidiaryId,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/fudo`, headers, {
    companyId,
    clientId,
    clientSecret,
    productIDForShippingCost,
    useShippingCost,
    paymentId,
    isTest,
    subsidiaryId,
  });
};

export const updateFudo = async ({
  companyId,
  clientId,
  clientSecret,
  productIDForShippingCost,
  useShippingCost,
  paymentId,
  isTest,
  subsidiaryId,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`https://api.lumarketo.cl/fudo`, headers, {
    companyId,
    clientId,
    clientSecret,
    productIDForShippingCost,
    useShippingCost,
    paymentId,
    isTest,
    subsidiaryId,
  });
};

export const getFudo = async (companyId, subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/fudo/getFudoBySubsidiary?companyId=${companyId}&subsidiaryId=${subsidiaryId}`,
    headers
  );
};

export const deleteFudo = async (companyId, subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`https://api.lumarketo.cl/fudo/`, headers, {
    companyId,
    subsidiaryId,
  });
};

export const createIngefactura = async ({
  companyId,
  token,
  isTest,
  subsidiariesInfo,
  razonSocial,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/ingefactura`, headers, {
    companyId,
    token,
    isTest,
    subsidiariesInfo,
    razonSocial,
  });
};

export const updateIngefactura = async ({
  companyId,
  token,
  isTest,
  subsidiariesInfo,
  razonSocial,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`https://api.lumarketo.cl/ingefactura`, headers, {
    companyId,
    token,
    isTest,
    subsidiariesInfo,
    razonSocial,
  });
};

export const getIngefactura = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/ingefactura?id=${companyId}`,
    headers
  );
};

export const deleteIngefactura = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`https://api.lumarketo.cl/ingefactura/`, headers, {
    companyId,
  });
};

export const createOrderFudo = async (companyId, orderId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/fudo/order`, headers, {
    companyId,
    orderId,
  });
};

export const createOrderToteat = async (companyId, orderId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/toteat/order`, headers, {
    companyId,
    orderId,
  });
};

export const createToteat = async ({
  companyId,
  restaurantId,
  localId,
  userId,
  token,
  isTest,
  subsidiaryId,
  paymentType,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/toteat`, headers, {
    companyId,
    restaurantId,
    localId,
    userId,
    token,
    isTest,
    subsidiaryId,
    paymentType,
  });
};

export const updateToteat = async ({
  companyId,
  restaurantId,
  localId,
  userId,
  token,
  isTest,
  subsidiaryId,
  paymentType,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`https://api.lumarketo.cl/toteat`, headers, {
    companyId,
    restaurantId,
    localId,
    userId,
    token,
    isTest,
    subsidiaryId,
    paymentType,
  });
};

export const getToteat = async (companyId, subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/toteat/getToteatBySubsidiary?companyId=${companyId}&subsidiaryId=${subsidiaryId}`,
    headers
  );
};

export const deleteToteat = async (companyId, subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`https://api.lumarketo.cl/toteat/`, headers, {
    companyId,
    subsidiaryId,
  });
};

export const getPolygon = async (subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.baseUrl}/polygon?subsidiaryId=${subsidiaryId}`,
    headers
  );
};

export const createPolygon = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return post(`${config.baseUrl}/polygon`, headers, data);
};

export const updatePolygon = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`${config.baseUrl}/polygon`, headers, data);
};

export const deletePolygon = async (_id) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return Delete(`${config.baseUrl}/polygon`, headers, {
    _id,
  });
};

export const getAllQrSurvey = async (companyId, byDate, startDate, endDate) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/qrsurvey?companyId=${companyId}&byDate=${byDate}&startDate=${startDate}&endDate=${endDate}`,
    headers
  );
};

export const createMailChimp = async ({ companyId, apiKey }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/mailchimp`, headers, {
    companyId,
    apiKey,
  });
};

export const updateMailChimp = async ({ companyId, apiKey }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`https://api.lumarketo.cl/mailchimp`, headers, {
    companyId,
    apiKey,
  });
};

export const getMailChimp = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/mailchimp?id=${companyId}`,
    headers
  );
};

export const deleteMailChimp = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`https://api.lumarketo.cl/mailchimp/`, headers, {
    companyId,
  });
};

export const getAllListMailChimp = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/mailchimp/allList?id=${companyId}`,
    headers
  );
};

export const setBatchMembersMailChimp = async (companyId, listId, members) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `https://api.lumarketo.cl/mailchimp/setBatchMembers`,
    headers,
    {
      companyId,
      listId,
      members,
    }
  );
};

export const getAllTemplatesMailChimp = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/mailchimp/template/all?companyId=${companyId}`,
    headers
  );
};

export const createTemplateMailChimp = async (companyId, html, name) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/mailchimp/template`, headers, {
    companyId,
    html,
    name,
  });
};

export const updateTemplateMailChimp = async (
  companyId,
  html,
  name,
  templateId
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`https://api.lumarketo.cl/mailchimp/template`, headers, {
    companyId,
    html,
    name,
    templateId,
  });
};

export const deleteTemplateMailChimp = async (companyId, templateId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`https://api.lumarketo.cl/mailchimp/template`, headers, {
    companyId,
    templateId,
  });
};

export const getAllCampaignsMailChimp = async (companyId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/mailchimp/campaign/all?companyId=${companyId}`,
    headers
  );
};

export const createCampaignMailChimp = async (
  companyId,
  type,
  settings,
  recipients
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`https://api.lumarketo.cl/mailchimp/campaign`, headers, {
    companyId,
    type,
    settings,
    recipients,
  });
};

export const updateCampaignMailChimp = async (
  companyId,
  type,
  settings,
  recipients,
  campaignId
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await put(`https://api.lumarketo.cl/mailchimp/campaign`, headers, {
    companyId,
    type,
    settings,
    recipients,
    campaignId,
  });
};

export const getCampaignsMailChimp = async (companyId, campaignId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `https://api.lumarketo.cl/mailchimp/campaign?companyId=${companyId}&campaignId=${campaignId}`,
    headers
  );
};

export const deleteCampaignMailChimp = async (companyId, campaignId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`https://api.lumarketo.cl/mailchimp/campaign`, headers, {
    companyId,
    campaignId,
  });
};

export const scheduleCampaignMailChimp = async (
  companyId,
  campaignId,
  scheduleTime
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `https://api.lumarketo.cl/mailchimp/campaign/schedule`,
    headers,
    {
      companyId,
      campaignId,
      scheduleTime,
    }
  );
};

export const sendCampaignMailChimp = async (companyId, campaignId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `https://api.lumarketo.cl/mailchimp/campaign/send`,
    headers,
    {
      companyId,
      campaignId,
    }
  );
};

export const createSimphonyOracle = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(`${config.baseUrl}/simphony`, headers, data);
};

export const updateSimphonyOracle = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await patch(`${config.baseUrl}/simphony`, headers, data);
};

export const removeSimphonyOracle = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await Delete(`${config.baseUrl}/simphony`, headers, data);
};

export const getSimphonyOracle = async (companyId, subsidiaryId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.baseUrl}/simphony?companyId=${companyId}&subsidiaryId=${subsidiaryId}`,
    headers
  );
};

const companyService = {
  getSKUNumber,
  setSKUNumber,
  getCompanyStatus,
  update,
  getByIdCompany,
  setShippingCost,
  updateCompany,
  setSEO,
  setColors,
  getAWSConfig,
  validateDNS,
  getCertificateStatus,
  createClientDistribution,
  deleteDemoDistribution,
  getSubsidiary,
  getFonts,
  createFont,
  setTypography,
  setTemplate,
  getTemplates,
  getTemplateHistory,
  createTemplateHistory,
  validateChangesOnTemplate,
  getPlans,
  getPlan,
  createSuscriptionPlan,
  getSuscriptionPlan,
  getFlowConfig,
  availableDynamicPageName,
  createDynamicPage,
  getDynamicPages,
  getDynamicPage,
  deleteDynamicPage,
  createCustomWebComponent,
  getCustomWebComponents,
  verifyCustomWebComponentToken,
  createTemplate,
  getHeader,
  getAllHeader,
  createHeader,
  deleteHeader,
  updateHeader,
  createOrUpdateShutdownSubsidiary,
  createFudo,
  updateFudo,
  getFudo,
  createOrderFudo,
  createOrderToteat,
  createToteat,
  updateToteat,
  getToteat,
  getPolygon,
  createPolygon,
  deletePolygon,
  updatePolygon,
  createMailChimp,
  getMailChimp,
  deleteMailChimp,
  updateMailChimp,
  getAllListMailChimp,
  setBatchMembersMailChimp,
  createTemplateMailChimp,
  updateTemplateMailChimp,
  deleteTemplateMailChimp,
  getAllCampaignsMailChimp,
  createCampaignMailChimp,
  updateCampaignMailChimp,
  getCampaignsMailChimp,
  deleteCampaignMailChimp,
  scheduleCampaignMailChimp,
  sendCampaignMailChimp,
  createSimphonyOracle,
  updateSimphonyOracle,
  removeSimphonyOracle,
  getSimphonyOracle,
};

export default companyService;
