import reactCSS from "reactcss";
import { useEffect, useState } from "react";
import {
  createTheme,
  InputAdornment,
  MuiThemeProvider,
  TextField as MuiTextField,
  withStyles,
} from "@material-ui/core";
import ColorPopup from "./index.component";
import ColorPicker from 'react-best-gradient-color-picker'
import { isString } from "lodash";

function GradientColor({ callback, value, colors = [] }) {
  const [color, setColor] = useState('rgba(0,0,0,1)');
  const [inputColor, setInputColor] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isString(value)) setColor(value);
  }, [value]);

  useEffect(() => {
    if (isString(value)) setInputColor(value);
  }, [value]);

  const handleChange = (color) => {
    setColor(color);
    setInputColor(color);
  };

  const handleChangeInput = (value) => {
    try {
      handleChange(value);
      if (callback) callback(value);
      setInputColor(value);
    } catch (error) {
      setInputColor(value);
    }
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "15px",
        height: "15px",
        borderRadius: "50px",
        background: color,
        margin: -1,
      },
      swatch: {
        padding: "2px",
        background: "#fff",
        borderRadius: "50px",
        boxShadow:
          `0 0 0 2px ${color}`,
        display: "inline-block",
        cursor: "pointer",
        margin: 10,
      },
      popover: {
        top: 0,
        position: "relative",
        zIndex: "4",
        display: "flex",
        left: 0,
        width: "100%",
        height: "100%",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "50px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ zIndex: 2 }}>
        <TextField
          value={inputColor}
          className="inputText"
          placeholder="Color"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div style={styles.swatch} onClick={() => setOpen(true)}>
                  <div style={styles.color} />
                </div>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={(e) => handleChangeInput(e.target.value)}
        />
      </div>
      <ColorPopup
        show={open}
        handleClose={() => {
          setOpen(false);
          setColor(value);
          setInputColor(value);
        }}
        content={
          <div className="row">
            <div
              className={colors && colors.length > 0 ? "col-6" : "col-12"}
              style={{
                textAlign: colors && colors.length > 0 ? "left" : "center",
              }}
            >
              <h6
                className="inputTitle"
                style={{ marginLeft: 0, color: "#fff" }}
              >
                Color personalizado
              </h6>
            </div>
            <div
              className={colors && colors.length > 0 ? "col-6" : "col-12"}
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <ColorPicker value={color} onChange={handleChange} />
            </div>
          </div>
        }
        handleSave={() => {
          setOpen(false);
          if (isString(value)) setColor(value);
          if (isString(value)) setInputColor(value);
          if (callback) callback(color);
        }}
      />
    </MuiThemeProvider>
  );
}
const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        minHeight: 35,
      },
      notchedOutline: {
        border: "0.1px solid gray",
        borderColor: "gray !important",
        borderRadius: 0,
      },
      // "$&focused": {
      //   border: "2px solid #122526",
      //   borderColor: "#122526",
      // },
      adornedEnd: {
        padding: "0 !important",
      },
      input: {
        padding: "5px 10px",
      },
    },
    MuiTextField: {
      root: {
        minHeight: 35,
        margin: "10px 0px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        color: "#4c4c4c",
      },
    },
    MuiInput: {
      root: {
        border: "none",
      },
    },
  },
});

const TextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(MuiTextField);

export default GradientColor;
