import { combineReducers } from "@reduxjs/toolkit";
import companyReducer from "./companySlice";
import addressReducer from "./addressSlice";
import orderReducer from "./orderSlice";
import productReducer from "./productSlice";
import userReducer from "./userSlice";
import billingReducer from "./billingSlice";
import categoryReducer from "./categorySlice";
import checkoutReducer from "./checkoutSlice";
import depositChangesReducer from "./depositChangesSlice";
import discountReducer from "./discountSlice";
import emailReducer from "./emailSlice";
import inventaryRecord from "./inventaryRecordSlice";
import serviceReducer from "./serviceSlice";
import seoReducer from "./seoSlice";
import uiReducer from "./uiSlice";
import depositReducer from "./depositSlice";
import customerServiceReducer from "./customerServiceSlice";
import shippingReducer from "./shippingSlice";
import imgHistorySlice from "./imgHistorySlice";
import subsidiarySlice from "./subsidiarySlice";
import fontSlice from "./fontSlice";
import webSlice from "./webSlice";

const rootReducer = combineReducers({
  company: companyReducer,
  address: addressReducer,
  orders: orderReducer,
  products: productReducer,
  user: userReducer,
  billing: billingReducer,
  categories: categoryReducer,
  checkout: checkoutReducer,
  depositChanges: depositChangesReducer,
  inventaryRecord: inventaryRecord,
  services: serviceReducer,
  discount: discountReducer,
  email: emailReducer,
  seo: seoReducer,
  ui: uiReducer,
  deposit: depositReducer,
  customerService: customerServiceReducer,
  shipping: shippingReducer,
  imgHistory: imgHistorySlice,
  subsidiaries: subsidiarySlice,
  fonts: fontSlice,
  web: webSlice,
});

export default rootReducer;
