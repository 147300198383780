import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const UsersPage = lazy (() => import ('../containers/users'));

export default function UsersRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <UsersPage />
    </Suspense>
  );
}
