import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const DepositChangesPage = lazy (() => import ('../containers/depositChanges'));
const OrderChangesPage = lazy (() => import ('../containers/orderChanges'));
const SettingsChangesPage = lazy (() =>
  import ('../containers/settingsChanges')
);

export function DepositChangesRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <DepositChangesPage />
    </Suspense>
  );
}

export function OrderChangesRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <OrderChangesPage />
    </Suspense>
  );
}

export function SettingsChangesRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <SettingsChangesPage />
    </Suspense>
  );
}
