export const config = {
  urlApi: process.env.REACT_APP_PRODUCT_API_URL,
  ordersMicroserviceUrlApi: process.env.REACT_APP_ORDERS_API_URL,
  usersMicroserviceUrlApi: process.env.REACT_APP_USERS_API_URL,
  companyMicroserviceUrlApi: process.env.REACT_APP_COMPANY_API_URL,
  addressMicroserviceUrlApi: process.env.REACT_APP_ADDRESS_API_URL,
  recordsMicroserviceUrlApi: process.env.REACT_APP_RECORDS_API_URL,
  financeMicroserviceUrlApi: process.env.REACT_APP_FINANCE_API_URL,
  emailMicroserviceUrlApi: process.env.REACT_APP_EMAIL_API_URL,
  servicesMicroserviceUrlApi: process.env.REACT_APP_SERVICES_API_URL,
  customerServiceMicroserviceUrlApi:
    process.env.REACT_APP_CUSTOMER_SERVICE_API_URL,
  paymentServiceMicroserviceUrlApi: process.env.REACT_APP_PAYMENT_API_URL,
  shippingServiceMicroserviceUrlApi: process.env.REACT_APP_SHIPPING_API_URL,
  awsBucket: process.env.REACT_APP_PHOTOS_BUCKET,
  awsFolder: process.env.REACT_APP_PHOTOS_FOLDER,
  awsRegion: process.env.REACT_APP_REGION,
  apiKey: process.env.REACT_APP_API_KEY,
  tinypngApiKey: process.env.REACT_APP_TINYPNG_API_KEY,
  idCompany: process.env.REACT_APP_LUMARKETO_COMPANY_ID,
  country: process.env.REACT_APP_COUNTRY,
  jwtSecret: process.env.REACT_APP_JWT_SECRET,
  salt: process.env.REACT_APP_SALT,
  googlePlacesKey: process.env.REACT_APP_PLACES_GOOGLE_API_KEY,
  nodeEnv: process.env.REACT_APP_ENV,
  websocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
  baseUrl: process.env.REACT_APP_BASE_URL || "https://api.lumarketo.cl",
};

export default config;
