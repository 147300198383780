import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/products";
import isArray from "lodash/isArray";
import storage from "../utils/storage";

export const productSlice = createSlice({
  name: "products",
  initialState: initialState.products,
  reducers: {
    receiveProducts: (state, action) => {
      state.splice(0, state.length);
      state.push(...action.payload);
    },
    receiveProduct: (state, action) => {
      const index = state.findIndex((item) => item._id === action.payload._id);
      if (index !== -1) state[index] = action.payload;
      else state.push(action.payload);
    },
    updateItem: (state, action) => {
      const oldState = [];
      oldState.push(...state);
      const index = oldState.findIndex(
        (item) => item._id === action.payload._id
      );

      if (index === -1) oldState.push({ ...action.payload });
      else oldState[index] = action.payload;

      state.splice(0, state.length);
      state.push(...oldState);
    },
    removeItem: (state, action) => {
      const oldState = [];
      oldState.push(...state);

      const index = oldState.findIndex(
        (item) => item._id === action.payload._id
      );
      oldState.splice(index, 1);
      state.splice(0, state.length);
      state.push(...oldState);
    },
  },
});

export const { receiveProduct, receiveProducts, removeItem, updateItem } =
  productSlice.actions;

export const getAllProducts = async (idCompany, token, dispatch) => {
  await service
    .getProductByIdCompany(idCompany, token)
    .then((res) => dispatch(receiveProducts(res)));
};

export const create = (data, token) => service.create(data, token);

export const Delete = (data, token) => {
  return service.DeleteProduct(data, token);
};

export const update = (data, token) => {
  if (isArray(data)) {
    return Promise.all(data.map((product) => service.update(product, token)));
  } else return service.update(data, token);
};

export const selectProducts = (state) => state.products;

export const selectViewedProduct = (state) => {
  const lumarketo = storage.get();
  if (lumarketo && lumarketo.viewedProducts) return lumarketo.viewedProducts;
  return [];
};

export const getProduct = (id) => service.getProductById(id);

export default productSlice.reducer;
