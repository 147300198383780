import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputComponent from "../../inputs";
import PlusImgButton from "../../button/plusImg";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import useQuery from "../../../utils/hooks/queryParameters";
import {
  createCustomWebComponent,
  getCustomWebComponents,
} from "../../../utils/services/company";
import { useSnackbar } from "notistack";
import { Notification } from "../../../containers/notification";
import { componentType } from "../../../constants/web.constants";
import successIcon from "../../../assets/img/icon-alert-success.png";
import errorIcon from "../../../assets/img/icon-alert-error.png";
import { useDispatch } from "react-redux";
import { loadingOn, loadingOff } from "../../../reducers/uiSlice";

export default function CreateWebComponentDialog({
  color = "#fff",
  component,
}) {
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [show, setShow] = useState(false);
  const [validatingTitle, setValidatingTitle] = useState(false);
  const [availableTitle, setAvailableTitle] = useState(false);
  const query = useQuery(useLocation);
  const token = query.get("token");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    async function validate() {
      setValidatingTitle(true);
      const components = await getCustomWebComponents().catch((err) => {
        console.log(err);
        return [];
      });
      if (components && components.length > 0) {
        const found = components.find((item) => item.title === title);
        setAvailableTitle(!found);
      }
      setValidatingTitle(false);
    }
    if (title !== "") validate();
  }, [title]);

  const handleClick = async () => {
    const { _id, ...others } = component;
    dispatch(loadingOn());
    const result = await createCustomWebComponent(
      {
        ...others,
        title,
        icon,
        type: componentType.custom,
        component: "CustomComponent",
      },
      token
    ).catch((err) => {
      console.log(err);
      enqueueSnackbar(
        Notification({
          text: err,
          variant: "error",
          withDetails: false,
        })
      );
      return null;
    });
    if (result) {
      setShow(false);
      enqueueSnackbar(
        Notification({
          text: "Componente creado exitosamente.",
          variant: "success",
          withDetails: false,
        })
      );

      enqueueSnackbar(
        Notification({
          text: "Por favor recargue para visualizar.",
          variant: "success",
          withDetails: false,
        })
      );
    }
    dispatch(loadingOff());
  };

  return (
    <span>
      <IconButton
        size="small"
        style={{
          color,
          borderRadius: "0 !important",
        }}
        onClick={() => setShow(true)}
      >
        <img
          heigth={15}
          width={15}
          src="https://luma-photos.s3.amazonaws.com/content/saveIcon.jpeg"
          alt="saveIcon"
        />
      </IconButton>
      <Dialog open={show} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Guardar componente web</DialogTitle>
        <DialogContent>
          <Typography variant="h6" align="center">
            ¿Esta seguro de que desea guardar este componente?
          </Typography>
          <div className="row" style={{ padding: "10px 5%" }}>
            <div className="col-3">
              <h6
                className="inputTitle"
                style={{ lineHeight: 3, fontWeight: 600 }}
              >
                Título:
              </h6>
            </div>
            <div className="col-9">
              <InputComponent
                validation={false}
                placeholder="Escribe el nombre de la personalización"
                value={title}
                handler={(e) => setTitle(e.target.value)}
                helperText={"Este campo es obligatorio"}
                style={{ color: "#000" }}
                useBlueBackground={false}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {validatingTitle ? (
                        <CircularProgress
                          style={{
                            width: 25,
                            height: 25,
                            color: "#000",
                            margin: 5,
                          }}
                        />
                      ) : availableTitle ? (
                        <img src={successIcon} alt="success" width={25} />
                      ) : (
                        <img src={errorIcon} alt="error" width={25} />
                      )}
                    </InputAdornment>
                  ),
                }}
                width={400}
              />
            </div>
            <div className="col-4">
              <h6
                className="inputTitle"
                style={{ lineHeight: 3, fontWeight: 600 }}
              >
                Icono (35x35):
              </h6>
            </div>
            <div className="col-8">
              <PlusImgButton
                withHistory={false}
                path={icon}
                width={200}
                height={200}
                callback={(img) => setIcon(img.location)}
                text="Subir logo"
                subtext="(medida recomendada 35x35 px)"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={() => setShow(false)} className="btn backButton">
            Cancelar
          </button>
          <button
            disabled={
              !token ||
              token === "" ||
              title === "" ||
              icon === "" ||
              !availableTitle ||
              validatingTitle
            }
            onClick={() => {
              if (
                !(
                  !token ||
                  token === "" ||
                  title === "" ||
                  icon === "" ||
                  !availableTitle ||
                  validatingTitle
                )
              )
                handleClick();
            }}
            className="btn addButton"
          >
            Aceptar
          </button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
