import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/address";
import config from "../utils/config";
import jwt from "jsonwebtoken";

export const addressSlice = createSlice({
  name: "address",
  initialState: initialState.addresses,
  reducers: {
    receiveAddresses: (state, action) => {
      state.push(...action.payload);
    },
    receiveAddress: (state, action) => {
      const index = state.findIndex((item) => item === action.payload);
      if (index !== -1) state[index] = action.payload;
      else state.push(action.payload);
    },
    deleteAddress: (state, action) => {
      const index = state.findIndex((address) => address === action.payload);
      state.splice(index, 1);
    },
  },
});

export const { receiveAddress, receiveAddresses, deleteAddress } =
  addressSlice.actions;

export const getAddressById = async (id, token) =>
  await service.getById(id, token);

export const selectAddresses = (state) =>
  state.address.map((address) =>
    address ? jwt.verify(address, config.jwtSecret) : undefined
  );

export default addressSlice.reducer;
