import classNames from "classnames";

import styles from "./styles.module.css";

export default function Toolbar({
  useTitle = true,
  useSubtitle = true,
  title,
  subtitle,
  FirstComponent,
  SecondComponent,
}) {
  return (
    <div className={styles.toolbarContainer}>
      {useTitle && (
        <div className={styles.toolbarTitlesContainer}>
          <h6 className={styles.TitleToolbar}>{title}</h6>
          {subtitle && subtitle !== "" && useSubtitle && (
            <h6 className={styles.SubTitleToolbar}>{subtitle}</h6>
          )}
        </div>
      )}
      <div className={styles.componentsContainer}>
        {FirstComponent && (
          <div
            className={classNames(
              styles.firstSecondComponent,
              useTitle ? "text-right" : "text-left"
            )}
          >
            {FirstComponent}
          </div>
        )}
        {SecondComponent && (
          <div className={styles.firstSecondComponent}>{SecondComponent}</div>
        )}
      </div>
    </div>
  );
}
