import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/records";
import { clearChanges } from "./depositSlice";

export const depositChangesSlice = createSlice({
  name: "depositChanges",
  initialState: initialState.depositChanges,
  reducers: {
    receiveChanges: (state, action) => {
      state.splice(0, state.length);
      state.push(...action.payload);
    },
  },
});

export const { receiveChanges } = depositChangesSlice.actions;

export const getAllChangesByIdCompany = async (
  {
    idCompany,
    limit,
    page,
    order,
    orderBy,
    module,
    submodule,
    searchText,
    searchField,
  },
  token
) =>
  await service.getChangeRecordByIdCompany(
    {
      idCompany,
      limit,
      page,
      order,
      orderBy,
      submodule,
      module,
      searchText,
      searchField,
    },
    token
  );

export const createChangeRecord = async (data, token, dispatch) =>
  await service.createChangeRecord(data, token).then((res) => {
    dispatch(clearChanges());
    return res;
  });

export const selectdepositChanges = (state) => state.depositChanges;

export default depositChangesSlice.reducer;
