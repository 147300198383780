import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const SettingsPage = lazy (() =>
  import ('../components/settings/bsale/index.component')
);

export default function SettingsRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <SettingsPage />
    </Suspense>
  );
}
