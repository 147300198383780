import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import { getHistory as getImgHistory } from "../utils/services/imgHistory";

export const imgHistorySlice = createSlice({
  name: "imgHistory",
  initialState: initialState.imgHistory,
  reducers: {
    receive: (state, action) => {
      state.splice(0, state.length);
      state.push(...action.payload);
    },
  },
});

export const { receive } = imgHistorySlice.actions;

export const getHistory = async (token, loadingLogo, companyId, dispatch) => {
  try {
    const res = await getImgHistory(token, companyId, loadingLogo);
    dispatch(receive(res.items));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const selectImgHistory = (state) => state.imgHistory;

export default imgHistorySlice.reducer;
