export const SixCategoriesImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-seis-categorias.svg";
export const TextImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-texto.svg";
export const VideoWithTextImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-video-con-texto.svg";
export const VideoImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-video-svg.svg";
export const LanzamientosImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-lanzamientos.png";
export const TresBannersImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-tres-banners.svg";
export const DosBannersImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-dos-banners.svg";
export const DosCategoriesImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-dos-categorias.svg";
export const FourCategoriesImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-cuatro-categorias(1).svg";
export const CarruselPorDepartamentoImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-carrusel.svg";
export const BrandCarrouselImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-carrusel-de-marcas.svg";
export const ImagesCarrouselImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-carrusel-de-imagenes.svg";
export const InformationBoxImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-caja-informativa.svg";
export const ButtonImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-boton.svg";
export const BlogsImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-blog.svg";
export const BannerWithButtonImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-banner-con-boton.svg";
export const BannerImg =
  "https://luma-photos.s3.amazonaws.com/photos/icono-banner.svg";
export const ProductosImg =
  "https://s3.amazonaws.com/lumarketo.cl/productos.svg";
export const ProductoDestacadoImg =
  "https://s3.amazonaws.com/lumarketo.cl/producto-destacado.svg";
export const BannersCategoriasImg =
  "https://luma-photos.s3.amazonaws.com/photos/Iconos-Lumarketo_Subbanners_categorias.png";
export const BannerBackgroundImg =
  "https://s3.amazonaws.com/lumarketo.cl/banner-de-fondo-con-carrusel.svg";
const CarruselIcon =
  "https://s3.amazonaws.com/haya.lumarketo.cl/cartooncarrusel.svg";
const LinkIcon = "https://s3.amazonaws.com/haya.lumarketo.cl/cartoonenlace.svg";
const ImageIcon =
  "https://s3.amazonaws.com/haya.lumarketo.cl/cartoonimagen.svg";
const TabIcon =
  "https://s3.amazonaws.com/haya.lumarketo.cl/cartoonpestan%CC%83as.svg";
// const SubtituloIcon =
//   "https://s3.amazonaws.com/haya.lumarketo.cl/cartoonsubtitulo.svg";
const TextoIcon = "https://s3.amazonaws.com/haya.lumarketo.cl/cartoontexto.svg";
const TituloIcon =
  "https://s3.amazonaws.com/haya.lumarketo.cl/cartoontitulo.svg";
const PLPIcon = "https://s3.amazonaws.com/lumarketo.cl/PLP.svg";

export const UploadImg =
  "https://luma-photos.s3.amazonaws.com/photos/subir-imagen.webp";
export const UploadBanner =
  "https://luma-photos.s3.amazonaws.com/photos/subir-banner.png";

export const HtmlImg =
  "https://luma-photos.s3.amazonaws.com/content/htmlIcon.jpeg";

export const componentType = {
  slider: "slider",
  banner: "banner",
  categories: "categories",
  popular: "popular",
  custom: "custom",
};

export const componentTypeText = {
  slider: "Carrusel",
  banner: "Banners",
  categories: "Categorias",
  popular: "Más utilizados",
  custom: "Personalizados",
};

const webConstants = {
  textHtmlTags: ["p", "strong", "h1", "h2", "h3", "h4", "h5", "h6"],
  mediaHtmlTags: ["img"],
  htmlTags: [
    "html",
    "base",
    "head",
    "style",
    "title",
    "address",
    "article",
    "footer",
    "header",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hgroup",
    "nav",
    "section",
    "dd",
    "div",
    "dl",
    "dt",
    "figcaption",
    "figure",
    "hr",
    "li",
    "main",
    "ol",
    "p",
    "pre",
    "ul",
    "abbr",
    "b",
    "bdi",
    "bdo",
    "br",
    "cite",
    "code",
    "data",
    "dfn",
    "em",
    "i",
    "kbd",
    "mark",
    "q",
    "rp",
    "rt",
    "rtc",
    "ruby",
    "s",
    "samp",
    "small",
    "span",
    "strong",
    "sub",
    "sup",
    "time",
    "u",
    "var",
    "wbr",
    "area",
    "audio",
    "map",
    "track",
    "video",
    "embed",
    "object",
    "param",
    "source",
    "canvas",
    "noscript",
    "script",
    "del",
    "ins",
    "caption",
    "col",
    "colgroup",
    "table",
    "tbody",
    "td",
    "tfoot",
    "th",
    "thead",
    "tr",
    "button",
    "datalist",
    "fieldset",
    "form",
    "input",
    "keygen",
    "label",
    "legend",
    "meter",
    "optgroup",
    "option",
    "output",
    "progress",
    "select",
    "details",
    "dialog",
    "menu",
    "menuitem",
    "summary",
    "content",
    "element",
    "shadow",
    "template",
    "acronym",
    "applet",
    "basefont",
    "big",
    "blink",
    "center",
    "dir",
    "frame",
    "frameset",
    "isindex",
    "listing",
    "noembed",
    "plaintext",
    "spacer",
    "strike",
    "tt",
    "xmp",
  ],
  productFrom: {
    category: "category",
    skus: "skus",
    collections: "collections",
  },
  header: {
    TYPES: [{ name: "link" }, { name: "banner" }],
    BANNER_TYPE: [{ name: "brand" }, { name: "categories" }],
  },
  routes: {
    BANNERS: "banners",
    HOME: "home",
    CUSTOMER_SERVICE: "atención-al-cliente",
    COLLECTIONS: "colecciones",
    HEADER: "header",
    FOOTER: "footer",
    BODY: "body",
    NEWSLETTER: "newsletter",
    LOGO: "logo",
    SETTINGS: "configuraciones",
    COLORS: "colores",
    TYPOGRAPHY: "tipografías",
    SEO: "seo",
    LOGIN: "acceder",
    RESET_PASSWORD: "cambio-de-contrasena",
    WEB: "web",
    TEMPLATES: "templates",
    MODULE_WEB: "modulo-web",
    MODULE_DYNAMIC_PAGE: "modulo-pagina-dinamica",
    PRODUCT_CARD: "tarjeta-de-producto",
    OTHER_COMPONENTS: "otros-componentes",
    MODAL_PRODUCT_CARD: "modal-de-producto",
  },
  HTML: {
    component: "HtmlComponent",
    informationTree: [
      [
        {
          onClickId: "aceEditor",
          title: "Html",
          icon: ImageIcon,
        },
      ],
    ],
    inputs: [
      {
        html: "<p>Hello World</p>",
      },
    ],
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    columns: 12,
    type: componentType.popular,
    pro: true,
  },
  FEATURED_PRODUCTS: {
    pro: true,
    component: "FeaturedProductsComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/Productos-destacados-1.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/productos-destacados-2.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/productos-destacados-3.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: " https://s3.amazonaws.com/lumarketo.cl/productos-destacados-4.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
    ],
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    columns: 12,
    type: componentType.banner,
  },
  FEATURED_PRODUCTS_TABLET: {
    pro: true,
    component: "FeaturedProductsTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Text",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-producto-destacado-1.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-producto-destacado-2.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-producto-destacado-3.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-producto-destacado-4.webp",
        Resolution: "100%x100%",
        Link: "/",
        Text: "Aca escribiras una breve descripcion del producto o categoria",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
    ],
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    columns: 12,
    type: componentType.banner,
  },
  PRODUCT_TAB: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ProductsTabComponent",
    informationTree: [
      [
        {
          onClickId: "type",
          title: "Tipo de carrusel",
          icon: CarruselPorDepartamentoImg,
        },
        {
          onClickId: "categories",
          title: "Categorias",
          icon: CarruselPorDepartamentoImg,
        },
      ],
    ],
    inputs: [
      {
        UseCategories: true,
        Category: "",
        Categories: [
          "newProducts",
          "newProducts",
          "newProducts",
          "newProducts",
        ],
        slidesToShow: 5,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    columns: 12,
    type: componentType.slider,
  },
  PRODUCT_TAB_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ProductsTabTabletComponent",
    informationTree: [
      [
        {
          onClickId: "type",
          title: "Tipo de carrusel",
          icon: CarruselPorDepartamentoImg,
        },
        {
          onClickId: "categories",
          title: "Categorias",
          icon: CarruselPorDepartamentoImg,
        },
      ],
    ],
    inputs: [
      {
        UseCategories: true,
        Category: "",
        Categories: [
          "newProducts",
          "newProducts",
          "newProducts",
          "newProducts",
        ],

        slidesToShow: 4,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    columns: 12,
    type: componentType.slider,
  },
  TEXT: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "TextComponent",
    informationTree: [
      [
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
      ],
    ],
    inputs: [
      {
        Text: "Aquí podrás escribir titulos, subtitulos, etc.",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Weight: 400,
      },
    ],
    columns: 12,
    type: componentType.popular,
  },
  BUTTON: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ButtonComponent",
    informationTree: [
      [
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
      ],
    ],
    inputs: [
      {
        Text: "Ver más",
        Color: "#ffffff",
        ButtonWidth: 20,
        ButtonHeight: 100,
        BackgroundColor: "#000000",
        Border: "none",
        BorderRadius: 5,
        Align: "center",
        Size: 16,
        Link: "/",
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Weight: 400,
        ButtonMargin: "0px 0px 0px 0px",
        ButtonPadding: "0px 0px 0px 0px",
        ButtonWeight: 400,
      },
    ],
    type: componentType.popular,
  },
  BANNER: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
        Alt: "",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.popular,
  },
  BANNER_TABLET: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
        Alt: "",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.popular,
  },
  BANNER_MOBILE: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerMobileComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/632b16918a4e06101c121dee/66b303-3b63-1e8e-0621-de4f00b40b4-banner-secundario-mobile.webp",
        Alt: "",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.popular,
  },
  TWO_BANNER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "TwoBannerComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/componente-dos-categorias-1.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/componente-dos-categorias-2.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
    ],
    type: componentType.popular,
  },
  TWO_BANNER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "TwoBannerTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/componente-dos-categorias-1-tablet.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/componente-dos-categorias-2-tablet.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
    ],
    type: componentType.popular,
  },
  THREE_BANNER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeBannerComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-1.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-2.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-3.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
    ],
    type: componentType.banner,
  },
  THREE_BANNER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeBannerTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-1-tablet.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-2-tablet.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-3-tablet.webp",
        Alt: "",
        Link: "/",
        Width: 100,
      },
    ],
    type: componentType.banner,
  },
  CATEGORY_BANNER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "CategoryBannerComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
    ],
    type: componentType.categories,
  },
  CATEGORY_BANNER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "CategoryBannerTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
        Alt: "",
        Link: "/",
        Text: "Texto",
        Color: "#000000",
        Align: "center",
        Size: 16,
        Width: 100,
      },
    ],
    type: componentType.categories,
  },
  VIDEO: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "VideoComponent",
    inputs: [
      {
        Url: "",
        Muted: false,
        Controls: true,
        Autoplay: true,
        Loop: true,
        Width: 100,
      },
    ],
    type: componentType.popular,
  },
  THREE_VIDEO: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeVideoComponent",
    inputs: [
      {
        videos: [
          {
            Url: "",
            Muted: false,
            Controls: true,
            Autoplay: true,
            Loop: true,
            Width: 100,
          },
          {
            Url: "",
            Muted: false,
            Controls: true,
            Autoplay: true,
            Loop: true,
            Width: 100,
          },
          {
            Url: "",
            Muted: false,
            Controls: true,
            Autoplay: true,
            Loop: true,
            Width: 100,
          },
        ],
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 16,
        TitleColor: "#000000",
        TitleMargin: "0px 0px 0px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
      },
    ],
    columns: 12,
  },
  VIDEO_WITH_TEXT: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "VideoWithTextComponent",
    inputs: [
      {
        Url: "",
        Muted: false,
        Controls: true,
        Autoplay: true,
        Loop: true,
        Width: 100,
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 16,
        TitleColor: "#000000",
        TitleMargin: "0px 0px 0px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Veniam qui aliquip exercitation ad cupidatat et. Officia qui deserunt ut non labore. Incididunt aliquip nulla pariatur id occaecat anim do. Laborum ex sint nisi in aliqua sunt magna ea dolore aliqua nostrud. Magna culpa labore velit nostrud occaecat fugiat eiusmod nostrud. Incididunt Lorem irure commodo dolor duis amet.",
        TextAlign: "center",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "0px 0px 0px 0px",
        TextPadding: "0px 0px 0px 0px",
        ButtonText: "Boton",
        ButtonColor: "#fff",
        ButtonAlign: "center",
        ButtonTextSize: 16,
        ButtonLink: "/",
        ButtonWidth: 100,
        ButtonHeight: 100,
        ButtonBackgroundColor: "#000000",
        ButtonBorder: "none",
        ButtonBorderSize: 3,
        ButtonBorderRadius: 5,
        ButtonMargin: "0px 0px 0px 0px",
        ButtonPadding: "0px 0px 0px 0px",
        ButtonTextWeight: 400,
      },
    ],
    columns: 12,
  },
  VIDEO_WITH_TEXT_MOBILE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "VideoWithTextMobileComponent",
    inputs: [
      {
        Url: "",
        Muted: false,
        Controls: true,
        Autoplay: true,
        Loop: true,
        Width: 100,
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 16,
        TitleColor: "#000000",
        TitleMargin: "0px 0px 0px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Veniam qui aliquip exercitation ad cupidatat et. Officia qui deserunt ut non labore. Incididunt aliquip nulla pariatur id occaecat anim do. Laborum ex sint nisi in aliqua sunt magna ea dolore aliqua nostrud. Magna culpa labore velit nostrud occaecat fugiat eiusmod nostrud. Incididunt Lorem irure commodo dolor duis amet.",
        TextAlign: "center",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "0px 0px 0px 0px",
        TextPadding: "0px 0px 0px 0px",
        ButtonText: "Boton",
        ButtonColor: "#fff",
        ButtonAlign: "center",
        ButtonTextSize: 16,
        ButtonLink: "/",
        ButtonWidth: 100,
        ButtonHeight: 100,
        ButtonBackgroundColor: "#000000",
        ButtonBorder: "none",
        ButtonBorderSize: 3,
        ButtonBorderRadius: 5,
        ButtonMargin: "0px 0px 0px 0px",
        ButtonPadding: "0px 0px 0px 0px",
        ButtonTextWeight: 400,
      },
    ],
    columns: 12,
  },
  BANNER_WITH_BUTTON: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerWithButtonComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "description",
          title: "Descripción",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
    ],
    inputs: [
      {
        Path: {
          Path: "https://s3.amazonaws.com/lumarketo.cl/banner-con-texto-desktop.png",
          Resolution: "",
          Alt: "",
        },
        Img: {
          Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/150x220.jpg",
          Resolution: "",
          Alt: "",
        },
        Width: 100,
        Button: "Haz click aquí",
        ButtonPath: "",
        Description: "Descripción",
        Direction: "Left",
      },
    ],
    type: componentType.banner,
  },
  BANNER_WITH_BUTTON_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerWithButtonTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "description",
          title: "Descripción",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
    ],
    inputs: [
      {
        Path: {
          Path: "https://s3.amazonaws.com/lumarketo.cl/banner-con-texto-tablet.png",
          Resolution: "",
          Alt: "",
        },
        Img: {
          Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/150x220.jpg",
          Resolution: "",
          Alt: "",
        },
        Width: 100,
        Button: "Haz click aquí",
        ButtonPath: "",
        Description: "Descripción",
        Direction: "Left",
      },
    ],
    type: componentType.banner,
  },
  PRODUCTS_RELEASES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ProductsReleasesComponent",
    inputs: [
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/615x280.jpg",
        Resolution: "",
        Alt: "",
        CardTitle: "Título principal",
        CardTitleAlign: "left",
        CardTitleSize: 18,
        CardTitleColor: "#000000",
        CardTitleMargin: "5px 0px 5px 0px",
        CardTitlePadding: "0px 0px 0px 0px",
        CardTitleWeight: 400,
        Title: "Título",
        TitleAlign: "left",
        TitleSize: 18,
        TitleColor: "#000000",
        TitleMargin: "5px 0px 5px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
        ButtonMargin: "0px 0px 0px 0px",
        ButtonPadding: "0px 0px 0px 0px",
        ButtonText: "Texto",
        ButtonTextAlign: "left",
        ButtonTextSize: 18,
        ButtonTextColor: "#ffffff",
        ButtonTextMargin: "5px 0px 5px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
        ButtonTextWeight: 400,
        ButtonColor: "#ffffff",
        ButtonWidth: 100,
        ButtonHeight: 100,
        ButtonBackgroundColor: "#000000",
        ButtonBorder: "none",
        ButtonBorderRadius: 5,
        ButtonAlign: "center",
        ButtonSize: 16,
        ButtonPath: "",
        Description: "Descripción",
        DescriptionAlign: "left",
        DescriptionSize: 18,
        DescriptionColor: "#000000",
        DescriptionMargin: "5px 0px 5px 0px",
        DescriptionPadding: "0px 0px 0px 0px",
        DescriptionWeight: 400,
        Width: 100,
        VerticalCenter: false,
      },
    ],
    type: componentType.banner,
  },
  FOUR_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FourCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.categories,
  },
  FOUR_CATEGORIES_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FourCategoriesTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-4-categorias-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-4-categorias-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-4-categorias-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-4-categorias-2.webp",
        Alt: "",
        LinkText: "Ver más",
        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 25,
        TitleColor: "#000000",
        TitleMargin: "100px 0px 10px 0px",
        TitlePadding: "0px 5% 0px 5%",
        Text: "Texto",
        TextAlign: "left",
        TextSize: 16,
        TextColor: "#000000",
        TextMargin: "100px 15px 15px 15px",
        TextPadding: "0px 0px 0px 0px",
        LinkAlign: "left",
        LinkSize: 14,
        LinkColor: "#000000",
        LinkMargin: "25px 15px 0px 15px",
        LinkPadding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.categories,
  },
  BLOGS: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BlogsComponent",
    inputs: [
      {
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 20,
        TitleColor: "#122526",
        TitleMargin: "0px 0px 0px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
        blogs: [
          {
            Text: "Ad velit officia aliqua commodo veniam ad in veniam nostrud aliquip velit magna. Ipsum consectetur veniam deserunt nulla non aliquip aliqua dolor aliqua. Irure adipisicing ex excepteur mollit proident eiusmod consequat anim ut nisi commodo Lorem dolore fugiat.",
            TextAlign: "left",
            TextSize: 16,
            TextColor: "#122526",
            TextMargin: "0px 0px 15px 0px",
            TextPadding: "0px 5px 0px 5px",
            TextWeight: 400,
            ButtonAlign: "center",
            ButtonLinkSize: 16,
            ButtonLinkColor: "#fff",
            ButtonLinkMargin: "0px 0px 0px 0px",
            ButtonLinkPadding: "0px 0px 0px 0px",
            ButtonMargin: "0px 0px 0px 0px",
            ButtonPadding: "0px 0px 0px 0px",
            ButtonLinkText: "Leer más",
            ButtonLinkWeight: 400,
            ButtonBackgroundColor: "#122526",
            ButtonBorder: "#122526",
            ButtonBorderRadius: 25,
            ButtonHeight: 130,
            ButtonWidth: 50,
            Link: "/",
            Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/400x225.jpg",
            Alt: "",
            Width: 200,

            ButtonBorderSize: 3,
          },
          {
            Text: "Qui consequat nisi magna sunt dolore dolore culpa proident aliqua. Aute quis culpa do sunt duis nostrud. Excepteur pariatur cupidatat sint ut aute labore exercitation anim incididunt. Minim ut officia id aute duis quis Lorem consequat nostrud officia eu mollit voluptate.",
            TextAlign: "left",
            TextSize: 16,
            TextColor: "#122526",
            TextMargin: "0px 0px 15px 0px",
            TextPadding: "0px 5px 0px 5px",
            TextWeight: 400,
            ButtonAlign: "center",
            ButtonLinkSize: 16,
            ButtonLinkColor: "#fff",
            ButtonLinkMargin: "0px 0px 0px 0px",
            ButtonLinkPadding: "0px 0px 0px 0px",
            ButtonMargin: "0px 0px 0px 0px",
            ButtonPadding: "0px 0px 0px 0px",
            ButtonLinkText: "Leer más",
            ButtonLinkWeight: 400,
            ButtonBackgroundColor: "#122526",
            ButtonBorder: "#122526",
            ButtonBorderRadius: 25,
            ButtonHeight: 130,
            ButtonWidth: 50,
            Link: "/",
            Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/400x225.jpg",
            Alt: "",
            Width: 200,
            ButtonBorderSize: 3,
          },
          {
            Text: "Dolor ullamco mollit reprehenderit tempor. Minim magna magna sit occaecat ea esse irure labore tempor nostrud consectetur. Aliquip in eu velit do velit laborum. Eu incididunt laborum reprehenderit laborum fugiat laborum. Quis eu reprehenderit nisi ullamco ullamco occaecat. Pariatur reprehenderit tempor ipsum anim.",
            TextAlign: "left",
            TextSize: 16,
            TextColor: "#122526",
            TextMargin: "0px 0px 15px 0px",
            TextPadding: "0px 5px 0px 5px",
            TextWeight: 400,
            ButtonAlign: "center",
            ButtonLinkSize: 16,
            ButtonLinkColor: "#fff",
            ButtonLinkMargin: "0px 0px 0px 0px",
            ButtonLinkPadding: "0px 0px 0px 0px",
            ButtonMargin: "0px 0px 0px 0px",
            ButtonPadding: "0px 0px 0px 0px",
            ButtonLinkText: "Leer más",
            ButtonLinkWeight: 400,
            ButtonBackgroundColor: "#122526",
            ButtonBorder: "#122526",
            ButtonBorderRadius: 25,
            ButtonHeight: 130,
            ButtonWidth: 50,
            Link: "/",
            Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/400x225.jpg",
            Alt: "",
            Width: 200,
            ButtonBorderSize: 3,
          },
          {
            Text: "Cupidatat eiusmod nulla proident cillum sit amet consequat incididunt id ex aliquip labore officia laborum. Labore proident et sint id deserunt qui laboris tempor. Ullamco aliqua nulla voluptate do aute sit do fugiat ullamco eiusmod id. Et aliquip mollit eu veniam consectetur nulla magna ad cupidatat fugiat ad.",
            TextAlign: "left",
            TextSize: 16,
            TextColor: "#122526",
            TextMargin: "0px 0px 15px 0px",
            TextPadding: "0px 5px 0px 5px",
            TextWeight: 400,
            ButtonAlign: "center",
            ButtonLinkSize: 16,
            ButtonLinkColor: "#fff",
            ButtonLinkMargin: "0px 0px 0px 0px",
            ButtonLinkPadding: "0px 0px 0px 0px",
            ButtonMargin: "0px 0px 0px 0px",
            ButtonPadding: "0px 0px 0px 0px",
            ButtonLinkText: "Leer más",
            ButtonLinkWeight: 400,
            ButtonBackgroundColor: "#122526",
            ButtonBorder: "#122526",
            ButtonBorderRadius: 25,
            ButtonHeight: 130,
            ButtonWidth: 50,
            Link: "/",
            Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/400x225.jpg",
            Alt: "",
            Width: 200,
            ButtonBorderSize: 3,
          },
        ],
      },
    ],
  },
  TWO_BLOGS: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "TwoBlogsComponent",
    inputs: [
      {
        Title: "Título",
        TitleAlign: "center",
        TitleSize: 20,
        TitleColor: "#122526",
        TitleMargin: "0px 0px 0px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
        blogs: [
          {
            Title: "Título",
            TitleAlign: "left",
            TitleSize: 20,
            TitleColor: "#122526",
            TitleMargin: "5px 0px 5px 0px",
            TitlePadding: "0px 5% 0px 5%",
            TitleWeight: 400,
            Text: "Ad velit officia aliqua commodo veniam ad in veniam nostrud aliquip velit magna. Ipsum consectetur veniam deserunt nulla non aliquip aliqua dolor aliqua. Irure adipisicing ex excepteur mollit proident eiusmod consequat anim ut nisi commodo Lorem dolore fugiat.",
            TextAlign: "left",
            TextSize: 16,
            TextColor: "#122526",
            TextMargin: "0px 0px 15px 0px",
            TextPadding: "0px 5px 0px 5px",
            TextWeight: 400,
            ButtonAlign: "center",
            ButtonLinkSize: 16,
            ButtonLinkColor: "#fff",
            ButtonLinkMargin: "0px 0px 0px 0px",
            ButtonLinkPadding: "0px 0px 0px 0px",
            ButtonMargin: "0px 0px 0px 0px",
            ButtonPadding: "0px 0px 0px 0px",
            ButtonLinkText: "Leer más",
            ButtonLinkWeight: 400,
            ButtonBackgroundColor: "#122526",
            ButtonBorder: "#122526",
            ButtonBorderRadius: 25,
            ButtonHeight: 130,
            ButtonWidth: 50,
            Link: "/",
            Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/400x225.jpg",
            Alt: "",
            Width: 200,

            ButtonBorderSize: 3,
          },
          {
            Title: "Título",
            TitleAlign: "left",
            TitleSize: 20,
            TitleColor: "#122526",
            TitleMargin: "5px 0px 5px 0px",
            TitlePadding: "0px 5% 0px 5%",
            TitleWeight: 400,
            Text: "Qui consequat nisi magna sunt dolore dolore culpa proident aliqua. Aute quis culpa do sunt duis nostrud. Excepteur pariatur cupidatat sint ut aute labore exercitation anim incididunt. Minim ut officia id aute duis quis Lorem consequat nostrud officia eu mollit voluptate.",
            TextAlign: "left",
            TextSize: 16,
            TextColor: "#122526",
            TextMargin: "0px 0px 15px 0px",
            TextPadding: "0px 5px 0px 5px",
            TextWeight: 400,
            ButtonAlign: "center",
            ButtonLinkSize: 16,
            ButtonLinkColor: "#fff",
            ButtonLinkMargin: "0px 0px 0px 0px",
            ButtonLinkPadding: "0px 0px 0px 0px",
            ButtonMargin: "0px 0px 0px 0px",
            ButtonPadding: "0px 0px 0px 0px",
            ButtonLinkText: "Leer más",
            ButtonLinkWeight: 400,
            ButtonBackgroundColor: "#122526",
            ButtonBorder: "#122526",
            ButtonBorderRadius: 25,
            ButtonHeight: 130,
            ButtonWidth: 50,
            Link: "/",
            Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/400x225.jpg",
            Alt: "",
            Width: 200,
            ButtonBorderSize: 3,
          },
        ],
      },
    ],
  },
  EIGHT_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "EightCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 7",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 8",
          icon: ImageIcon,
        },
        {
          onClickId: "text",
          title: "Texto",
          icon: TextoIcon,
        },
        {
          onClickId: "button",
          title: "Botón",
          icon: ButtonImg,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/220x320.jpg",
        Alt: "",
        Link: "/",
        ButtonText: "Texto",
        ButtonTextSize: 16,
        ButtonTextWeight: 400,
        ButtonColor: "#fff",
        ButtonBorderColor: "#fff",
        ButtonBorderSize: "1px",
        ButtonBorderRadius: "2px",
        ButtonTextColor: "#000000",
        ButtonTextMargin: "0px 0px 0px 0px",
        ButtonTextPadding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.categories,
  },

  FIVE_CATEGORIES_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FiveCategoriesTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-componente-1-tablet.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-componente-3-tablet.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-componente-2-tablet.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-componente-5-tablet.webp.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-componente-4-tablet.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },
  FIVE_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FiveCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-no-ajedrez-1.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-no-ajedrez-2.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-no-ajedrez-3.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-no-ajedrez-4.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-categorias-no-ajedrez-5.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },

  FIVE_CHEST_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FiveChestCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-1.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-3.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-4.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-2.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-5.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },

  FIVE_CHEST_CATEGORIES_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FiveChestCategoriesTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-tablet-1.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-tablet-4.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-tablet-3.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-tablet-2.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/5-ajedrez-tablet-5.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },

  FOUR_CHEST_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FourChestCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-1.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-2.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-3.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-4.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },
  FOUR_CHEST_CATEGORIES_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FourChestCategoriesTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/4-categorias-ajedrez-1-tablet.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-4-categorias-2.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-4-categorias-3.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-categoria-4.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },

  IMG_TAB_SLIDER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ImgTabSliderComponent",
    informationTree: [
      [
        {
          onClickId: "tab",
          title: "Tabs",
          icon: TabIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          imgs: [
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 1",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
      {
        component: {
          imgs: [
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 2",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    type: componentType.slider,
  },

  IMG_TAB_SLIDER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ImgTabSliderTabletComponent",
    informationTree: [
      [
        {
          onClickId: "tab",
          title: "Tabs",
          icon: TabIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          imgs: [
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 1",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
      {
        component: {
          imgs: [
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 2",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    type: componentType.slider,
  },

  IMG_SLIDER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ImgSliderComponent",
    informationTree: [
      [
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          imgs: [
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-desktop.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 1",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    type: componentType.popular,
  },

  IMG_SLIDER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ImgSliderTabletComponent",
    informationTree: [
      [
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          imgs: [
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://luma-photos.s3.amazonaws.com/content/componente-banner-tablet.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 1",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    type: componentType.popular,
  },

  IMG_SLIDER_MOBILE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ImgSliderMobileComponent",
    informationTree: [
      [
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          imgs: [
            {
              path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/632b16918a4e06101c121dee/66b303-3b63-1e8e-0621-de4f00b40b4-banner-secundario-mobile.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/632b16918a4e06101c121dee/66b303-3b63-1e8e-0621-de4f00b40b4-banner-secundario-mobile.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/632b16918a4e06101c121dee/66b303-3b63-1e8e-0621-de4f00b40b4-banner-secundario-mobile.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 1",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    type: componentType.popular,
  },

  IMG_WITH_SKUS: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ImgWithSKUsComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/imagen-con-SKU-desktop.webp",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
    ],
    type: componentType.banner,
  },

  IMG_WITH_SKUS_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ImgWithSKUsTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/imagen-con-SKU-tablet.webp.webp",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
    ],
    type: componentType.banner,
  },

  TWO_IMG_WITH_SKUS: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "TwoImgWithSKUsComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/desktop-shoppable-img-1.png",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/desktop-shoppable-img-2.webp",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
    ],
    type: componentType.banner,
  },

  TWO_IMG_WITH_SKUS_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "TwoImgWithSKUsTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/tablet-shoppable-img-2.web",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/tablet-shoppable-img-2.webp",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
    ],
    type: componentType.banner,
  },

  THREE_IMG_WITH_SKUS: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeImgWithSKUsComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/shoppable-image3-tablet.png",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/shoppable-image3-tablet.png",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/shoppable-image3-tablet.png",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
    ],
    type: componentType.banner,
  },

  THREE_IMG_WITH_SKUS_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeImgWithSKUsTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/shoppable-image3-desktop.png",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/shoppable-image3-desktop.png",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
      {
        path: "https://s3.amazonaws.com/lumarketo.cl/shoppable-image3-desktop.png",
        alt: "",
        useImgOnProductCards: true,
        buttons: [],
        link: "/",
      },
    ],
    type: componentType.banner,
  },

  BANNER_SLIDER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerSliderComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        path: "https://luma-photos.s3.amazonaws.com/photos/banner-con-productos.webp",
        alt: "",
        link: "/",
        title: "Título",
        titleAlign: "center",
        titleSize: 20,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        slidesToShow: 4,
        slidesToScroll: 1,
        tittleWeight: 400,
        arrowColor: "#000000",
        type: false,
        productFrom: "category",
      },
    ],
    type: componentType.popular,
  },
  BANNER_SLIDER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerSliderTabletComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        path: "https://luma-photos.s3.amazonaws.com/photos/banner-con-productos-tablet.webp",
        alt: "",
        link: "/",
        title: "Título",
        titleAlign: "center",
        titleSize: 20,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        slidesToShow: 3,
        slidesToScroll: 1,
        tittleWeight: 400,
        arrowColor: "#000000",
        type: false,
        productFrom: "category",
      },
    ],
    type: componentType.popular,
  },

  BANNER_BACKGROUND_SLIDER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerBackgroundSliderComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        path: "https://luma-photos.s3.amazonaws.com/photos/componente-banner-con-carrusel.webp",
        alt: "",
        link: "/",
        title: "Destacados",
        titleAlign: "left",
        titleSize: 35,
        titleColor: "#000",
        titleMargin: "5px 0px 5px 45px",
        titlePadding: "0px 5% 0px 5%",
        tittleWeight: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.slider,
  },
  BANNER_BACKGROUND_SLIDER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerBackgroundSliderTabletComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        path: "https://luma-photos.s3.amazonaws.com/photos/componente-banner-con-carrusel-tablet.webp",
        alt: "",
        link: "/",
        title: "Destacados",
        titleAlign: "left",
        titleSize: 35,
        titleColor: "#000",
        titleMargin: "5px 0px 5px 45px",
        titlePadding: "0px 5% 0px 5%",
        tittleWeight: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.slider,
  },

  LEFT_IMG_PRODUCTCARD_SLIDER: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "LeftImgProductCardSliderComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        link: "/",
        title: "Destacados",
        titleAlign: "center",
        titleSize: 35,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        titleWeight: 400,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.slider,
  },
  LEFT_IMG_PRODUCTCARD_SLIDER_TABLET: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "LeftImgProductCardSliderTabletComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        link: "/",
        title: "Destacados",
        titleAlign: "center",
        titleSize: 35,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        titleWeight: 400,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.slider,
  },

  BRAND_SLIDER: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BrandSliderComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        link: "/",
        title: "Destacados",
        titleAlign: "center",
        titleSize: 35,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        tittleWeight: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.popular,
  },
  BRAND_SLIDER_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BrandSliderTabletComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        link: "/",
        title: "Destacados",
        titleAlign: "center",
        titleSize: 35,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        tittleWeight: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.popular,
  },

  BRAND_SLIDER_MOBILE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BrandSliderMobileComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        link: "/",
        title: "Destacados",
        titleAlign: "center",
        titleSize: 35,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        tittleWeight: 400,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.popular,
  },

  SIX_CHEST_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "SixChestCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/desktop-ajedrez-6-categorias-1.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/desktop-ajedrez-6-categorias-2.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/desktop-ajedrez-6-categorias-3.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/desktop-ajedrez-6-categorias-4.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/desktop-Ajedrez-6-categorias-5.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/desktop-ajedrez-6-categorias-6.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },
  SIX_CHEST_CATEGORIES_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "SixChestCategoriesTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-6-categorias-1.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-6-categorias-2.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-6-categorias-3.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-6-categorias-4.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-6-categorias-5.webp",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-ajedrez-6-categorias-6.webp",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },

  SIX_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "SixCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-desktop.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-desktop.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-desktop.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-desktop.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-desktop.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-desktop.png",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },
  SIX_CATEGORIES_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "SixCategoriesTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-tablet.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-tablet.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-tablet.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-tablet.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-tablet.png",
        Alt: "",
        Link: "/",
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/categorias3x2-tablet.png",
        Alt: "",
        Link: "/",
      },
    ],
    type: componentType.categories,
  },

  SIX_CATEGORIES_IN_LINE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "SixCategoriesInLineComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        categories: [
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/categoria6deeesktop.webp",
            Alt: "",
            Link: "/",
          },
        ],
        title: "Título",
        titleAlign: "center",
        titleSize: 20,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        titleWeight: 400,
      },
    ],
    type: componentType.categories,
  },

  SIX_CATEGORIES_IN_LINE_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "SixCategoriesInLineTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 5",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 6",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        categories: [
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
            Alt: "",
            Link: "/",
          },
          {
            Path: "https://s3.amazonaws.com/lumarketo.cl/tablet-6-categorias-lineales(1).jpg",
            Alt: "",
            Link: "/",
          },
        ],
        title: "Título",
        titleAlign: "center",
        titleSize: 20,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        titleWeight: 400,
      },
    ],
    type: componentType.categories,
  },

  THREE_INFORMATION_BOX: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeInformationBoxComponent",
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/iconfinder-place-4341312_120536.png",
        Alt: "",
        Link: "/",
        Width: 100,
        Title: "Encuentra la tienda más cercana",
        TitleAlign: "left",
        TitleSize: 20,
        TitleColor: "#000000",
        TitleMargin: "5px 0px 5px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
        Text: "Nulla adipisicing dolor nulla est incididunt duis excepteur magna in ullamco dolor.",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/iconfinder-place-4341312_120536.png",
        Alt: "",
        Link: "/",
        Width: 100,
        Title: "Encuentra la tienda más cercana",
        TitleAlign: "left",
        TitleSize: 20,
        TitleColor: "#000000",
        TitleMargin: "5px 0px 5px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
        Text: "Labore ipsum nostrud fugiat reprehenderit dolore culpa amet et in excepteur excepteur excepteur labore.",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/iconfinder-place-4341312_120536.png",
        Alt: "",
        Link: "/",
        Width: 100,
        Title: "Encuentra la tienda más cercana",
        TitleAlign: "left",
        TitleSize: 20,
        TitleColor: "#000000",
        TitleMargin: "5px 0px 5px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
        Text: "Tempor exercitation laboris elit dolore reprehenderit qui cillum nulla nisi sit nostrud qui.",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
      },
    ],
    columns: 12,
  },

  THREE_CATEGORIES: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeCategoriesComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-1.webp",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-2.webp",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-3.webp",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.categories,
  },

  THREE_CATEGORIES_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ThreeCategoriesTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-1-tablet.webp",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-2-tablet.webp",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
      {
        Path: "https://luma-photos.s3.amazonaws.com/photos/3-categoria-3-tablet.webp",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.categories,
  },

  FOUR_CATEGORIES_IN_LINE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FourCategoriesInLineComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen 1 ",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 2",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 3",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
      [
        {
          onClickId: "img",
          title: "Imagen 4",
          icon: ImageIcon,
        },
        {
          onClickId: "link",
          title: "Link",
          icon: LinkIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/440x320.jpg",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/440x320.jpg",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/440x320.jpg",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/440x320.jpg",
        Alt: "",

        Link: "/",
        Width: 100,
        Margin: "0px 0px 0px 0px",
        Padding: "0px 0px 0px 0px",
      },
    ],
    type: componentType.categories,
  },
  INFORMATION_BOX: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "InformationBoxComponent",
    inputs: [
      {
        Path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/parrafo.png",
        Alt: "",
        Link: "/",
        Width: 100,
        Text: "Est cillum ipsum labore pariatur deserunt tempor. Excepteur consectetur reprehenderit occaecat in aliqua irure ipsum reprehenderit. Labore non fugiat adipisicing aute laborum mollit duis pariatur esse duis ullamco. Est reprehenderit reprehenderit nisi dolor excepteur mollit non sint. In incididunt esse culpa cupidatat quis dolor amet ipsum nisi laborum sunt tempor irure amet. Dolor fugiat exercitation cupidatat sunt dolore cillum consectetur. Excepteur in aliquip qui exercitation anim veniam id dolore qui adipisicing nostrud laborum Lorem.",
        TextAlign: "left",
        TextSize: 18,
        TextColor: "#000000",
        TextMargin: "5px 0px 5px 0px",
        TextPadding: "0px 0px 0px 0px",
        TextWeight: 400,
        BackgroundColor: "#12252626",
      },
    ],
    columns: 12,
  },
  BANNER_WITH_PRODUCTS: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerWithProductsComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "slider",
          title: "Productos",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        skus: [],
        path: "https://s3.amazonaws.com/lumarketo.cl/banner-con-productos.webp",
        alt: "",
        link: "/",
      },
    ],
    type: componentType.banner,
  },
  BANNER_WITH_PRODUCTS_TABLET: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerWithProductsTabletComponent",
    informationTree: [
      [
        {
          onClickId: "img",
          title: "Imagen",
          icon: ImageIcon,
        },
        {
          onClickId: "slider",
          title: "Productos",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        skus: [],
        path: "https://s3.amazonaws.com/lumarketo.cl/banner-con-productos-tablet.webp",
        alt: "",
        link: "/",
      },
    ],
    type: componentType.banner,
  },
  PRODUCT_MOBILE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ProductMobileComponent",
    informationTree: [
      [
        {
          onClickId: "product",
          title: "Producto",
          icon: ImageIcon,
        },
      ],
    ],
    inputs: [
      {
        sku: "",
      },
    ],
    type: componentType.popular,
  },
  FOUR_PRODUCT_MOBILE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "FourProductMobileComponent",

    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "pdp",
          title: "Productos",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        skus: "",
        title: "Título",
        titleAlign: "left",
        titleSize: 20,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        titleWeight: 400,
      },
    ],
    type: componentType.popular,
  },
  BANNER_SCROLL: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "BannerScrollMobileComponent",
    informationTree: [
      [
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          imgs: [
            {
              path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/632b16918a4e06101c121dee/66b303-3b63-1e8e-0621-de4f00b40b4-banner-secundario-mobile.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/632b16918a4e06101c121dee/66b303-3b63-1e8e-0621-de4f00b40b4-banner-secundario-mobile.webp",
              alt: "",
              link: "/",
            },
            {
              path: "https://dgdvd9d8e5bk8.cloudfront.net/photos/632b16918a4e06101c121dee/66b303-3b63-1e8e-0621-de4f00b40b4-banner-secundario-mobile.webp",
              alt: "",
              link: "/",
            },
          ],
        },
        title: "Tab 1",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrowColor: "#000000",
      },
    ],
    type: componentType.slider,
  },
  CATEGORIES_MOBILE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "CategoriesMobileComponent",
    informationTree: [
      [
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/3categorias-mobile.png",
        Alt: "",
        Link: "/",
        Title: "Título",
        TitleAlign: "left",
        TitleSize: 20,
        TitleColor: "#000000",
        TitleMargin: "5px 0px 5px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/3categorias-mobile.png",
        Alt: "",
        Link: "/",
        Title: "Título",
        TitleAlign: "left",
        TitleSize: 20,
        TitleColor: "#000000",
        TitleMargin: "5px 0px 5px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
      },
      {
        Path: "https://s3.amazonaws.com/lumarketo.cl/3categorias-mobile.png",
        Alt: "",
        Link: "/",
        Title: "Título",
        TitleAlign: "left",
        TitleSize: 20,
        TitleColor: "#000000",
        TitleMargin: "5px 0px 5px 0px",
        TitlePadding: "0px 5% 0px 5%",
        TitleWeight: 400,
      },
    ],
    type: componentType.categories,
  },
  PRODUCT_SLIDER_MOBILE: {
    pro: true,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "ProductSliderMobileComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        component: {
          skus: [],
          category: "newProducts",
          collection: "",
        },
        link: "/",
        title: "Destacados",
        titleAlign: "center",
        titleSize: 35,
        titleColor: "#000000",
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        tittleWeight: 400,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrowColor: "#000000",
        productFrom: "category",
      },
    ],
    type: componentType.slider,
  },
  PLP: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "PLPComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        order: [],
        skus: [],
        category: "newProducts",
        collection: "",
        title: "Título",
        titleAlign: "left",
        titleSize: 20,
        titleColor: "#000000",
        titleBackground: "#fff",
        titleBorderRadius: 0,
        titleLetterSpacing: 1,
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        titleWeight: 400,
        productFrom: "category",
      },
    ],
    type: componentType.popular,
  },
  VERTICAL_PLP: {
    pro: false,
    margin: "0px 0px 0px 0px",
    padding: "0px 0px 0px 0px",
    gridGap: 15,
    component: "VerticalPLPComponent",
    informationTree: [
      [
        {
          onClickId: "title",
          title: "Título",
          icon: TituloIcon,
        },
        {
          onClickId: "slider",
          title: "Carrusel",
          icon: CarruselIcon,
        },
      ],
    ],
    inputs: [
      {
        order: [],
        skus: [],
        resolution: "desktop",
        category: "newProducts",
        collection: "",
        title: "Título",
        titleAlign: "left",
        titleSize: 20,
        titleColor: "#000000",
        titleBackground: "#fff",
        titleBorderRadius: 0,
        titleLetterSpacing: 1,
        titleMargin: "5px 0px 5px 0px",
        titlePadding: "0px 5% 0px 5%",
        titleWeight: 400,
        productFrom: "category",
        backgroundColor: "#000",
        desktopNameColor: "#fff",
        desktopNameAlign: "left",
        desktopNameSize: 20,
        desktopNameBackground: "transparent",
        desktopNameBorderRadius: 0,
        desktopNameLetterSpacing: 1,
        desktopNameMargin: "5px 0px 5px 0px",
        desktopNamePadding: "0px 5% 0px 5%",
        desktopNameWeight: 400,
        desktopColorDescription: "#fff",
        desktopDescriptionAlign: "left",
        desktopDescriptionSize: 20,
        desktopDescriptionBackground: "transparent",
        desktopDescriptionBorderRadius: 0,
        desktopDescriptionLetterSpacing: 1,
        desktopDdescriptionMargin: "5px 0px 5px 0px",
        desktopDescriptionPadding: "0px 5% 0px 5%",
        desktopDescriptionWeight: 400,
        desktopBackgroundColorAddToCardButton: "rgba(35,47,62,1)",
        desktopTextColorAddToCardButton: "#fff",
        desktopTextSizeAddToCardButton: 16,
        desktopPriceColor: "rgba(122,122,122,1)",
        desktopPriceAlign: "left",
        desktopPriceSize: 20,
        desktopPriceBackground: "transparent",
        desktopPriceBorderRadius: 0,
        desktopPriceLetterSpacing: 1,
        desktopPriceMargin: "5px 0px 5px 0px",
        desktopPricePadding: "0px 5% 0px 5%",
        desktopPriceWeight: 400,
        desktopPriceWithDiscountColor: "rgba255,95,23,1)",
        desktopPriceWithDiscountAlign: "left",
        desktopPriceWithDiscountSize: 20,
        desktopPriceWithDiscountBackground: "transparent",
        desktopPriceWithDiscountBorderRadius: 0,
        desktopPriceWithDiscountLetterSpacing: 1,
        desktopPriceWithDiscountMargin: "5px 0px 5px 0px",
        desktopPriceWithDiscountPadding: "0px 5% 0px 5%",
        desktopPriceWithDiscountWeight: 400,
        desktopBackgroundColorDiscountSpan: "rgba(255,192,12,1)",
        desktopTextColorDiscountSpan: "#000",
        desktopUseDiscountColorText: false,
        desktopTextAddToCardButton: "Agregar",
        desktopMarginAddToCardButton: "0px 0px 0px 0px",
        desktopPaddingAddToCardButton: "0px 0px 0px 0px",
        desktopAlignAddToCardButton: "left",
        desktopWeightAddToCardButton: 400,
        desktopColumns: 4,
        desktopMaxWidth: "100%",

        tabletColumns: 3,
        mobileColumns: 2,
        tabletTextAddToCardButton: "Agregar",
        tabletNameColor: "#fff",
        tabletNameAlign: "left",
        tabletNameSize: 20,
        tabletNameBackground: "transparent",
        tabletNameBorderRadius: 0,
        tabletNameLetterSpacing: 1,
        tabletNameMargin: "5px 0px 5px 0px",
        tabletNamePadding: "0px 5% 0px 5%",
        tabletNameWeight: 400,
        tabletColorDescription: "#fff",
        tabletDescriptionAlign: "left",
        tabletDescriptionSize: 20,
        tabletDescriptionBackground: "transparent",
        tabletDescriptionBorderRadius: 0,
        tabletDescriptionLetterSpacing: 1,
        tabletDdescriptionMargin: "5px 0px 5px 0px",
        tabletDescriptionPadding: "0px 5% 0px 5%",
        tabletDescriptionWeight: 400,
        tabletBackgroundColorAddToCardButton: "rgba(35,47,62,1)",
        tabletTextColorAddToCardButton: "#fff",
        tabletTextSizeAddToCardButton: 16,
        tabletPriceColor: "rgba(122,122,122,1)",
        tabletPriceAlign: "left",
        tabletPriceSize: 20,
        tabletPriceBackground: "transparent",
        tabletPriceBorderRadius: 0,
        tabletPriceLetterSpacing: 1,
        tabletPriceMargin: "5px 0px 5px 0px",
        tabletPricePadding: "0px 5% 0px 5%",
        tabletPriceWeight: 400,
        tabletPriceWithDiscountColor: "rgba255,95,23,1)",
        tabletPriceWithDiscountAlign: "left",
        tabletPriceWithDiscountSize: 20,
        tabletPriceWithDiscountBackground: "transparent",
        tabletPriceWithDiscountBorderRadius: 0,
        tabletPriceWithDiscountLetterSpacing: 1,
        tabletPriceWithDiscountMargin: "5px 0px 5px 0px",
        tabletPriceWithDiscountPadding: "0px 5% 0px 5%",
        tabletPriceWithDiscountWeight: 400,
        tabletBackgroundColorDiscountSpan: "rgba(255,192,12,1)",
        tabletTextColorDiscountSpan: "#000",
        tabletUseDiscountColorText: false,
        tabletMarginAddToCardButton: "0px 0px 0px 0px",
        tabletPaddingAddToCardButton: "0px 0px 0px 0px",
        tabletAlignAddToCardButton: "left",
        tabletWeightAddToCardButton: 400,
        tabletMaxWidth: "100%",

        mobileTextAddToCardButton: "Agregar",
        mobileNameColor: "#fff",
        mobileNameAlign: "left",
        mobileNameSize: 20,
        mobileNameBackground: "transparent",
        mobileNameBorderRadius: 0,
        mobileNameLetterSpacing: 1,
        mobileNameMargin: "5px 0px 5px 0px",
        mobileNamePadding: "0px 5% 0px 5%",
        mobileNameWeight: 400,
        mobileColorDescription: "#fff",
        mobileDescriptionAlign: "left",
        mobileDescriptionSize: 20,
        mobileDescriptionBackground: "transparent",
        mobileDescriptionBorderRadius: 0,
        mobileDescriptionLetterSpacing: 1,
        mobileDdescriptionMargin: "5px 0px 5px 0px",
        mobileDescriptionPadding: "0px 5% 0px 5%",
        mobileDescriptionWeight: 400,
        mobileBackgroundColorAddToCardButton: "rgba(35,47,62,1)",
        mobileTextColorAddToCardButton: "#fff",
        mobileTextSizeAddToCardButton: 16,
        mobilePriceColor: "rgba(122,122,122,1)",
        mobilePriceAlign: "left",
        mobilePriceSize: 20,
        mobilePriceBackground: "transparent",
        mobilePriceBorderRadius: 0,
        mobilePriceLetterSpacing: 1,
        mobilePriceMargin: "5px 0px 5px 0px",
        mobilePricePadding: "0px 5% 0px 5%",
        mobilePriceWeight: 400,
        mobilePriceWithDiscountColor: "rgba255,95,23,1)",
        mobilePriceWithDiscountAlign: "left",
        mobilePriceWithDiscountSize: 20,
        mobilePriceWithDiscountBackground: "transparent",
        mobilePriceWithDiscountBorderRadius: 0,
        mobilePriceWithDiscountLetterSpacing: 1,
        mobilePriceWithDiscountMargin: "5px 0px 5px 0px",
        mobilePriceWithDiscountPadding: "0px 5% 0px 5%",
        mobilePriceWithDiscountWeight: 400,
        mobileBackgroundColorDiscountSpan: "rgba(255,192,12,1)",
        mobileTextColorDiscountSpan: "#000",
        mobileUseDiscountColorText: false,
        mobileMarginAddToCardButton: "0px 0px 0px 0px",
        mobilePaddingAddToCardButton: "0px 0px 0px 0px",
        mobileAlignAddToCardButton: "left",
        mobileWeightAddToCardButton: 400,
        mobileMaxWidth: "100%",
      },
    ],
    type: componentType.popular,
  },
};

export const listComponents = {
  [webConstants.HTML.component]: webConstants.HTML,
  [webConstants.BUTTON.component]: webConstants.BUTTON,
  [webConstants.TEXT.component]: webConstants.TEXT,
  [webConstants.VIDEO.component]: webConstants.VIDEO,
  [webConstants.BANNER.component]: webConstants.BANNER,
  [webConstants.BANNER_MOBILE.component]: webConstants.BANNER_MOBILE,
  [webConstants.BANNER_TABLET.component]: webConstants.BANNER_TABLET,
  [webConstants.TWO_BANNER.component]: webConstants.TWO_BANNER,
  [webConstants.THREE_BANNER.component]: webConstants.THREE_BANNER,
  [webConstants.TWO_BANNER_TABLET.component]: webConstants.TWO_BANNER_TABLET,
  [webConstants.THREE_BANNER_TABLET.component]:
    webConstants.THREE_BANNER_TABLET,
  [webConstants.CATEGORY_BANNER.component]: webConstants.CATEGORY_BANNER,
  [webConstants.CATEGORY_BANNER_TABLET.component]:
    webConstants.CATEGORY_BANNER_TABLET,
  [webConstants.PRODUCT_TAB.component]: webConstants.PRODUCT_TAB,
  [webConstants.PRODUCT_TAB_TABLET.component]: webConstants.PRODUCT_TAB_TABLET,
  [webConstants.FEATURED_PRODUCTS.component]: webConstants.FEATURED_PRODUCTS,
  [webConstants.FEATURED_PRODUCTS_TABLET.component]:
    webConstants.FEATURED_PRODUCTS_TABLET,
  [webConstants.BANNER_WITH_BUTTON.component]: webConstants.BANNER_WITH_BUTTON,
  [webConstants.BANNER_WITH_BUTTON_TABLET.component]:
    webConstants.BANNER_WITH_BUTTON_TABLET,
  // [webConstants.PRODUCTS_RELEASES.component]:
  //   webConstants.PRODUCTS_RELEASES,
  [webConstants.VIDEO_WITH_TEXT.component]: webConstants.VIDEO_WITH_TEXT,
  [webConstants.THREE_VIDEO.component]: webConstants.THREE_VIDEO,
  [webConstants.FOUR_CATEGORIES.component]: webConstants.FOUR_CATEGORIES,
  [webConstants.FOUR_CATEGORIES_TABLET.component]:
    webConstants.FOUR_CATEGORIES_TABLET,
  [webConstants.BLOGS.component]: webConstants.BLOGS,
  // [webConstants.EIGHT_CATEGORIES.component]: webConstants.EIGHT_CATEGORIES,
  [webConstants.FIVE_CATEGORIES.component]: webConstants.FIVE_CATEGORIES,
  [webConstants.FIVE_CATEGORIES_TABLET.component]:
    webConstants.FIVE_CATEGORIES_TABLET,
  [webConstants.FIVE_CHEST_CATEGORIES.component]:
    webConstants.FIVE_CHEST_CATEGORIES,
  [webConstants.FIVE_CHEST_CATEGORIES_TABLET.component]:
    webConstants.FIVE_CHEST_CATEGORIES_TABLET,
  [webConstants.FOUR_CHEST_CATEGORIES.component]:
    webConstants.FOUR_CHEST_CATEGORIES,
  [webConstants.FOUR_CHEST_CATEGORIES_TABLET.component]:
    webConstants.FOUR_CHEST_CATEGORIES_TABLET,
  [webConstants.IMG_TAB_SLIDER.component]: webConstants.IMG_TAB_SLIDER,
  [webConstants.IMG_TAB_SLIDER_TABLET.component]:
    webConstants.IMG_TAB_SLIDER_TABLET,
  [webConstants.IMG_SLIDER.component]: webConstants.IMG_SLIDER,
  [webConstants.IMG_SLIDER_TABLET.component]: webConstants.IMG_SLIDER_TABLET,
  [webConstants.IMG_SLIDER_MOBILE.component]: webConstants.IMG_SLIDER_MOBILE,
  [webConstants.IMG_WITH_SKUS.component]: webConstants.IMG_WITH_SKUS,
  [webConstants.IMG_WITH_SKUS_TABLET.component]:
    webConstants.IMG_WITH_SKUS_TABLET,
  [webConstants.TWO_IMG_WITH_SKUS.component]: webConstants.TWO_IMG_WITH_SKUS,
  [webConstants.TWO_IMG_WITH_SKUS_TABLET.component]:
    webConstants.TWO_IMG_WITH_SKUS_TABLET,
  [webConstants.THREE_IMG_WITH_SKUS.component]:
    webConstants.THREE_IMG_WITH_SKUS,
  [webConstants.THREE_IMG_WITH_SKUS_TABLET.component]:
    webConstants.THREE_IMG_WITH_SKUS_TABLET,
  [webConstants.BANNER_SLIDER.component]: webConstants.BANNER_SLIDER,
  [webConstants.BANNER_SLIDER_TABLET.component]:
    webConstants.BANNER_SLIDER_TABLET,
  [webConstants.BANNER_BACKGROUND_SLIDER.component]:
    webConstants.BANNER_BACKGROUND_SLIDER,
  [webConstants.BANNER_BACKGROUND_SLIDER_TABLET.component]:
    webConstants.BANNER_BACKGROUND_SLIDER_TABLET,
  [webConstants.LEFT_IMG_PRODUCTCARD_SLIDER.component]:
    webConstants.LEFT_IMG_PRODUCTCARD_SLIDER,
  [webConstants.LEFT_IMG_PRODUCTCARD_SLIDER_TABLET.component]:
    webConstants.LEFT_IMG_PRODUCTCARD_SLIDER_TABLET,
  [webConstants.BRAND_SLIDER.component]: webConstants.BRAND_SLIDER,
  [webConstants.BRAND_SLIDER_TABLET.component]:
    webConstants.BRAND_SLIDER_TABLET,
  [webConstants.BRAND_SLIDER_MOBILE.component]:
    webConstants.BRAND_SLIDER_MOBILE,
  [webConstants.SIX_CHEST_CATEGORIES.component]:
    webConstants.SIX_CHEST_CATEGORIES,
  [webConstants.SIX_CHEST_CATEGORIES_TABLET.component]:
    webConstants.SIX_CHEST_CATEGORIES_TABLET,
  [webConstants.SIX_CATEGORIES.component]: webConstants.SIX_CATEGORIES,
  [webConstants.SIX_CATEGORIES_TABLET.component]:
    webConstants.SIX_CATEGORIES_TABLET,
  [webConstants.SIX_CATEGORIES_IN_LINE.component]:
    webConstants.SIX_CATEGORIES_IN_LINE,
  [webConstants.SIX_CATEGORIES_IN_LINE_TABLET.component]:
    webConstants.SIX_CATEGORIES_IN_LINE_TABLET,
  [webConstants.THREE_INFORMATION_BOX.component]:
    webConstants.THREE_INFORMATION_BOX,
  [webConstants.THREE_CATEGORIES.component]: webConstants.THREE_CATEGORIES,
  [webConstants.THREE_CATEGORIES_TABLET.component]:
    webConstants.THREE_CATEGORIES_TABLET,
  [webConstants.FOUR_CATEGORIES_IN_LINE.component]:
    webConstants.FOUR_CATEGORIES_IN_LINE,
  [webConstants.INFORMATION_BOX.component]: webConstants.INFORMATION_BOX,
  [webConstants.BANNER_WITH_PRODUCTS.component]:
    webConstants.BANNER_WITH_PRODUCTS,
  [webConstants.BANNER_WITH_PRODUCTS_TABLET.component]:
    webConstants.BANNER_WITH_PRODUCTS_TABLET,
  [webConstants.TWO_BLOGS.component]: webConstants.TWO_BLOGS,
  [webConstants.PRODUCT_MOBILE.component]: webConstants.PRODUCT_MOBILE,
  [webConstants.FOUR_PRODUCT_MOBILE.component]:
    webConstants.FOUR_PRODUCT_MOBILE,
  [webConstants.VIDEO_WITH_TEXT_MOBILE.component]:
    webConstants.VIDEO_WITH_TEXT_MOBILE,
  [webConstants.BANNER_SCROLL.component]: webConstants.BANNER_SCROLL,
  [webConstants.CATEGORIES_MOBILE.component]: webConstants.CATEGORIES_MOBILE,
  [webConstants.PRODUCT_SLIDER_MOBILE.component]:
    webConstants.PRODUCT_SLIDER_MOBILE,
  [webConstants.PLP.component]: webConstants.PLP,
  [webConstants.VERTICAL_PLP.component]: webConstants.VERTICAL_PLP,
};

// https://api.lorem.space/image/furniture?w=350&h=350

export const resolutions = {
  desktop: "desktop",
  tablet: "tablet",
  mobile: "mobile",
};

export const desktopAvailableComponents = [
  {
    ...listComponents["HtmlComponent"],
    resolution: resolutions.desktop,
    title: "Html",
    icon: HtmlImg,
  },
  {
    ...listComponents["ProductsTabComponent"],
    resolution: resolutions.desktop,
    title: "Carrusel por departamento",
    icon: CarruselPorDepartamentoImg,
  },
  {
    ...listComponents["FeaturedProductsComponent"],
    resolution: resolutions.desktop,
    title: "Productos destacados",
    icon: ProductoDestacadoImg,
  },
  {
    ...listComponents["BannerWithButtonComponent"],
    resolution: resolutions.desktop,
    title: "Banner con botón",
    icon: BannerWithButtonImg,
  },
  {
    ...listComponents["BannerComponent"],
    resolution: resolutions.desktop,
    title: "Banner",
    icon: BannerImg,
  },
  {
    ...listComponents["TwoBannerComponent"],
    resolution: resolutions.desktop,
    title: "Dos Banners",
    icon: DosBannersImg,
  },
  {
    ...listComponents["ThreeBannerComponent"],
    resolution: resolutions.desktop,
    title: "Tres Banners",

    icon: TresBannersImg,
  },
  {
    ...listComponents["CategoryBannerComponent"],
    resolution: resolutions.desktop,
    title: "Banners de categorias",
    icon: BannerImg,
  },
  {
    ...listComponents["FourCategoriesComponent"],
    resolution: resolutions.desktop,
    title: "4 Categorias",
    icon: FourCategoriesImg,
  },
  {
    ...listComponents["TextComponent"],
    resolution: resolutions.desktop,
    title: "Texto",
    icon: TextImg,
  },
  {
    ...listComponents["ButtonComponent"],
    resolution: resolutions.desktop,
    title: "Botón",
    icon: ButtonImg,
  },
  {
    ...listComponents["FiveCategoriesComponent"],
    resolution: resolutions.desktop,
    title: "5 Categorias",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["FiveChestCategoriesComponent"],
    resolution: resolutions.desktop,
    title: "5 Categorias Ajedrez",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["FourChestCategoriesComponent"],
    resolution: resolutions.desktop,
    title: "4 Categorias Ajedrez",
    icon: FourCategoriesImg,
  },
  {
    ...listComponents["ImgTabSliderComponent"],
    resolution: resolutions.desktop,
    title: "Tabs de Imagenes",
    icon: ImagesCarrouselImg,
  },
  {
    ...listComponents["ImgSliderComponent"],
    resolution: resolutions.desktop,
    title: "Carrusel de Banners",
    icon: ImagesCarrouselImg,
  },
  {
    ...listComponents["ImgWithSKUsComponent"],
    resolution: resolutions.desktop,
    title: "Imagen con SKUs",
    icon: BannerImg,
  },
  {
    ...listComponents["TwoImgWithSKUsComponent"],
    resolution: resolutions.desktop,
    title: "Dos Imagen con SKUs",
    icon: DosCategoriesImg,
  },
  {
    ...listComponents["ThreeImgWithSKUsComponent"],
    resolution: resolutions.desktop,
    title: "Tres Imagen con SKUs",
    icon: TresBannersImg,
  },
  {
    ...listComponents["BannerSliderComponent"],
    resolution: resolutions.desktop,
    title: "Banner con carrusel",
    icon: ImagesCarrouselImg,
  },
  {
    ...listComponents["BannerBackgroundSliderComponent"],
    resolution: resolutions.desktop,
    title: "Banner de fondo con carrusel",
    icon: CarruselPorDepartamentoImg,
  },
  {
    ...listComponents["LeftImgProductCardSliderComponent"],
    resolution: resolutions.desktop,
    title: "Productos",
    icon: PLPIcon,
  },
  {
    ...listComponents["BrandSliderComponent"],
    resolution: resolutions.desktop,
    title: "Carrusel de marcas",
    icon: BrandCarrouselImg,
  },
  {
    ...listComponents["SixChestCategoriesComponent"],
    resolution: resolutions.desktop,
    title: "6 Categorias Ajedrez",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["SixCategoriesComponent"],
    resolution: resolutions.desktop,
    title: "6 Categorias",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["SixCategoriesInLineComponent"],
    resolution: resolutions.desktop,
    title: "6 Categorias en linea",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["ThreeCategoriesComponent"],
    resolution: resolutions.desktop,
    title: "3 Categorias",
    icon: TresBannersImg,
  },
  {
    ...listComponents["FourCategoriesInLineComponent"],
    resolution: resolutions.desktop,
    title: "4 Categorias en linea",
    icon: FourCategoriesImg,
  },
  {
    ...listComponents["BannerWithProductsComponent"],
    resolution: resolutions.desktop,
    title: "Banner con productos",
    icon: BannerImg,
  },
  {
    ...listComponents["PLPComponent"],
    resolution: resolutions.desktop,
    title: "Lista de productos",
    icon: PLPIcon,
  },
  {
    ...listComponents["VerticalPLPComponent"],
    resolution: resolutions.desktop,
    title: "Lista de productos destacados",
    icon: PLPIcon,
  },
];

export const tabletAvailableComponents = [
  {
    ...listComponents["HtmlComponent"],
    resolution: resolutions.tablet,
    title: "Html",
    icon: HtmlImg,
  },
  {
    ...listComponents["PLPTabletComponent"],
    resolution: resolutions.tablet,
    title: "Lista de productos",
    icon: PLPIcon,
  },
  {
    ...listComponents["ProductsTabTabletComponent"],
    resolution: resolutions.tablet,
    title: "Carrusel por departamento",
    icon: HtmlImg,
  },
  {
    ...listComponents["FeaturedProductsTabletComponent"],
    resolution: resolutions.tablet,
    title: "Productos destacados",
    icon: ProductoDestacadoImg,
  },
  {
    ...listComponents["BannerWithButtonTabletComponent"],
    resolution: resolutions.tablet,
    title: "Banner con botón",
    icon: BannerWithButtonImg,
  },
  {
    ...listComponents["BannerTabletComponent"],
    resolution: resolutions.tablet,
    title: "Banner",
    icon: BannerImg,
  },
  {
    ...listComponents["TwoBannerTabletComponent"],
    resolution: resolutions.tablet,
    title: "Dos Banners",
    icon: DosBannersImg,
  },
  {
    ...listComponents["ThreeBannerTabletComponent"],
    resolution: resolutions.tablet,
    title: "Tres Banners",

    icon: TresBannersImg,
  },
  {
    ...listComponents["CategoryBannerTabletComponent"],
    resolution: resolutions.tablet,
    title: "Banners de categorias",
    icon: BannerImg,
  },
  {
    ...listComponents["FourCategoriesTabletComponent"],
    resolution: resolutions.tablet,
    title: "4 Categorias",
    icon: FourCategoriesImg,
  },
  {
    ...listComponents["TextComponent"],
    resolution: resolutions.tablet,
    title: "Texto",
    icon: TextImg,
  },
  {
    ...listComponents["ButtonComponent"],
    resolution: resolutions.tablet,
    title: "Botón",
    icon: ButtonImg,
  },
  {
    ...listComponents["FiveCategoriesTabletComponent"],
    resolution: resolutions.tablet,
    title: "5 Categorias",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["FiveChestCategoriesTabletComponent"],
    resolution: resolutions.tablet,
    title: "5 Categorias Ajedrez",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["FourChestCategoriesTabletComponent"],
    resolution: resolutions.tablet,
    title: "4 Categorias Ajedrez",
    icon: FourCategoriesImg,
  },
  {
    ...listComponents["ImgTabSliderTabletComponent"],
    resolution: resolutions.tablet,
    title: "Tabs de Imagenes",
    icon: ImagesCarrouselImg,
  },
  {
    ...listComponents["ImgSliderTabletComponent"],
    resolution: resolutions.tablet,
    title: "Carrusel de Banners",
    icon: ImagesCarrouselImg,
  },
  {
    ...listComponents["ImgWithSKUsTabletComponent"],
    resolution: resolutions.tablet,
    title: "Imagen con SKUs",
    icon: BannerImg,
  },
  {
    ...listComponents["TwoImgWithSKUsTabletComponent"],
    resolution: resolutions.tablet,
    title: "Dos Imagen con SKUs",
    icon: DosCategoriesImg,
  },
  {
    ...listComponents["ThreeImgWithSKUsTabletComponent"],
    resolution: resolutions.tablet,
    title: "Tres Imagen con SKUs",
    icon: TresBannersImg,
  },
  {
    ...listComponents["BannerSliderTabletComponent"],
    resolution: resolutions.tablet,
    title: "Banner con carrusel",
    icon: ImagesCarrouselImg,
  },
  {
    ...listComponents["BannerBackgroundSliderTabletComponent"],
    resolution: resolutions.tablet,
    title: "Banner de fondo con carrusel",
    icon: CarruselPorDepartamentoImg,
  },
  {
    ...listComponents["LeftImgProductCardSliderTabletComponent"],
    resolution: resolutions.tablet,
    title: "Productos",
    icon: PLPIcon,
  },
  {
    ...listComponents["BrandSliderTabletComponent"],
    resolution: resolutions.tablet,
    title: "Carrusel de marcas",
    icon: BrandCarrouselImg,
  },
  {
    ...listComponents["SixChestCategoriesTabletComponent"],
    resolution: resolutions.tablet,
    title: "6 Categorias Ajedrez",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["SixCategoriesTabletComponent"],
    resolution: resolutions.tablet,
    title: "6 Categorias",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["SixCategoriesInLineTabletComponent"],
    resolution: resolutions.tablet,
    title: "6 Categorias en linea",
    icon: SixCategoriesImg,
  },
  {
    ...listComponents["ThreeCategoriesTabletComponent"],
    resolution: resolutions.tablet,
    title: "3 Categorias",
    icon: TresBannersImg,
  },
  {
    ...listComponents["FourCategoriesInLineTabletComponent"],
    resolution: resolutions.tablet,
    title: "4 Categorias en linea",
    icon: FourCategoriesImg,
  },
  {
    ...listComponents["BannerWithProductsTabletComponent"],
    resolution: resolutions.tablet,
    title: "Banner con productos",
    icon: BannerImg,
  },
];

export const mobileAvailableComponents = [
  {
    ...listComponents["HtmlComponent"],
    resolution: resolutions.mobile,
    title: "Html",
    icon: CarruselPorDepartamentoImg,
  },
  {
    ...listComponents["ProductMobileComponent"],
    resolution: resolutions.mobile,
    title: "Producto",
    icon: LanzamientosImg,
  },
  {
    ...listComponents["FourProductMobileComponent"],
    resolution: resolutions.mobile,
    title: "4 Productos",
    icon: LanzamientosImg,
  },
  {
    ...listComponents["BannerMobileComponent"],
    resolution: resolutions.mobile,
    title: "Banner",
    icon: BannerImg,
  },
  {
    ...listComponents["TextComponent"],
    resolution: resolutions.mobile,
    title: "Texto",
    icon: TextImg,
  },
  {
    ...listComponents["ButtonComponent"],
    resolution: resolutions.mobile,
    title: "Botón",
    icon: ButtonImg,
  },
  {
    ...listComponents["ImgSliderMobileComponent"],
    resolution: resolutions.mobile,
    title: "Carrusel de Banners",
    icon: CarruselPorDepartamentoImg,
  },

  {
    ...listComponents["ProductSliderMobileComponent"],
    resolution: resolutions.mobile,
    title: "Carrusel de Productos",
    icon: CarruselPorDepartamentoImg,
  },
  {
    ...listComponents["BrandSliderMobileComponent"],
    resolution: resolutions.mobile,
    title: "Carrusel de marcas",
    icon: BrandCarrouselImg,
  },
  {
    ...listComponents["BannerScrollMobileComponent"],
    resolution: resolutions.mobile,
    title: "Banners desplazables",
    icon: CarruselPorDepartamentoImg,
  },
  {
    ...listComponents["CategoriesMobileComponent"],
    resolution: resolutions.mobile,
    title: "Categorias",
    icon: TresBannersImg,
  },
];

export default webConstants;
