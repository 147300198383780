/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import Notification from "./containers/notification";
import LoadingPage from "./containers/loading";
import Routes from "./routes";

import "./App.css";
import 'react-quill/dist/quill.snow.css';

function App(props) {
  const { store } = props;

  return (
    <SnackbarProvider
      autoHideDuration={10000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      maxSnack={3}
      preventDuplicate
      content={(key, message) => <Notification id={key} message={message} />}
    >
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Suspense fallback={<LoadingPage />}>
            <Routes store={store} />
          </Suspense>
        </MuiPickersUtilsProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
