/* import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { store } from "./store";

import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";

const render = () => {
  ReactDOM.render(<App store={store()} />, document.getElementById("root"));
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
 */

import React from "react";
import ReactDOM from "react-dom/client"; // Note the change here
import "./index.css";
import { store } from "./store";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";

// Create the root element using the new API
const root = ReactDOM.createRoot(document.getElementById("root"));

const render = () => {
  root.render(<App store={store()} />);
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
