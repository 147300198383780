/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { createTheme, Drawer, MuiThemeProvider } from "@material-ui/core";
import classNames from "classnames";
import { Fragment, memo, useCallback, useEffect, useState } from "react";

import styles from "./styles.module.css";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Routes from "../../../constants/routes";
import webConstants, {
  componentType,
  desktopAvailableComponents,
  mobileAvailableComponents,
  tabletAvailableComponents,
} from "../../../constants/web.constants";

import orderIcon from "../../../assets/img/ordenar-abajo.svg";
import templatesIcon from "../../../assets/img/templates.svg";
import useQuery from "../../../utils/hooks/queryParameters";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { resolutions } from "../../../constants/web.constants";
import {
  loadingOff,
  loadingOn,
  selectHeightPixels,
  selectResolution,
  setResolution,
} from "../../../reducers/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getComponent,
  removeComponent,
  reorderComponentsFromSiderBar,
  selectComponents,
  selectSelectedComponent,
  setEditTab,
  setSelectedComponent,
  setShowConfigDialog,
  setWebIndex,
  setWebSubIndex,
} from "../../../reducers/webSlice";
import _orderBy from "lodash/orderBy";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import CircularProgress from "@material-ui/core/CircularProgress";
import ConfigIcon from "../../../assets/img/configuraciones-icono.svg";
import WarningDeleteDialog from "../../web/home/warningDeleteModal.component";
import {
  DragDropContext,
  Draggable,
  Droppable,
  resetServerContext,
} from "react-beautiful-dnd";
import uuid from "react-uuid";
import { onClickToElement } from "../../web/dndComponents/properties.component";
import capitalize from "capitalize";
import DynamicPopup from "../../popup/dynamic.component";
import InputComponent, {
  MultiSKUInputComponent,
  SelectComponent,
} from "../../inputs";
import InputAdornment from "@material-ui/core/InputAdornment";

import successIcon from "../../../assets/img/icon-alert-success.png";
import errorIcon from "../../../assets/img/icon-alert-error.png";
import {
  selectBrands,
  selectCompany,
  selectSKUCollections,
} from "../../../reducers/companySlice";
import {
  availableDynamicPageName,
  createDynamicPage,
  deleteDynamicPage,
  getCustomWebComponents,
  getDynamicPage,
  getDynamicPages,
  updateDynamicPage,
  verifyCustomWebComponentToken,
} from "../../../utils/services/company";
import { slugifyText } from "../../../utils/string";
import Axios from "axios";
import { Notification } from "../../../containers/notification";
import { useSnackbar } from "notistack";
//import { selectProducts } from "../../../reducers/productSlice";
import WarningDeletePageDialog from "./warningDeleteModal.component";
import CreateWebComponentDialog from "./createWebComponentModal.component";
import CreateTemplateComponentDialog from "./createTemplate.component";
import useGetProducts from "../../../utils/hooks/products/useGetProducts";
import { selectUser } from "../../../reducers/userSlice";
const footerIcon = "https://s3.amazonaws.com/lumarketo.cl/footer.svg";
const headerIcon = "https://s3.amazonaws.com/lumarketo.cl/header.svg";
// const colorsIcon = "https://s3.amazonaws.com/lumarketo.cl/color-icon.svg";
const bannersIcon = "https://s3.amazonaws.com/lumarketo.cl/imagen+1.svg";
// const customerServiceIcon =
//   "https://s3.amazonaws.com/lumarketo.cl/call-center+1.svg";
const typographyIcon =
  "https://s3.amazonaws.com/lumarketo.cl/tipo-de-letra-icon.svg";
const newsletterIcon =
  "https://s3.amazonaws.com/lumarketo.cl/newsletter-icon.svg";
const productCardIcon =
  "https://s3.amazonaws.com/lumarketo.cl/tarjeta-de-productos.svg";
const logoIcon = "https://s3.amazonaws.com/lumarketo.cl/Logoicon.svg";

const sizes = [
  {
    label: "PC",
    value: resolutions.desktop,
    icon: (
      <DesktopWindowsIcon fontSize="small" style={{ width: 15, height: 15 }} />
    ),
  },
  {
    label: "Tablet",
    value: resolutions.tablet,
    icon: (
      <TabletAndroidIcon fontSize="small" style={{ width: 15, height: 15 }} />
    ),
  },
  {
    label: "Mobil",
    value: resolutions.mobile,
    icon: (
      <PhoneAndroidIcon fontSize="small" style={{ width: 15, height: 15 }} />
    ),
  },
];

const NewPageMode = {
  NewPage: "newPage",
  NewProductPage: "newProductPage",
  None: "none",
};

const ProductPageMode = {
  AllSkus: "all",
  Skus: "skus",
  Brand: "brand",
  None: "none",
};

const notAvailable = [
  "/",
  "[",
  "*",
  "+",
  "~",
  ".",
  "(",
  ")",
  "{",
  "}",
  "]",
  "#",
  "$",
  "%",
  "^",
  "&",
  ",",
  "=",
  '"',
  "!",
  ":",
  "@",
];

const ModuleWebSideBar = memo(() => {
  const collections = useSelector(selectSKUCollections);
  const brands = useSelector(selectBrands);
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);

  //const products = useSelector(selectProducts);
  const { products } = useGetProducts(
    company,
    user?.token /* [
    refreshKey,
  ] */
  );
  const [showGeneralSettings, setShowGeneralSettins] = useState(true);
  const [showPagesSettinggs, setShowPagesSettings] = useState(true);
  const [dynamicPages, setDynamicPages] = useState([]);
  const [showNewDynamicPagePopup, setShowNewDynamicPagePopup] = useState(false);
  const [showDeleteDynamicPagePopup, setDeleteNewDynamicPagePopup] =
    useState(false);
  const [newDynamicPageMode, setNewDynamicPageMode] = useState(
    NewPageMode.None
  );
  const [newDynamicPageName, setNewDynamicPageName] = useState("");
  const [availableNewDynamicPageName, setAvailableNewDynamicPageName] =
    useState(false);
  const [validatingNewDynamicPageName, setValidatingNewDynamicPageName] =
    useState(false);
  const [productPageMode, setProductPageMode] = useState(ProductPageMode.None);
  const [brand, setBrand] = useState("none");
  const [skus, setSkus] = useState([]);
  const [allSkus, setAllSkus] = useState(false);
  const [page, setPage] = useState();
  const [allSkusPage, setAllSkusPage] = useState();
  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const resolution = useSelector(selectResolution);
  const location = useLocation();
  const dispatch = useDispatch();
  const query = useQuery(useLocation);
  const c = query.get("c");
  const pageQuery = query.get("page");
  const admin = query.get("admin");
  const token = query.get("token");
  const template = query.get("template");
  const [showSaveModalIcon, setShowModalIcon] = useState(false);
  const heightPixels = useSelector(selectHeightPixels);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function verifyToken() {
      const verify = await verifyCustomWebComponentToken(token).catch((err) => {
        console.log(err);
        return null;
      });
      setShowModalIcon(verify && verify.validate);
      if (template) setShowTemplatePopup(template === "false" ? false : true);
      else setShowTemplatePopup(false);
    }
    if (admin && token && token !== "") {
      verifyToken();
    }
  }, [admin, token, template]);

  useEffect(() => {
    if (pageQuery && pageQuery !== "" && company && company._id !== "") {
      getDynamicPageData();
    } else setPage(undefined);
  }, [pageQuery, company]);

  const getDynamicPageData = async () => {
    const data = await getDynamicPage(pageQuery, company._id).catch((err) => {
      console.log(err);
      return null;
    });

    if (data) setPage(data);
  };

  useEffect(() => {
    let unmounted = false;
    let source = Axios.CancelToken.source();
    async function getData() {
      const pages = await getDynamicPages(company._id, source.token)
        .then((res) => {
          if (!unmounted) return res;
          return null;
        })
        .catch((err) => {
          if (!unmounted) {
            console.log(err);
          }
          return null;
        });
      if (pages) setDynamicPages(pages);
    }
    if (company && company._id !== "") getData();
    return () => {
      unmounted = true;
      source.cancel();
    };
  }, [company, location.search]);

  useEffect(() => {
    if (productPageMode === ProductPageMode.None) {
      setBrand("");
      setSkus([]);
      setAllSkus(false);
    } else if (productPageMode === ProductPageMode.Brand) {
      setSkus([]);
      setAllSkus(false);
    } else if (productPageMode === ProductPageMode.Skus) {
      setBrand("");
      setAllSkus(false);
    } else if (productPageMode === ProductPageMode.AllSkus) {
      setBrand("");
      setSkus([]);
      setAllSkus(true);
    }
  }, [productPageMode]);

  useEffect(() => {
    let unmounted = false;
    let source = Axios.CancelToken.source();

    async function validate() {
      setValidatingNewDynamicPageName(true);
      const foundDigitNotAvailable =
        notAvailable
          .map((item) => (newDynamicPageName.includes(item) ? item : null))
          .filter((item) => item).length > 0;
      if (
        newDynamicPageName === "" ||
        newDynamicPageName === "producto" ||
        newDynamicPageName === "productos" ||
        newDynamicPageName === "productos/categoria" ||
        newDynamicPageName === "productos/subcategoria" ||
        newDynamicPageName === "productos/marcas" ||
        newDynamicPageName === "productos/marca" ||
        newDynamicPageName === "productos/nombre" ||
        collections.find(
          (collection) => collection.Name.toLowerCase() === newDynamicPageName
        ) ||
        brands.find(
          (brand) => brand.Path.toLowerCase() === newDynamicPageName
        ) ||
        foundDigitNotAvailable
      ) {
        setAvailableNewDynamicPageName(
          productPageMode === ProductPageMode.Brand
        );
      } else {
        const found = await availableDynamicPageName(
          newDynamicPageName,
          company._id,
          source.token
        )
          .then((res) => {
            if (!unmounted) return res;
            return null;
          })
          .catch((err) => {
            if (!unmounted) {
              console.log(err);
            }
            return null;
          });
        if (found) setAvailableNewDynamicPageName(found.available);
        else setAvailableNewDynamicPageName(false);
      }
      setValidatingNewDynamicPageName(false);
    }
    if (company && company._id !== "") validate();
    return () => {
      unmounted = true;
      source.cancel();
    };
  }, [newDynamicPageName, brands, collections, company, productPageMode]);

  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      dispatch(
        reorderComponentsFromSiderBar({
          resolution,
          sourceIndex: result.source.index,
          destinationIndex: result.destination.index,
        })
      );
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    [dispatch, resolution]
  );

  const onDragUpdate = (result) => {
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    // });
  };

  const getNewDynamicPageTitle = () => {
    switch (newDynamicPageMode) {
      case NewPageMode.None:
        return "Configuración de páginas";
      case NewPageMode.NewPage:
        return "";
      case NewPageMode.NewProductPage:
        return "";
      default:
        return "Configuración de páginas";
    }
  };

  const getNewDynamicPageSubTitle = () => {
    switch (newDynamicPageMode) {
      case NewPageMode.None:
        return "Seleccione una las siguientes opciones de creación de página";
      case NewPageMode.NewPage:
        return "Link/nombre de la página";
      case NewPageMode.NewProductPage:
        switch (productPageMode) {
          case ProductPageMode.AllSkus:
            return allSkusPage
              ? ""
              : "Esta página saldra para todos los productos de su tienda";
          case ProductPageMode.Brand:
            return "Seleccione una de las siguientes marcas";
          case ProductPageMode.Skus:
            return "Seleccione un producto";
          default:
            return "Seleccione una de las siguientes opciones";
        }
      default:
        return "Seleccione una las siguientes opciones de creación de página";
    }
  };

  const handleSaveNewDynamicPage = async () => {
    switch (newDynamicPageMode) {
      case NewPageMode.None:
        break;
      default:
        dispatch(loadingOn());
        const createResult = await createDynamicPage({
          companyId: company._id,
          mode: newDynamicPageMode,
          name: newDynamicPageName,
          url: slugifyText(newDynamicPageName),
          brand,
          skus,
          allSkus,
          margin: 0,
          padding: 0,
          gridGap: 0,
          components: [],
          activate: true,
        }).catch((err) => {
          console.log(err);
          return null;
        });
        if (createResult) {
          setShowNewDynamicPagePopup(false);
          navigate(
            `${Routes.MODULE_WEB.path}?page=${slugifyText(newDynamicPageName)}`
          );
          enqueueSnackbar(
            Notification({
              text: "Cambio de página exitoso",
              variant: "success",
              withDetails: false,
            })
          );
          setNewDynamicPageName("");
          setNewDynamicPageMode(NewPageMode.None);
          setSkus([]);
          setBrand("");
          setAllSkus(false);
          setProductPageMode(ProductPageMode.None);
        }
        dispatch(loadingOff());
        break;
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        className={styles.drawer}
        variant="permanent"
        classes={{
          paper: classNames(styles.drawerPaper),
          docked: classNames(styles.docked),
        }}
        style={{ marginTop: heightPixels }}
      >
        <div
          style={{
            display: "block",
            position: "relative",
            overflowY: "auto",
          }}
        >
          <button
            type="button"
            className={styles.collapsible}
            onClick={() => setShowGeneralSettins((prev) => !prev)}
          >
            <h6 className={styles.titleCollapsible}>
              Personalizaciones generales{" "}
              <span>
                <img width={15} height={15} src={orderIcon} alt="open" />
              </span>
            </h6>
          </button>
          <div
            className={styles.content}
            style={{ display: showGeneralSettings ? "inherit" : "none" }}
          >
            <div className="row">
              <div className="col-12">
                <p className={styles.noteText}>
                  Las configuraciones de esta sección aplican para todas las
                  páginas y plantillas.
                </p>
              </div>
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.HEADER}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img
                        width={13}
                        height={13}
                        src={headerIcon}
                        alt="header"
                      />
                    </span>
                    Cabecera de página
                  </h6>
                </Link>
              </div>
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.FOOTER}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img
                        width={13}
                        height={13}
                        src={footerIcon}
                        alt="footer"
                      />
                    </span>
                    Pie de página
                  </h6>
                </Link>
              </div>
              {/* <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.COLORS}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img
                        width={13}
                        height={13}
                        src={colorsIcon}
                        alt="colores"
                      />
                    </span>
                    Colores
                  </h6>
                </Link>
              </div> */}
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.BANNERS}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span
                      className={styles.iconSpan}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <img
                        width={13}
                        height={13}
                        src={bannersIcon}
                        alt="banners"
                      />
                    </span>
                    Banners
                  </h6>
                </Link>
              </div>
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.TYPOGRAPHY}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span
                      className={styles.iconSpan}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <img
                        width={13}
                        height={13}
                        src={typographyIcon}
                        alt="fuentes"
                      />
                    </span>
                    Tipo de letra
                  </h6>
                </Link>
              </div>
              {/* <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.CUSTOMER_SERVICE}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span
                      className={styles.iconSpan}
                      style={{ marginRight: 5 }}
                    >
                      <img
                        width={13}
                        height={13}
                        src={customerServiceIcon}
                        alt="soporte al cliente"
                      />
                    </span>
                    Soporte al cliente
                  </h6>
                </Link>
              </div> */}
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.LOGO}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img width={13} height={13} src={logoIcon} alt="logos" />
                    </span>
                    Logos
                  </h6>
                </Link>
              </div>
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.PRODUCT_CARD}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img
                        width={13}
                        height={13}
                        src={productCardIcon}
                        alt="productCard"
                      />
                    </span>
                    Tarjeta de productos
                  </h6>
                </Link>
              </div>
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.OTHER_COMPONENTS}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img
                        width={13}
                        height={13}
                        src={productCardIcon}
                        alt="productCard"
                      />
                    </span>
                    Otros componentes
                  </h6>
                </Link>
              </div>
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.MODAL_PRODUCT_CARD}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img
                        width={13}
                        height={13}
                        src="https://s3.amazonaws.com/haya.lumarketo.cl/iconmodalmodal.png"
                        alt="modalProductCard"
                      />
                    </span>
                    PopUp de producto
                  </h6>
                </Link>
              </div>
              <div className="col-12">
                <Link
                  component={RouterLink}
                  to={`${Routes.WEB.path}?c=${webConstants.routes.NEWSLETTER}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <h6 className={styles.navBarTitle}>
                    <span className={styles.iconSpan}>
                      <img
                        width={13}
                        height={13}
                        src={newsletterIcon}
                        alt="newsletter"
                      />
                    </span>
                    PopUp de inicio
                  </h6>
                </Link>
              </div>
              <div className="col-12">
                <div className={styles.divider} />
              </div>
            </div>
          </div>
          <div className={styles.subcontent}>
            <div className="row">
              {location.pathname.includes(Routes.WEB.path) &&
                c !== webConstants.routes.TEMPLATES && (
                  <div className="col-12">
                    <Link
                      component={RouterLink}
                      to={`${Routes.MODULE_WEB.path}?page=pedir`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <button
                        className={classNames(
                          "btn blueEditionSaveButton",
                          styles.templatesButton
                        )}
                        style={{ padding: 5 }}
                      >
                        <p
                          className="blueEditionSaveButtonText"
                          style={{ fontSize: 12.5 }}
                        >
                          Personalizar páginas
                          <span style={{ marginLeft: 10 }}>
                            <img
                              src={templatesIcon}
                              alt="personalizar"
                              width={16}
                              height={16}
                            />
                          </span>
                        </p>
                      </button>
                    </Link>
                  </div>
                )}
              {(location.pathname.includes(webConstants.routes.MODULE_WEB) ||
                location.pathname.includes(
                  webConstants.routes.MODULE_DYNAMIC_PAGE
                )) && (
                <Fragment>
                  <button
                    type="button"
                    className={styles.collapsible}
                    onClick={() => setShowPagesSettings((prev) => !prev)}
                  >
                    <h6 className={styles.titleCollapsible}>
                      Personalización de página{" "}
                      <span>
                        <img
                          width={15}
                          height={15}
                          src={orderIcon}
                          alt="open"
                        />
                      </span>
                    </h6>
                  </button>
                  {showPagesSettinggs && (
                    <div className="col-6" style={{ padding: "0 0px 0 15px" }}>
                      <p className={styles.selectPageText}>Página actual:</p>
                    </div>
                  )}
                  {showPagesSettinggs && (
                    <div className="col-6" style={{ padding: "0 15px 0 0" }}>
                      <select
                        className={classNames(
                          "form-control form-control-sm  mr-sm-2",
                          styles.selectPageOptionSelected
                        )}
                        id="exampleFormControlSelect1"
                        onChange={(e) => {
                          navigate(e.target.value);
                          enqueueSnackbar(
                            Notification({
                              text: "Cambio de página exitoso",
                              variant: "success",
                              withDetails: false,
                            })
                          );
                        }}
                        value={`${location.pathname}${location.search}`}
                      >
                        {(company.UseHome
                          ? [
                              {
                                value: Routes.MODULE_WEB.path,
                                label: "Home",
                              },
                            ]
                          : []
                        )
                          .concat(
                            dynamicPages.map((item) => {
                              return {
                                value: `${Routes.MODULE_WEB.path}?page=${item.url}`,
                                label: item.name,
                              };
                            })
                          )
                          .map((item) => (
                            <option
                              key={uuid()}
                              value={item.value}
                              className={styles.selectPageOption}
                            >
                              {item.label
                                .substring(0, 10)
                                .concat(item.label.length > 10 ? "..." : "")}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  {showPagesSettinggs &&
                    page &&
                    !location.search.includes("menuqr") && (
                      <div className="col-12" style={{ padding: "0 15px" }}>
                        <p
                          onClick={async () => {
                            setDeleteNewDynamicPagePopup(true);
                          }}
                          className={classNames(
                            styles.selectPageText,
                            styles.newDynamicPageText
                          )}
                        >
                          Eliminar página
                        </p>
                      </div>
                    )}
                  {showPagesSettinggs &&
                    page &&
                    !location.search.includes("menuqr") && (
                      <div className="col-12" style={{ padding: "0 15px" }}>
                        <p
                          onClick={async () => {
                            dispatch(loadingOn());
                            await updateDynamicPage({
                              ...page,
                              activate: !page.activate,
                            });
                            await getDynamicPageData();
                            enqueueSnackbar(
                              Notification({
                                text: "Página actualizada",
                                variant: "success",
                                withDetails: false,
                              })
                            );
                            dispatch(loadingOff());
                          }}
                          className={classNames(
                            styles.selectPageText,
                            styles.newDynamicPageText
                          )}
                        >
                          {!page.activate
                            ? "Activar página"
                            : "Desactivar página"}
                        </p>
                      </div>
                    )}
                  {showPagesSettinggs && (
                    <div className="col-12" style={{ padding: "0 15px" }}>
                      <p
                        onClick={() => setShowNewDynamicPagePopup(true)}
                        className={classNames(
                          styles.selectPageText,
                          styles.newDynamicPageText
                        )}
                      >
                        Crear nueva página
                      </p>
                    </div>
                  )}
                </Fragment>
              )}
              {(location.pathname.includes(webConstants.routes.MODULE_WEB) ||
                location.pathname.includes(
                  webConstants.routes.MODULE_DYNAMIC_PAGE
                )) && (
                <div className="col-12">
                  <p className={styles.noteText}>
                    Aquí podrás editar o eliminar los elementos actuales de tu
                    plantilla.
                  </p>
                </div>
              )}
              {company.UseMultiResolution &&
                (location.pathname.includes(webConstants.routes.MODULE_WEB) ||
                  location.pathname.includes(
                    webConstants.routes.MODULE_DYNAMIC_PAGE
                  )) && (
                  <div className="col-12">
                    <div className={styles.ButtonGroupContainer}>
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="resolutions"
                      >
                        {sizes.map((size) => (
                          <button
                            className={classNames(
                              resolution === size.value
                                ? styles.selectedButton
                                : "",
                              "btn btn-secondary",
                              styles.resolutionButton
                            )}
                            key={size.label}
                            onClick={() => dispatch(setResolution(size.value))}
                          >
                            <p className={styles.resolutionLabel}>
                              <span style={{ marginRight: 2 }}>
                                {size.icon}
                              </span>
                              {size.label}
                            </p>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              {(location.pathname.includes(webConstants.routes.MODULE_WEB) ||
                location.pathname.includes(
                  webConstants.routes.MODULE_DYNAMIC_PAGE
                )) && (
                <div
                  className="col-12"
                  style={{ paddingLeft: 0, paddingRight: 0, marginTop: 5 }}
                >
                  <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragUpdate={onDragUpdate}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          <CollapsibleButtons {...{ showSaveModalIcon }} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
              {(location.pathname.includes(webConstants.routes.MODULE_WEB) ||
                location.pathname.includes(
                  webConstants.routes.MODULE_DYNAMIC_PAGE
                )) && (
                <div className="col-12">
                  <button
                    onClick={() => dispatch(setShowConfigDialog(true))}
                    className={classNames("btn", styles.configMarginButton)}
                  >
                    <p className={styles.configMarginLabel}>
                      Márgenes
                      <span>
                        <img
                          src={ConfigIcon}
                          width={20}
                          style={{ marginLeft: 10 }}
                        />
                      </span>
                    </p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <DynamicPopup
          component={
            <div className="row">
              {newDynamicPageMode === NewPageMode.None && (
                <div className="col-6" style={{ padding: "0px 5px" }}>
                  <div
                    className={classNames(
                      styles.newDynamicPageResolutionButton
                    )}
                    onClick={() => {
                      setNewDynamicPageMode(NewPageMode.NewPage);
                    }}
                  >
                    <p className={styles.newDynamicPageResolutionText}>
                      Crear nueva página
                    </p>
                  </div>
                </div>
              )}
              {newDynamicPageMode === NewPageMode.None && (
                <div className="col-6" style={{ padding: "0px 5px" }}>
                  <div
                    className={classNames(
                      styles.newDynamicPageResolutionButton
                    )}
                    onClick={() => {
                      setNewDynamicPageMode(NewPageMode.NewProductPage);
                    }}
                  >
                    <p className={styles.newDynamicPageResolutionText}>
                      Personalizar página de producto
                    </p>
                  </div>
                </div>
              )}
              {newDynamicPageMode === NewPageMode.NewPage && (
                <div className="col-12">
                  <InputComponent
                    validation={false}
                    placeholder="Escribe el nombre de la personalización"
                    value={newDynamicPageName}
                    handler={(e) => setNewDynamicPageName(e.target.value)}
                    helperText={"Este campo es obligatorio"}
                    style={{ color: "#fff" }}
                    useBlueBackground
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {validatingNewDynamicPageName ? (
                            <CircularProgress
                              style={{
                                width: 25,
                                height: 25,
                                color: "#fff",
                                margin: 5,
                              }}
                            />
                          ) : availableNewDynamicPageName ? (
                            <img src={successIcon} alt="success" width={25} />
                          ) : (
                            <img src={errorIcon} alt="error" width={25} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    width={400}
                  />
                </div>
              )}
              {newDynamicPageMode === NewPageMode.NewProductPage &&
                productPageMode === ProductPageMode.None && (
                  <div className="col-12 text-left">
                    <button
                      onClick={() => setProductPageMode(ProductPageMode.Brand)}
                      className={classNames("btn", "blueEditionSaveButton2")}
                      style={{ margin: "35px 0px 5px 20%", width: "60%" }}
                    >
                      <h6
                        style={{ fontSize: 14, textAlign: "left" }}
                        className={"blueEditionSaveButtonText2"}
                      >
                        Personalizar página por marca
                      </h6>
                    </button>
                  </div>
                )}
              {newDynamicPageMode === NewPageMode.NewProductPage &&
                productPageMode === ProductPageMode.None && (
                  <div className="col-12 text-left">
                    <button
                      onClick={() => setProductPageMode(ProductPageMode.Skus)}
                      className={classNames("btn", "blueEditionSaveButton2")}
                      style={{ margin: "5px 0px 5px 20%", width: "60%" }}
                    >
                      <h6
                        style={{ fontSize: 14, textAlign: "left" }}
                        className={"blueEditionSaveButtonText2"}
                      >
                        Personalizar página por producto
                      </h6>
                    </button>
                  </div>
                )}
              {newDynamicPageMode === NewPageMode.NewProductPage &&
                productPageMode === ProductPageMode.None && (
                  <div className="col-12 text-left">
                    <button
                      onClick={() => {
                        setProductPageMode(ProductPageMode.AllSkus);
                        const found = dynamicPages.find((page) => page.allSkus);
                        if (found) {
                          setAllSkusPage(found);
                          enqueueSnackbar(
                            Notification({
                              text: "Ya existe una página general",
                              variant: "success",
                              withDetails: false,
                            })
                          );
                        } else {
                          setProductPageMode(ProductPageMode.AllSkus);
                          setNewDynamicPageName("Todos los productos");
                        }
                      }}
                      className={classNames("btn", "blueEditionSaveButton2")}
                      style={{ margin: "5px 0px 5px 20%", width: "60%" }}
                    >
                      <h6
                        style={{ fontSize: 14, textAlign: "left" }}
                        className={"blueEditionSaveButtonText2"}
                      >
                        Personalizar general todas las páginas de producto
                      </h6>
                    </button>
                  </div>
                )}
              {newDynamicPageMode === NewPageMode.NewProductPage &&
                productPageMode === ProductPageMode.Brand && (
                  <div className="col-12">
                    <SelectComponent
                      handler={(value) => {
                        setBrand(value);
                        setNewDynamicPageName(value);
                      }}
                      value={brand}
                      array={_orderBy(
                        brands.filter(
                          (brand) =>
                            !dynamicPages.find(
                              (page) => page.brand === brand.Path
                            )
                        ),
                        "Name",
                        "asc"
                      ).map((category) => {
                        return {
                          name: category.Name,
                          value: category.Path,
                        };
                      })}
                      style={{ color: "#fff", textAlign: "left" }}
                      width={400}
                      useBlueBackground
                    />
                  </div>
                )}
              {newDynamicPageMode === NewPageMode.NewProductPage &&
                productPageMode === ProductPageMode.Skus && (
                  <div className="col-12">
                    {" "}
                    <MultiSKUInputComponent
                      {...{
                        classes: styles,
                        skus,
                        handleChange: (value) => {
                          const found = dynamicPages.find(
                            (page) =>
                              page.skus.length > 0 &&
                              page.skus.find((item) => item === value[0])
                          );
                          if (!found) {
                            setSkus(value);
                            if (value && value.length > 0) {
                              const foundProduct = products.find(
                                (p) =>
                                  p.Children.find(
                                    (child) => child.SKU === value[0]
                                  ) || p.Name === value[0]
                              );
                              if (foundProduct) {
                                const children = foundProduct.Children;
                                const foundChild = children.find(
                                  (child) => child.SKU === value[0]
                                );
                                setNewDynamicPageName(
                                  `${
                                    foundChild
                                      ? foundChild.SKU
                                      : foundProduct.Children[0].SKU
                                  } - ${foundProduct.Name}`
                                );
                              } else setNewDynamicPageName(value[0]);
                            }
                          } else {
                            setSkus([]);
                            enqueueSnackbar(
                              Notification({
                                text: "Este producto ya posee una página",
                                variant: "success",
                                withDetails: false,
                              })
                            );
                          }
                        },
                        width: 400,
                        maxQty: 1,
                        company: company,
                        user: user,
                      }}
                    />
                  </div>
                )}
              {newDynamicPageMode === NewPageMode.NewProductPage &&
                productPageMode === ProductPageMode.AllSkus &&
                allSkusPage && (
                  <div className="col-12">
                    <p className={styles.newDynamicPageResolutionText}>
                      Ya existe una página general para todos los productos, si
                      quieres editarla haz click{" "}
                      <span style={{ display: "inline-flex" }}>
                        {" "}
                        <a
                          href={`${Routes.MODULE_WEB.path}?page=${allSkusPage.url}`}
                        >
                          <p>aquí</p>
                        </a>{" "}
                      </span>
                    </p>
                  </div>
                )}
              {newDynamicPageMode === NewPageMode.NewPage && (
                <div className="col-12" style={{ padding: "0px 16%" }}>
                  {" "}
                  <p className="noteText">
                    ** Recuerde escribir un nombre distinto a las páginas,
                    colecciones y categorías actuales **
                  </p>
                </div>
              )}
            </div>
          }
          title={getNewDynamicPageTitle()}
          subtitle={getNewDynamicPageSubTitle()}
          show={showNewDynamicPagePopup}
          handleBack={() => {
            if (newDynamicPageMode === NewPageMode.None)
              setShowNewDynamicPagePopup(false);
            else if (productPageMode !== ProductPageMode.None) {
              setProductPageMode(ProductPageMode.None);
              setNewDynamicPageName("");
            } else setNewDynamicPageMode(NewPageMode.None);
          }}
          handleClose={() => {
            setShowNewDynamicPagePopup(false);
            setNewDynamicPageName("");
            setNewDynamicPageMode("none");
            setProductPageMode(ProductPageMode.None);
            setAllSkus(false);
            setBrand("");
            setSkus([]);
            setAllSkusPage(undefined);
          }}
          saveButtonText={"Aceptar"}
          disableSaveButton={
            newDynamicPageMode === NewPageMode.None ||
            !availableNewDynamicPageName ||
            (newDynamicPageMode === NewPageMode.NewProductPage &&
              brand === "" &&
              skus.length === 0 &&
              !allSkus)
          }
          handleSave={() => handleSaveNewDynamicPage()}
        />
        <WarningDeletePageDialog
          show={showDeleteDynamicPagePopup}
          handleClose={() => setDeleteNewDynamicPagePopup(false)}
          handleClick={async () => {
            dispatch(loadingOn());
            await deleteDynamicPage({ _id: page._id });
            await getDynamicPageData();
            navigate(Routes.MODULE_WEB.path);
            enqueueSnackbar(
              Notification({
                text: "Página eliminada",
                variant: "success",
                withDetails: false,
              })
            );
            setDeleteNewDynamicPagePopup(false);
            dispatch(loadingOff());
          }}
          color="#122526"
        />
        <CreateTemplateComponentDialog
          show={showTemplatePopup}
          handleClose={() => setShowTemplatePopup(false)}
        />
      </Drawer>
    </MuiThemeProvider>
  );
});

const CollapsibleButtons = memo(
  ({ showSaveModalIcon }) => {
    const resolution = useSelector(selectResolution);
    const componentsHome = useSelector(selectComponents(resolution));
    const [data, setData] = useState([]);
    const [availableComponents, setAvailableComponents] = useState([]);

    useEffect(() => {
      async function getData() {
        const customComponents = await getCustomWebComponents().catch((err) => {
          console.log(err);
          return [];
        });
        setAvailableComponents(
          _orderBy(
            [
              ...customComponents,
              ...desktopAvailableComponents,
              ...tabletAvailableComponents,
              ...mobileAvailableComponents,
            ],
            "title",
            "asc"
          )
        );
      }
      getData();
    }, []);

    useEffect(() => {
      if (componentsHome && componentsHome.length > 0) {
        const json = JSON.stringify(data);
        const newData = componentsHome.map((item) => {
          const { inputs, ...others } = item;
          return { ...others };
        });
        const newJson = JSON.stringify(newData);
        if (newJson !== json) {
          setData(newData);
        }
      }
    }, [componentsHome, data]);

    return data.map((item, index) => (
      <Draggable
        key={`draggable-${item._id}`}
        draggableId={`draggable-${item._id}`}
        index={index}
      >
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <CollapsibleButton
                availableComponents={availableComponents}
                item={item}
                provided={provided}
                showSaveModalIcon={showSaveModalIcon}
              />
            </div>
          );
        }}
      </Draggable>
    ));
  },
  (p, n) => p.showSaveModalIcon === n.showSaveModalIcon
);

const CollapsibleButton = memo(
  ({ item, provided, showSaveModalIcon, availableComponents }) => {
    const [showContent, setShowContent] = useState(false);
    const [showRemoveIcon, setShowRemoveIcon] = useState(false);
    const [info, setInfo] = useState();
    const resolution = useSelector(selectResolution);
    const dispatch = useDispatch();
    const handleChangeSubIndex = (index) => dispatch(setWebSubIndex(index));
    const selectedComponent = useSelector(selectSelectedComponent);
    const component = useSelector(getComponent(resolution, item._id));

    useEffect(() => {
      if (item && availableComponents && availableComponents.length > 0) {
        const found = availableComponents
          .filter((item) => item.resolution === resolution)
          .find((it) => {
            const foundResolution = item.component.indexOf(resolution);
            if (it.component === "CustomComponent") return null;
            if (foundResolution === -1) {
              const resolutionComponent = `${item.component
                .split("Component")
                .join("")}${capitalize(resolution)}Component`;

              return (
                it.component === item.component ||
                it.component === resolutionComponent
              );
            }
            return it.component === item.component;
          });
        if (found) setInfo(found);
        else {
          const foundCustomComponent = availableComponents
            .filter((item) => item.resolution === resolution)
            .find((it) => {
              return (
                item.title &&
                item.type &&
                it.title === item.title &&
                it.type === item.type
              );
            });
          const foundHtmlComponent = availableComponents.find(
            (item) =>
              item.resolution === resolution &&
              item.component === "HtmlComponent"
          );
          if (foundCustomComponent) {
            setInfo({
              ...foundCustomComponent,
              informationTree: foundHtmlComponent
                ? foundHtmlComponent.informationTree
                : [],
            });
          }
        }
      }
    }, [item, availableComponents, resolution]);

    const handleRemoveHomeComponent = (index) => {
      dispatch(removeComponent({ index, resolution }));
      setTimeout(() => {
        dispatch(setEditTab(0));
      }, 500);
    };

    resetServerContext();

    if (!item || !component) return <div></div>;
    return (
      <Fragment key={`${item._id}-sidebar-key`}>
        <button
          id={`${item._id}-sidebar`}
          className={styles.componentCollapsible}
          style={{
            paddingLeft: 0,
            display: "flex",
            borderRadius: 2,
            borderBottom:
              selectedComponent &&
              selectedComponent._id &&
              component &&
              component._id &&
              selectedComponent._id === component._id
                ? "2px solid #0f7fff"
                : "none",
            backgroundColor:
              selectedComponent &&
              selectedComponent._id &&
              component &&
              component._id &&
              selectedComponent._id === component._id
                ? "rgb(236, 236, 236)"
                : "#fff",
          }}
          onMouseEnter={() => {
            setShowRemoveIcon(true);
          }}
          onMouseLeave={() => {
            setShowRemoveIcon(false);
          }}
          {...provided.dragHandleProps}
          onClick={() =>
            onClickToElement(item._id, 0, () => null, false, true, false)
          }
        >
          <div className={styles.buttonGridContainer}>
            <div
              onClick={() => {
                setShowContent((prev) => !prev);
                dispatch(setEditTab(1));
                dispatch(setSelectedComponent(item));
                dispatch(setWebIndex(item._id));
              }}
            >
              <h6
                className={styles.titleComponent}
                style={{ margin: "0px 10px", width: "100%" }}
                title={info ? info.title : "Elemento"}
              >
                {" "}
                <span style={{ cursor: "pointer", margin: 5 }}>
                  <img width={10} height={10} src={orderIcon} alt="open" />
                </span>{" "}
                <span>
                  <img
                    src={
                      info
                        ? info.icon
                        : "https://luma-photos.s3.amazonaws.com/photos/icono-banner.svg"
                    }
                    alt={item.component}
                    width={16}
                    height={16}
                    style={{ marginRight: 5 }}
                  />
                </span>{" "}
                {info
                  ? info.title.length > 12
                    ? info.title.substring(0, 9).concat("...")
                    : info.title
                  : "Elemento"}{" "}
              </h6>
            </div>
            <div style={{ justifyContent: "right", margin: "0 auto" }}>
              {showSaveModalIcon &&
                component &&
                component.component === "HtmlComponent" &&
                component.type !== componentType.custom && (
                  <CreateWebComponentDialog component={component} />
                )}
              {showRemoveIcon && component && (
                <span>
                  <WarningDeleteDialog
                    handleClick={() =>
                      handleRemoveHomeComponent(component.index)
                    }
                    color="#122526"
                  />
                </span>
              )}{" "}
              {showRemoveIcon && (
                <DragIndicatorIcon
                  style={{
                    color: "#122526",
                    cursor: "grab",
                    height: 15,
                    width: 15,
                  }}
                  fontSize="small"
                />
              )}
            </div>{" "}
          </div>
        </button>
        <div
          style={{
            display: showContent ? "inherit" : "none",
            maxHeight: 100,
            overflowY: "auto",
          }}
        >
          {info && info.informationTree ? (
            info.informationTree.map((first, index) =>
              first.map((e) => (
                <div
                  className={styles.subcomponent}
                  onClick={() => {
                    onClickToElement(
                      `${e.onClickId}-${item._id}-${index}`,
                      index,
                      handleChangeSubIndex,
                      false,
                      false,
                      true
                    );
                  }}
                >
                  <h6
                    className={styles.titleSubcomponent}
                    style={{ margin: "0px 0px 0px 45px" }}
                    title={e.title}
                  >
                    <span>
                      <img
                        src={e ? e.icon : "/"}
                        alt={e.title}
                        width={15}
                        height={15}
                      />
                    </span>{" "}
                    {e.title}
                  </h6>
                </div>
              ))
            )
          ) : (
            <div className={styles.subcomponent}>
              <h6
                className={styles.titleSubcomponent}
                style={{ margin: "0px 0px 0px 25px" }}
              >
                Sub Elemento
              </h6>
            </div>
          )}
        </div>
      </Fragment>
    );
  },
  (p, n) => {
    return p.item === n.item && p.provided === n.provided;
  }
);

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#fff",
      },
    },
  },
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  height: "auto",
  userSelect: "none",
  padding: isDragging ? 0 : 1,
  margin: 1,
  // change background colour if dragging
  background: "transparent",
  // styles we need to apply on draggables
  border: isDragging ? "1px solid #0f7fff" : "none",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: "transparent",
  padding: 0,
  width: "auto",
});
export default ModuleWebSideBar;
