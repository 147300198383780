import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const Page = lazy (() => import ('../components/services/service'));
const ViewServicePage = lazy (() =>
  import ('../components/services/viewService.component')
);
const ServiceUsersComponent = lazy (() =>
  import ('../components/services/users/users.component')
);

export function ServicesRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export function ViewServicesRoute({path}) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ViewServicePage />
    </Suspense>
  );
}

export function ServiceUsersRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ServiceUsersComponent />
    </Suspense>
  );
}
