const userConstants = {
  ROLES: {
    ADMIN: "admin",
    USER: "user",
    PICKER: "picker",
    MANAGER: "manager",
  },
  PRODUCT_TAB: {
    component: "ProductsTabComponent",
    idCompany: "",
    index: 0,
    inputs: [
      {
        Categories: [],
        Category: "",
      },
    ],
  },
  WIDGET_TAB: {
    component: "ProductWidgetComponent",
    idCompany: "",
    index: 0,
    inputs: [
      {
        Categories: [],
      },
    ],
  },
  SHOPLIST_ITEM: {
    Button: "",
    Img: "",
    Path: "",
    SubTitle: "",
    Title: "",
  },
  SHOPLIST_TAB: {
    component: "ShopListComponent",
    idCompany: "",
    index: 0,
    inputs: [
      {
        ShopList: [],
      },
    ],
  },
  BILLBOARD_ITEM: {
    Desktop: "https://dgdvd9d8e5bk8.cloudfront.net/photos/viveres-02.png",
    Mobile: "",
  },
  BILLBOARD_TAB: {
    component: "BillboardComponent",
    idCompany: "",
    index: 0,
    inputs: [
      {
        BillBoard: {
          Left: {},
          Right: {},
        },
      },
    ],
  },
};

export default userConstants;
