import React, { useEffect } from 'react';
import styles from './styles.module.css';


export const SelectReason = ({ id }) => {

    useEffect(() => {

    }, [])
    const handleChange = (e) => {
        // console.log(e.target.value)
    }

    return (
        <select id={id} onChange={(e) => handleChange(e)} className={styles.rejectReasonSelect}>
            <option value="" disabled>Selecciona la razón</option>
            <option value="sin-stock">Sin stock</option>
            <option value="kitchen-close">Cocina cerrada</option>
            <option value="kitchen-busy">Cocina muy ocupada</option>
        </select>
    )
}
// export default SelectReason