import config from "../config";
import { get, post } from "../http";

const service = {
  createChangeRecord,
  getChangeRecord,
  getChangeRecordByIdCompany,
  getModuleChangesByIdChangeRecord,
  getRecordsStatus,
};

async function createChangeRecord(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = { ...data };
  return await post(
    `${config.recordsMicroserviceUrlApi}/records/create`,
    headers,
    body
  );
}

async function getChangeRecord(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.recordsMicroserviceUrlApi}/records/get?id=${id}`,
    headers
  );
}

async function getChangeRecordByIdCompany(
  {
    idCompany,
    limit,
    page,
    order,
    orderBy,
    searchText,
    searchField,
    module,
    submodule,
  },
  token
) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  var query = "";
  if (idCompany && idCompany !== "") query = `${query}&id=${idCompany}`;
  if (limit !== undefined && limit !== "") query = `${query}&limit=${limit}`;
  if (page !== undefined && page !== "") query = `${query}&page=${page}`;
  if (order && order !== "") query = `${query}&order=${order}`;
  if (orderBy && orderBy !== "") query = `${query}&orderBy=${orderBy}`;
  if (searchText && searchText !== "")
    query = `${query}&searchText=${searchText}`;
  if (searchField && searchField !== "")
    query = `${query}&searchField=${searchField}`;
  if (module && module !== "") query = `${query}&module=${module}`;
  if (submodule && submodule !== "") query = `${query}&submodule=${submodule}`;

  return await get(
    `${
      config.recordsMicroserviceUrlApi
    }/records/getChangeRecordsByIdCompany?${query.substr(1)}`,
    headers
  );
}

async function getModuleChangesByIdChangeRecord(idRecord, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.recordsMicroserviceUrlApi}/records/getModuleChangesByIdChangeRecord?id=${idRecord}`,
    headers
  );
}

async function getRecordsStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.recordsMicroserviceUrlApi}/system/status`,
    headers
  );
}

export default service;
