import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';
import service from '../utils/services/services';
import orderService from '../utils/services/order';

export const serviceSlice = createSlice({
  name: 'services',
  initialState: initialState.services,
  reducers: {
    receiveServices: (state, action) => {
      state.splice(0, state.length);
      state.push(...action.payload);
    },
  },
});

export const {receiveServices} = serviceSlice.actions;

export const update = async (data, token) => await service.update(data, token);

export const create = async (data, token) => await service.create(data, token);

export const remove = async (data, token) => await service.remove(data, token);

export const getAllServicesCheckout = async (idCompany, token, dispatch) =>
  await service.getByIdCompany(idCompany, token).then((res) => {
    dispatch(receiveServices(res));
    return res;
  });

export const getById = async (id, token) => await service.getById(id, token);
export const getAll = async (id, token, dispatch) => {
  const result = await service.getByIdCompany(id, token);
  dispatch(receiveServices(result));
  return result;
};

export const getServiceCheckoutById = async (id, token) =>
  await orderService.getServiceCheckoutById(id, token);

export const getServiceCheckoutByIdCheckout = async (id, token) =>
  await orderService.getServiceCheckoutByIdCheckout(id, token);

export const getCheckoutServicesByIdCompany = async (id, token) =>
  await orderService.getCheckoutServicesByIdCompany(id, token);

export const selectServices = (state) => state.services;

export default serviceSlice.reducer;
