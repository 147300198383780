import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const CouponMarketingPage = lazy(() =>
  import("../components/marketing/coupon/coupon")
);

const ViewCouponPage = lazy(() =>
  import("../components/marketing/coupon/view/viewCoupon.component")
);

export function CouponMarketingRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <CouponMarketingPage />
    </Suspense>
  );
}

export function ViewCouponRoute({ mode = "update" }) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ViewCouponPage mode={mode} />
    </Suspense>
  );
}
