/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

//MATERIAL
import {
  withStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// CSS

import cssStyles from "./cabify.module.css";

//IMPORTS
import { Notification } from "../../../containers/notification";
import { selectUser } from "../../../reducers/userSlice";
import {
  loadingOff,
  loadingOn,
  selectCompanyId,
} from "../../../reducers/uiSlice";
import {
  createShipping,
  getAllShipping,
  removeShipping,
  selectShipping,
  updateShipping,
} from "../../../reducers/shippingSlice";
import shippingConstants from "../../../constants/shipping.constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { selectCompany } from "../../../reducers/companySlice";
import InputComponent, { SelectComponent } from "../../inputs";
import jwt from "jsonwebtoken";
import config from "../../../utils/config";
import { Checkbox, Tooltip } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

function CabifyComponent() {
  const companyId = useSelector(selectCompanyId);
  const shippingPolicies = useSelector(selectShipping);
  const [active, setActive] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const price = 3490;
  const price2 = 3590;
  const price3 = 4000;
  const [id, setId] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [deliveryTime, setDeliveryTime] = useState(30);
  const [requested, setRequested] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [financing, setFinancing] = useState(false);
  const [financingType, setFinancingType] = useState("percentage");
  const [financingValue, setFinancingValue] = useState(0);
  const [description, setDescription] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user &&
      user.token &&
      user.token !== "" &&
      !requested &&
      company &&
      company._id !== ""
    ) {
      getAllShipping(company._id, user.token, dispatch);
      setRequested(true);
    }
  }, [company, user]);

  useEffect(() => {
    if (shippingPolicies.length > 0) {
      const shipping = shippingPolicies.find(
        (item) => item.useFulfillment && item.fulfillmentId === "Cabify"
      );
      // console.log(shipping);
      if (shipping) {
        setClientID(jwt.verify(shipping.extraInfo.clientID, config.jwtSecret));
        setClientSecret(
          jwt.verify(shipping.extraInfo.clientSecret, config.jwtSecret)
        );
        setDeliveryTime(shipping.extraInfo.deliveryTime);
        setId(shipping._id);
        setActive(shipping.status);
        setIsTest(!!shipping.isTest);
        setFinancing(shipping.financing);
        setFinancingType(shipping.financingType);
        setFinancingValue(shipping.financingValue);
        setDescription(shipping.description);
      } else {
        setActive(false);
        setId("");
      }
    }
  }, [shippingPolicies]);

  const handleSave = (accepted) => {
    handleShippingSave(accepted);
    // setActive(accepted);
  };

  const handleShippingSave = (accepted) => {
    dispatch(loadingOn());
    // console.log(id, accepted);
    if (id === "" && accepted) {
      createShipping(
        {
          name: "Cabify",
          type: shippingConstants.TYPES.today.code,
          status: true,
          pickupTimeTable: [],
          useTimeWindow: false,
          useLocationVerification: false,
          shippingTimeTable: [],
          maxDistance: 0,
          price,
          price2,
          price3,
          useFulfillment: true,
          fulfillmentId: "Cabify",
          idCompany: companyId,
          isTest,
          extraInfo: {
            clientID: jwt.sign(clientID, config.jwtSecret),
            clientSecret: jwt.sign(clientSecret, config.jwtSecret),
            deliveryTime,
          },
          financing,
          financingType,
          financingValue,
          description,
        },
        user.token
      )
        .then(() => {
          enqueueSnackbar(
            Notification({
              text: "Creado con exito!",
              variant: "success",
              withDetails: false,
            })
          );
          // setUpdated(false);
          dispatch(loadingOff());
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(
            Notification({
              text: "Ocurrio un error al intentar crear.",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
          // createChangeRecord(
          //   {
          //     user: user,
          //     username: user.username,
          //     fullName: `${user.firstName} ${user.lastName}`,
          //     idUser: user.id,
          //     idCompany: companyId,
          //     idObject: shipping._id,
          //     module: "settings",
          //     submodule: "shipping",
          //     createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
          //     changes: [
          //       {
          //         text: "Creado nuevo política de envío",
          //         oldValue: null,
          //         newValue: name,
          //         date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
          //       },
          //     ].concat(changes),
          //   },
          //   user.token,
          //   dispatch
          // ).catch((err) => console.log(err));
        });
    } else if (id !== "" && !accepted) {
      removeShipping(id, user.token, dispatch)
        .then((res) => {
          enqueueSnackbar(
            Notification({
              text: "Eliminado con exito",
              variant: "success",
              withDetails: false,
            })
          );
          // createChangeRecord(
          //   {
          //     user: user,
          //     username: user.username,
          //     fullName: `${user.firstName} ${user.lastName}`,
          //     idUser: user.id,
          //     idCompany: company._id,
          //     idObject: deleteItemId,
          //     module: "settings",
          //     submodule: "shipping",
          //     createdDate: dateUtils
          //       .getUTC()
          //       .slice(0, 19)
          //       .replace("T", " "),
          //     changes: [
          //       {
          //         text: "Método de envío eliminado",
          //         oldValue: deleteItemId,
          //         newValue: null,
          //         date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
          //       },
          //     ],
          //   },
          //   user.token,
          //   dispatch
          // ).catch((err) => console.log(err));
          dispatch(loadingOff());
          setId("");
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al elminar",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    }
  };

  const handleShippingUpdate = (active) => {
    dispatch(loadingOn());
    updateShipping(
      {
        _id: id,
        isTest,
        status: active,
        extraInfo: {
          clientID: jwt.sign(clientID, config.jwtSecret),
          clientSecret: jwt.sign(clientSecret, config.jwtSecret),
          deliveryTime,
        },
        financing,
        financingType,
        financingValue,
        description,
      },
      user.token
    )
      .then((res) => {
        getAllShipping(company._id, user.token, dispatch);
        enqueueSnackbar(
          Notification({
            text: "Actualizado con exito!",
            variant: "success",
            withDetails: false,
          })
        );
        // setUpdated(false);
        dispatch(loadingOff());
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          Notification({
            text: "Ocurrio un error al intentar actualizar.",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={cssStyles.parentContainer}>
        <button className={cssStyles.backButton} onClick={() => handleClose()}>
          <span>
            <ArrowBackIcon />
          </span>
          Regresar
        </button>
        <div className={cssStyles.infoContainer}>
          <div className={cssStyles.titleLogoContainer}>
            <div className={cssStyles.containerLogo}>
              <img
                src="https://s3.amazonaws.com/haya.lumarketo.cl/Cabify-Logo-Moradul-RGB.png"
                alt="Cabify"
                width="auto"
                height={50}
              />
            </div>
            <div className={cssStyles.serviceStatusContainer}>
              {!active ? (
                <div className={cssStyles.containerLogo}>
                  {" "}
                  <img
                    className="stateOffMargin"
                    src="https://s3.amazonaws.com/lumarketo.cl/clock-steps-home.svg"
                    alt=""
                  />
                  Inactivo{" "}
                </div>
              ) : (
                <div className={cssStyles.containerLogo}>
                  {" "}
                  <img
                    src="https://s3.amazonaws.com/lumarketo.cl/check-steps-home.svg"
                    alt=""
                  />{" "}
                  Activo{" "}
                </div>
              )}
            </div>
          </div>
          <div className={cssStyles.infoItemContainer}>
            <p className={cssStyles.infoItemTitle}>
              Cabify te ofrece las siguientes servicios{" "}
            </p>
            <p className={cssStyles.infoItem}>
              La activación del servicio de delivery de Cabify toma alrededor de
              20 minutos
            </p>

            <p className={cssStyles.infoItem}>
              Tarifica los envíos según dimensiones y coberturas.
            </p>
          </div>

          <button
            className={cssStyles.buttonConfig}
            onClick={() => {
              setShowInputs((p) => !p);
            }}
          >
            Configurar
            <span>{!showInputs ? <ExpandMoreIcon /> : <ExpandLessIcon />}</span>
          </button>

          {showInputs && (
            <div className={cssStyles.configSettingsContainer}>
              <p className={cssStyles.configSettingsTitle}>Credenciales</p>
              <p className={cssStyles.configSettingsText}>
                Si usted desea hacer uso de la flota de Cabify, debe obtener las
                credenciales en su{" "}
                <a
                  href="https://cabify.com/auth/authorizations/new?client_id=d627522c67f14504b42ffab52301d234&redirect_uri=https%3A%2F%2Fcabify.com%2Fapp%2Fauth%2Fcallback&response_type=code"
                  rel="noreferrer"
                  target="_blank"
                >
                  página
                </a>{" "}
                y darnos acceso a ellas para automatizar sus procesos.
              </p>

              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>ClientID</label>

                <InputComponent
                  placeholder="Ejemplo: 123456789"
                  type="text"
                  helperText={
                    clientID === ""
                      ? "Recuerde indicar el precio del envío"
                      : ""
                  }
                  value={clientID}
                  handler={(e) => {
                    setClientID(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>ClientSecret</label>

                <InputComponent
                  placeholder="Ejemplo: 123456789"
                  type="text"
                  helperText={
                    clientSecret === ""
                      ? "Recuerde indicar el precio del envío"
                      : ""
                  }
                  value={clientSecret}
                  handler={(e) => {
                    setClientSecret(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>Descripción</label>

                <InputComponent
                  disabled={!active}
                  placeholder="Ejemplo: Envío a domicilio en 24hrs"
                  type="text"
                  helperText={
                    description === "" ? "Recuerde indicar la descripción" : ""
                  }
                  value={description}
                  handler={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <LightTooltip
                  title="Tiempo máximo en el que se esperará un driver antes de cancelar el envío y pedir uno nuevo"
                  arrow
                >
                  <label className={cssStyles.labelInput}>
                    Tiempo de espera
                  </label>
                </LightTooltip>

                <InputComponent
                  placeholder="Ejemplo: 30 min"
                  type="number"
                  value={deliveryTime}
                  handler={(e) => {
                    setDeliveryTime(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Activar modo prueba
                </label>

                <Checkbox
                  checked={isTest}
                  onChange={(e) => setIsTest(e.target.checked)}
                  value={"isTest"}
                  style={{ color: "#122526" }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Activar financiamiento
                </label>

                <Checkbox
                  checked={financing}
                  onChange={(e) => setFinancing(e.target.checked)}
                  value={"isTest"}
                  style={{ color: "#122526" }}
                />
              </div>
              {financing && (
                <div className={cssStyles.inputContainer}>
                  <label
                    className="inputTitle"
                    style={{ margin: 0, lineHeight: 3, fontWeight: 600 }}
                  >
                    Tipo del financiamiento
                  </label>
                  <SelectComponent
                    value={financingType}
                    handler={(value) => {
                      setFinancingType(value);
                    }}
                    array={[
                      {
                        name: "Por porcentaje",
                        value: "percentage",
                      },
                      {
                        name: "Por monto",
                        value: "amount",
                      },
                    ]}
                  />
                </div>
              )}

              {financing && (
                <div className={cssStyles.inputContainer}>
                  <label
                    className="inputTitle"
                    style={{ margin: 0, lineHeight: 3, fontWeight: 600 }}
                  >
                    Valor del financiamiento
                  </label>
                  <InputComponent
                    placeholder="Ejemplo: 12"
                    type="text"
                    value={financingValue}
                    handler={(e) => {
                      setFinancingValue(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {showInputs && (
            <div className={cssStyles.buttonsContainer}>
              {active && (
                <button
                  className={cssStyles.confirmButton}
                  onClick={() => handleShippingUpdate(active)}
                >
                  Actualizar
                </button>
              )}
              {id !== "" && (
                <button
                  className={cssStyles.cancelButton}
                  onClick={() => handleShippingUpdate(!active)}
                >
                  {active ? "Inactivar" : "Activar"}
                </button>
              )}
              {id === "" && price !== 0 && price2 !== 0 && price3 !== 0 && (
                <button
                  className={cssStyles.confirmButton}
                  onClick={() => handleSave(true)}
                >
                  Aceptar
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </MuiThemeProvider>
  );
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: "5%",
    [theme.breakpoints.up("xs")]: {
      marginTop: "15%",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "1%",
    },
  },
  centerTitle: {
    textAlign: "center",
  },
  componentDescription: {
    padding: "0 5px",
  },
  componentContainerFab: {
    display: "flex",
    position: "absolute",
    top: "90%",
  },
  componentHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  componentSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textField: {
    width: "100%",
    margin: "5px 0 5px 0px",
  },
  bankTextField: {
    width: "100%",
  },
  unknowBankTextField: {
    width: "86%",
  },
  componentAddItem: {
    display: "flex",
    justifyContent: "center",
  },
  bankCard: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    height: 360,
    width: 450,
    marginBottom: 10,
  },
  cover: {
    width: 200,
    backgroundRepeat: "round",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: 250,
  },
  content: {
    flex: "1 0 auto",
  },
  deleteIcon: {
    position: "absolute",
    left: "88%",
    top: "-2%",
  },
  subtitleContainer: {
    margin: "auto 0px",
  },
});

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto, sans-serif !important",
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiFormGroup: {
      root: {
        flexDirection: "column !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: "-5%",
        marginLeft: "5%",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#122526 !important",
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottom: "2px solid #122526",
        },
        "&:after": {
          borderBottom: "2px solid #122526",
        },
        "&:before": {
          // borderBottom: '2px solid #122526'
        },
      },
    },
    MuiFormControl: {
      root: {
        width: 100,
        margin: "0px 5px",
      },
    },
    MuiCardHeader: {
      root: {
        background: "#122526",
      },
      title: {
        color: "white",
      },
    },
  },
});

export default withStyles(styles)(CabifyComponent);
