import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';
const Page = lazy (() => import ('../containers/login'));

function LoginRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page goHome={true} showPwdInput={true} />
    </Suspense>
  );
}

export default LoginRoute;
