import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/discount";

export const discountSlice = createSlice({
  name: "discount",
  initialState: initialState.discount,
  reducers: {
    receiveDiscount: (state, action) => {
      state.splice(0, state.length);
      state.push(...action.payload);
    },
  },
});

export const { receiveDiscount } = discountSlice.actions;

export const update = async (data, token) => await service.update(data, token);

export const create = async (data, token) => await service.create(data, token);

export const remove = async (data, token) =>
  await service.DeleteDiscount(data, token);

export const getAllDiscount = async (idCompany, token, dispatch) =>
  await service.getByIdCompany(idCompany, token).then((res) => {
    dispatch(receiveDiscount(res));
    return res;
  });

export const getById = async (id, token) => await service.getById(id, token);

export const selectDiscounts = (state) => state.discount;

export default discountSlice.reducer;
