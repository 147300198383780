const imgPath = `${process.env.PUBLIC_URL}/img/`;

const paymentConstants = {
  BS_BANKS_IMG: {
    BBVA: { title: "Provincial", img: `${imgPath}bbva.jpg` },
    BOD: { title: "Banco occidental de descuento", img: `${imgPath}bod.jpg` },
    BANESCO: { title: "Banesco", img: `${imgPath}banesco.png` },
    BDV: { title: "Banesco", img: `${imgPath}BDV.jpg` },
    MERCANTIL: { title: "Mercantil", img: `${imgPath}mercantil.jpg` },
  },
  BS_BANKS: [
    { name: "Banco de Venezuela", code: "BDV" },
    { name: "Provincial", code: "BBVA" },
    { name: "Banco occidental de descuento", code: "BOD" },
    { name: "Mercantil", code: "MERCANTIL" },
    { name: "Banesco", code: "BANESCO" },
  ],
  TYPES: {
    Getnet: "getnet",
    Pesos: "pesos",
    Bolivar: "bolivar",
    Zelle: "zelle",
    Paypal: "paypal",
    None: "none",
    Flow: "flow",
    Mercadopago: "mercadopago",
    Cash: "cash",
    TDD: "tdd",
    TDC: "tdc",
    WireTransfer: "wireTransfer",
  },
  TYPES_NAMES: {
    getnet: "Getnet",
    pesos: "Pesos",
    bolivar: "Bolivares",
    zelle: "Zelle",
    paypal: "Paypal",
    none: "None",
    flow: "Flow.cl",
    mercadopago: "Mercadopago",
    cash: "Efectivo",
    tdd: "Tarjeta de Débito",
    tdc: "Tarjeta de Crédito",
    wireTransfer: "Transferencia bancaria",
  },
  PAYMENT_TYPES: {
    total: "total",
    partial: "partial",
  },
  PAYMENT_TYPES_NAMES: {
    total: "Pago total",
    partial: "Pago parcial",
  },
  REFUND_STATUS: {
    created: "Solicitud creada",
    accepted: "Reembolso aceptado",
    rejected: "Reembolso rechazado",
    refunded: "Reembolso reembolsado",
    canceled: "Reembolso cancelado",
  },
  ORDER_STATUS: {
    1: "pendiente de pago",
    2: "pagada",
    3: "rechazada",
    4: "anulada",
  },
  MERCADOPAGO_STATUS: {
    pending: "El usuario no completó el proceso de pago todavía.",
    approved: "El pago fue aprobado y acreditado.",
    authorized: "El pago fue autorizado pero no capturado todavía.",
    in_process: "El pago está en revisión.",
    in_mediation: "El usuario inició una disputa.",
    rejected: "El pago fue rechazado. El usuario podría reintentar el pago.",
    cancelled: "El pago fue cancelado por una de las partes o el pago expiró.",
    refunded: "El pago fue devuelto al usuario.",
    charged_back:
      "Se ha realizado un contracargo en la tarjeta de crédito del comprador.",
  },
  GETNET_STATUS: {
    OK: "Petición de autenticación procesada correctamente.",
    FAILED: "Fallo en una petición de autenticación.",
    APPROVED: "Petición de pago o suscripción terminada.",
    REJECTED: "Se ha declinado la transacción.",
    PENDING:
      "Transacción pendiente para la sesión, debe estar bloqueada hasta la resolución.",
    REFUNDED:
      "Reintegro de una transacción por solicitud de un tarjetahabiente al comercio.",
  },
  companyPaymentTypeConstants: {
    monthly: {
      code: "monthly",
      label: "Pago mensual",
    },
    sales: {
      code: "sales",
      label: "Porcentaje de ventas",
    },
  },
  companyPaymentType: {
    monthly: "Pago mensual",
    sales: "Porcentaje de ventas",
  },
  companyPaymentStatus: {
    pending: "En espera de fecha de cobro",
    approved: "Aprobado",
    rejected: "Rechazado",
    "payment-pending": "Esperando confirmación de pago",
  },
};

export default paymentConstants;
