import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const Page = lazy(() => import("../containers/qrsurvey"));

function QrSurveyRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default QrSurveyRoute;
