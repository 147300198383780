import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MATERIAL */
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PopupState, {
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/index";
import classNames from "classnames";

/* ACTIONS */
import { logout, selectUser } from "../../reducers/userSlice";

/* STYLES */
import "../../components/users/userHeaders.css";
import styles from "./userMenu.module.css";

//IMPORTS
import routes from "../../constants/routes";

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Nunito Sans",
  },
  overrides: {
    MuiBadge: {
      badge: {
        color: "#fff",
        backgroundColor: "#122526",
      },
    },
    MuiList: {
      root: {
        maxHeight: 200,
        overflowY: "auto",
      },
    },
    MuiAvatar: {
      root: {
        width: 30,
        height: 30,
        fontSize: "0.7rem",
      },
    },

    MuiPaper: {
      root: {
        width: 250,
      },
    },
  },
});

theme.overrides.MuiIconButton = {
  root: {
    [theme.breakpoints.up("xs")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 10,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: 32,
    },
  },
};

// const styles = theme => ({
//   typography: {
//     padding: theme.spacing (2),
//   },
//   badges: {
//     color: '#fff',
//     backgroundColor: '#122526',
//   },
//   leftButton: {
//     borderRadius: '0px 0px 0px 4px',
//   },
//   rightButton: {
//     borderRadius: '0px 0px 4px 0px',
//   },
//   list: {
//     height: 'auto',
//   },
//   badgeRight: {
//     top: '0%',
//     right: '-130%',
//   },
//   badgeLeft: {
//     top: '0%',
//     right: '250%',
//   },
// });

function UserHeader(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const handlePopoverOpen = () => setOpen(true);

  const handlePopoverClose = () => setOpen(false);

  const handleLogout = () => {
    logout(dispatch).then(() => navigate(routes.LOGIN.path));
  };

  return (
    <MuiThemeProvider theme={theme}>
      <PopupState variant="popper" popupId="cart" style={{ top: "12%" }}>
        {(popupState) => (
          <div
            id="header-btn"
            onClick={user && user.firstName ? () => handlePopoverOpen() : null}
          >
            {user && user.firstName && (
              <IconButton
                variant="contained"
                color="inherit"
                {...bindToggle(popupState)}
              >
                <Avatar className={styles.avatar}>
                  {user.firstName.substring(0, 1)}
                  {user.lastName.substring(0, 1)}
                </Avatar>
              </IconButton>
            )}
            <Popper
              {...bindPopper(popupState)}
              transition
              open={open}
              onMouseLeave={handlePopoverClose}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper
                    className={classNames(
                      styles.paper,
                      !user ? styles.paperWithoutUser : ""
                    )}
                    elevation={0}
                  >
                    {user && (
                      <div className="row">
                        <div className="col-3">
                          <Avatar user={user} />
                        </div>
                        <div className="col-9">
                          <Typography display="inline" className={styles.name}>
                            {user ? `${user.firstName} ${user.lastName}` : ""}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {user && (
                      <div className="row">
                        <div className="col-12">
                          <button
                            className={classNames(
                              styles.button,
                              "btn btn-dark"
                            )}
                            onClick={() => handleLogout()}
                          >
                            <Typography
                              display="inline"
                              className={styles.textButton}
                            >
                              Cerrar sesión
                            </Typography>
                          </button>
                        </div>
                      </div>
                    )}

                    {!user && (
                      <div className="row">
                        <div className="col-12">
                          <button
                            className={classNames(
                              styles.button,
                              "btn btn-dark"
                            )}
                            onClick={() => navigate(routes.LOGIN.path)}
                          >
                            <Typography
                              display="inline"
                              className={styles.textButton}
                            >
                              Iniciar sesión
                            </Typography>
                          </button>
                        </div>
                      </div>
                    )}
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        )}
      </PopupState>
    </MuiThemeProvider>
  );
}

export default UserHeader;
