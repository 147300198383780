const shippingConstants = {
  TYPES: {
    normal: {
      name: "Normal",
      code: "normal",
      businessDays: "De 1 a 5 dias hábiles",
    },
    today: {
      name: "Hoy",
      code: "today",
      businessDays: "Envío en 24h",
    },
    tomorrow: {
      name: "Mañana",
      code: "tomorrow",
      businessDays: "Envío de 1 a 2 días hábiles",
    },
  },
  FULFILLMENT_IDS: {
    TiendaHouse: {
      name: "TiendaHouse",
      code: "TiendaHouse",
    },
  },
  PedidosyaStatus: {
    REJECTED:
      "Pedido de envío solicitado pero rechazado debido a datos no válidos.",
    CONFIRMED: "Orden de envío confirmada y en espera.",
    IN_PROGRESS: "Se ha asignado el transporte.",
    NEAR_PICKUP: "El transporte está cerca del punto de recogida.",
    PICKED_UP: "Transporte recogió los artículos del pedido.",
    NEAR_DROPOFF: "El transporte está más cerca del punto de entrega.",
    COMPLETED: "Transporte ha entregado los artículos.",
    CANCELLED: "Pedido de envío cancelado por cualquier motivo.",
  },
  PedidosyaCancelCode: {
    ADDRESS_DATA_MISSING: "Rider no encuentra el pickup/dropoff",
    NO_RIDER_AVAILABLE: "No hay cadete disponible en este momento",
    OUT_OF_DELIVERY_ZONE: "Fuera de área de cobertura del servicio",
    DELAYED_DELIVERY_SCHEDULE:
      "Cancelado debido a horario de entrega retrasado",
    COORDINATE_ERROR: "Coordenadas no concuerdan con la dirección ingresada",
    PACKAGE_DAMAGE_LOOSE: "Se produjo un problema con el producto o paquete",
    ORDER_NOT_DELIVERED: "Pedido no entregado",
    INAPPROPRIATE_CONDUCT: "Cancelado por problemas con el rider",
    UNREACHABLE_RIDER: "Cancelado por problemas con el rider",
    TYC_PACKAGE_CONTRADICTION:
      "Pedido incorrecto. Paquete o producto no respeta TyC.",
    PURCHASE_REQUESTED: "Pedido realizado por error",
    USER_CANNOT_PAY:
      "Solicitud de envío pendiente de pago. El usuario no puede pagar el pedido.",
    COUPON_NOT_APPLIED: "No fue posible aplicar el cupón.",
    DUPLICATED_ORDER: "Pedido duplicado",
    UNREACHABLE_USER_DROPOFF:
      "No es posible contactar al cliente en Punto de Entrega",
    SUSPICIOUS_CLIENT: "Pedido incorrecto.",
    USER_CANCELLED: "Cancelado a solicitud del usuario",
    TECHNICAL_PROBLEM: "Cancelado por problemas técnicos",
    BAD_WEATHER: "Condiciones climáticas adversas",
    UNREACHABLE_USER_PICKUP:
      "No es posible contactar al cliente en Punto de Retiro",
    CONTENT_WRONG: "Producto despachado no es correcto.",
    ORDER_MODIFICATION: "No es posible modificar punto de origen o destino",
    OUT_OF_FLEET_TIME: "Fuera de horario de servicio",
    TEST_ORDER: "Orden de prueba - TEST",
    CONTENT_WRONG_RIDER: "Producto despachado no es correcto",
  },
  CabifyStatusParcel: {
    ready: "La parcela ha sido creada.",
    qualifiedforpickup: "En busqueda de un conductor para iniciar la entrega.",
    onroutetopickup:
      "La entrega ha comenzado y un conductor se dirige al punto de recogida.",
    pickingup:
      "El conductor ha llegado al punto de recogida y está listo para recoger el paquete.",
    intransit: "El paquete está en el vehículo.",
    delivering:
      "El conductor ha llegado al punto de entrega y está listo para la entrega.",
    delivered: "El paquete ha sido entregado con éxito.",
    returning:
      "No se encontró el destinatario y el paquete está regresando al punto de recogida.",
    returned:
      "No se encontró el destinatario y el paquete se devolvió con éxito",
    incident: "Hubo un incidente durante el proceso de devolución.",
    requestercancel: "El solicitante ha cancelado la entrega.",
    internalcancel: "La entrega ha sido cancelada.",
    pickupfailed: "No fue posible recoger el paquete.",
  },
  CabifyfailureReason: {
    unknown: "Error desconocido",
    payment_method_declined: "Método de pago cancelado",
    no_payment_methods: "Sin métodos de pago",
    requester_not_found_or_unauthorized: "Solicitud de envío no autorizada",
    product_does_not_exist: "El producto no existe",
    invalid_pick_up_location: "Dirección de retiro inválida",
    delivery_already_exist: "El envío ya existe",
  },
  SHIPPING_TIME: {
    Inmediato: 0,
    "10min": 10,
    "15min": 15,
    "20min": 20,
    "25min": 25,
    "30min": 30,
    "45min": 45,
    "60min": 60,
  },
  UberStatus: {
    pending: "Envío solicitado y en espera de asignación de conductor",
    pickup: "El conductor está en camino a recoger el envío",
    pickup_complete: "El conductor ha recogido el envío",
    dropoff: "El conductor está en camino a entregar el envío",
    delivered: "El envío ha sido entregado",
    canceled: "El envío ha sido cancelado",
    returned: "El envío ha sido devuelto",
  },
};

export default shippingConstants;
