import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/subsidiary";

export const subsidiarySlice = createSlice({
  name: "subsidiaries",
  initialState: initialState.subsidiaries,
  reducers: {
    receive: (state, action) => {
      const newList = [];
      state.splice(0, state.length);
      if (action.payload && action.payload.length > 0)
        newList.push(...action.payload);
      state.push(...newList);
    },
  },
});

export const { receive } = subsidiarySlice.actions;

export const getAll = async (CompanyId, token, dispatch) =>
  await service.getByIdCompany(CompanyId, token).then((res) => {
    dispatch(receive(res ?? []));
    return res;
  });

export const selectSubsidiaries = (state) => {
  return state?.subsidiaries ?? [];
};

export default subsidiarySlice.reducer;
