import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/order";

export const orderSlice = createSlice({
  name: "orders",
  initialState: initialState.orders,
  reducers: {
    receiveOrders: (state, action) => {
      const newList = [];
      if (action.payload && action.payload.length > 0)
        newList.push(...action.payload);
      state.didntReviewed = getDidntReviewed(newList);
      state.list.splice(0, state.list.length);
      state.list.push(...newList);
      state.view.splice(0, state.view.length);
      state.view = newList.slice(0, 6);
    },
    update: (state, action) => {
      const newList = [];
      newList.push(...state.list);
      const foundIndex = newList.findIndex(
        (order) => order.id === action.payload.id
      );
      if (foundIndex !== -1) {
        newList[foundIndex] = { ...newList[foundIndex], ...action.payload };
        state.didntReviewed = getDidntReviewed(newList);
        state.list.splice(0, state.list.length);
        state.list.push(...newList);
        state.view.splice(0, state.view.length);
        state.view = newList.slice(0, 6);
      } else {
        state.didntReviewed = getDidntReviewed(newList);
        state.list.push(action.payload);
        state.view.splice(0, state.view.length);
        state.view = state.list.slice(0, 6);
      }
    },
    receiveProductsToDispatch: (state, action) => {
      const newProductsToDispatch = [];
      const payload = action.payload;
      if (payload !== null) {
        newProductsToDispatch.push(...payload);
        state.productsToDispatch = newProductsToDispatch;
      }
    },
  },
});

export const { receiveOrders, update, receiveProductsToDispatch } =
  orderSlice.actions;

export const getOrders = async (idCompany, subsidiaryId, token, dispatch) =>
  await service
    .getOrdersBySubsidiaryV2(idCompany, subsidiaryId, token)
    .then((res) => {
      dispatch(receiveOrders(res));
      return res;
    });

export const getOrdersByIdCompany = async (
  {
    idCompany,
    limit,
    page,
    order,
    orderBy,
    searchText,
    searchField,
    byStatus,
    byPayment,
    byDate,
    startDate,
    endDate,
    subsidiaryId,
    origin,
    populate,
    query
  },
  token
) =>
  await service.getOrdersByIdCompany(
    {
      idCompany,
      limit,
      page,
      order,
      orderBy,
      searchText,
      searchField,
      byStatus,
      byPayment,
      byDate,
      startDate,
      endDate,
      subsidiaryId,
      origin,
      populate,
      query
    },
    token
  );

export const verifyOrder = async (id, verify, dispatch) => {
  dispatch(update({ id, verify }));
  return await service.verifyOrder(id, verify);
};

export const updateOrder = async (order, token, dispatch) => {
  dispatch(update(order));
  return await service.updateOrder(order, token);
};

export const updateStatus = async (data, token) => {
  return await service.updateStatus({ ...data }, token);
};
export const getDiscountsApplied = async (orderId, token) => {
  return await service.getDiscountsApplied(orderId, token);
};

export const getOrder = async (id, token) =>
  await service.getOrderById(id, token);

export const getCheckout = async (id, token) =>
  await service.getCheckoutById(id, token);

export const selectOrders = (state) => state.orders;

function getDidntReviewed(ordersList) {
  let count = 0;
  if (ordersList.length > 0) {
    for (let i = 0; i < ordersList.length; i += 1) {
      const item = ordersList[i];
      if (!item.reviewed) {
        count += 1;
      }
      if (i === ordersList.length - 1) {
        return count;
      }
    }
  } else {
    return count;
  }
}

export default orderSlice.reducer;
