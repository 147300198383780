/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import stylesCss from "./index.module.css";
import classNames from "classnames";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import { selectLoadingUI } from "../../reducers/uiSlice";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default function DynamicPopup({
  show,
  handleClose,
  handleSave,
  handleBack,
  component,
  cancelButtonText = "Cancelar",
  saveButtonText = "Si, estoy seguro",
  disableSaveButton = false,
  title = "",
  subtitle = "",
}) {
  const loading = useSelector(selectLoadingUI);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={show}
        fullScreen={fullScreen}
        maxWidth={"md"}
        onClose={(e) => handleClose()}
      >
        <DialogTitle id="customized-dialog-title">
          <h5 onClick={() => handleBack()}>
            <ArrowBack style={{ color: "#fff" }} /> Regresar
          </h5>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <h4 className={stylesCss.title}>{title}</h4>
          <h6 className={stylesCss.description}>{subtitle}</h6>
          {component}
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              width: 180,
              height: 40,
              margin: "17px auto",
            }}
            disabled={loading}
            onClick={() => handleClose()}
            className={classNames("btn", "blueEditionCancelButton2")}
          >
            <h6
              style={{ fontSize: 14 }}
              className={"blueEditionCancelButtonText2"}
            >
              {cancelButtonText}
            </h6>
          </Button>
          <Button
            style={{
              width: 180,
              height: 40,
              margin: "17px auto",
            }}
            disabled={loading || disableSaveButton}
            onClick={() => handleSave()}
            className={classNames("btn", "blueEditionSaveButton2")}
          >
            <h6
              className={"blueEditionSaveButtonText2"}
              style={{ fontSize: 14 }}
            >
              {saveButtonText}
            </h6>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h5 className={stylesCss.back}>{children}</h5>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: "0 auto",
  },
}))(MuiDialogContent);

const Dialog = withStyles((theme) => ({
  root: {
    margin: 5,
    padding: theme.spacing(1),
    // minWidth: 250,
  },
  paper: {
    width: 620,
    minHeight: 480,
    padding: theme.spacing(1),
    margin: "0 auto",
    backgroundColor: "#122526",
  },
}))(MuiDialog);

const DialogActions = withStyles((theme) => ({
  root: {},
}))(MuiDialogActions);
