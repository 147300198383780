/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { selectCompany } from "../../reducers/companySlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import routes from "../../constants/routes";

import styles from "./app.module.css";

const PaymentUpperHeader = () => {
  const company = useSelector(selectCompany);
  const [showRedMessage, setShowRedMessage] = useState(false);

  useEffect(() => {
    setShowRedMessage(company?.BalanceDays <= 0);
  }, [company.BalanceDays]);

  return (
    <div>
      {showRedMessage && (
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          className={styles.paymentBannerLinkContainer}
        >
          <Grid item>
            <a
              href={routes.COMPANY_PAYMENTS_SETTINGS.path}
              rel="noreferrer"
              target="_blank"
              className={styles.paymentBannerLink}
            >
              Le quedan pocos dias de suscripción, por favor haga click para ver
              más información
            </a>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default PaymentUpperHeader;
