import { lazy, Suspense } from 'react';
import LoadingPage from '../containers/loading';

const MailChimpMarketingPage = lazy(() =>
  import('../components/marketing/mailchimp')
);

const NewTemplateMailChimpMarketingPage = lazy(() =>
  import('../components/marketing/mailchimp/templates/newTemplate')
);

const NewCampaignMailChimpMarketingPage = lazy(() =>
  import('../components/marketing/mailchimp/campaign/viewCampaign')
);

export function MailChimpMarketingRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <MailChimpMarketingPage />
    </Suspense>
  );
}

export function NewTemplateMailChimpMarketingRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <NewTemplateMailChimpMarketingPage />
    </Suspense>
  );
}

export function NewCampaignMailChimpMarketingRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <NewCampaignMailChimpMarketingPage />
    </Suspense>
  );
}