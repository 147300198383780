import { lazy, Suspense } from 'react';
import LoadingPage from '../containers/loading';

const GoogleSettingsPage = lazy(() =>
  import('../components/marketing/integrations/google/analytics')
);

export default function PickersSettingsRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <GoogleSettingsPage />
    </Suspense>
  );
}
