import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState.ui,
  reducers: {
    setCompanyId: (state, payload) => {
      state.companyId = payload.payload;
    },
    setResolution: (state, payload) => {
      state.resolution = payload.payload;
    },
    loadingOn: (state) => {
      state.loading = true;
    },
    loadingOff: (state) => {
      state.loading = false;
    },
    setIsGoogleApiLoaded: (state, action) => {
      state.isGoogleApiLoaded = action.payload;
    },
    setWebHomeIndex: (state, action) => {
      state.webHome.index = action.payload;
    },
    setWebHomeSubIndex: (state, action) => {
      state.webHome.subIndex = action.payload;
    },
    setHeightPixel: (state, action) => {
      state.heightPixels = action.payload;
    },
    setIsPro: (state, action) => {
      state.isPro = action.payload;
    },
    setSubsidiary: (state, action) => {
      state.subsidiary = action.payload;
    }
  },
});


export const {
  loadingOn,
  loadingOff,
  setResolution,
  setIsGoogleApiLoaded,
  setCompanyId,
  setWebHomeIndex,
  setWebHomeSubIndex,
  setHeightPixel,
  setIsPro,
  setSubsidiary
} = uiSlice.actions;

export const selectLoadingUI = (state) => state.ui.loading;
export const selectResolution = (state) => state.ui.resolution;
export const selectIsPro = (state) => state.ui.isPro;
export const selectIsGoogleApiLoaded = (state) => state.ui.isGoogleApiLoaded;
export const selectCompanyId = (state) => state.ui.companyId;
export const selectWebHomeIndex = (state) => state.ui.webHome.index;
export const selectWebHomeSubIndex = (state) => state.ui.webHome.subIndex;
export const selectHeightPixels = (state) => state.ui.heightPixels;
export const selectSubsidiary = (state) => state.ui.subsidiary;

export default uiSlice.reducer;
