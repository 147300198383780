import config from "../config";
import { get, put, Delete, post } from "../http";

export async function getByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/subsidiary/all?id=${id}`,
    headers
  );
}

export async function create(subsidiary, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...subsidiary },
  };
  return await post(
    `${config.companyMicroserviceUrlApi}/subsidiary/`,
    headers,
    body
  );
}

export async function updateSubsidiary(subsidiary, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: subsidiary,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/subsidiary/`,
    headers,
    body
  );
}

export async function DeleteSubsidiary(subsidiary, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...subsidiary },
  };
  return await Delete(
    `${config.companyMicroserviceUrlApi}/subsidiary/`,
    headers,
    body
  );
}

const functions = {
  getByIdCompany,
  create,
  DeleteSubsidiary,
  updateSubsidiary,
};

export default functions;
