import axios from "axios";

export const get = (
  url,
  headers,
  params = {},
  useHandler = true,
  cancelToken = axios.CancelToken.source().token
) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          ...headers,
        },
        params: {
          ...params,
        },
        cancelToken,
      })
      .then((res) => resolve(useHandler ? handleResponse(res) : res))
      .catch((err) => reject(handleError(err)));
  });

export const getBlob = (
  url,
  headers,
  params = {},
  useHandler = true,
  cancelToken = axios.CancelToken.source().token
) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          ...headers,
        },
        params: {
          ...params,
        },
        cancelToken,
        responseType: "blob",
      })
      .then((res) => resolve(useHandler ? handleResponse(res) : res))
      .catch((err) => reject(handleError(err)));
  });

export const post = (url, headers, body) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, body, {
        headers: {
          ...headers,
        },
      })
      .then((res) => resolve(handleResponse(res)))
      .catch((err) => reject(handleError(err)));
  });

export const put = (url, headers, data) =>
  new Promise((resolve, reject) => {
    axios({
      method: "put",
      url,
      headers,
      data: { ...data },
    })
      .then((res) => resolve(handleResponse(res)))
      .catch((err) => reject(handleError(err)));
  });

export const patch = (url, headers, data) =>
  new Promise((resolve, reject) => {
    axios({
      method: "patch",
      url,
      headers,
      data: { ...data },
    })
      .then((res) => resolve(handleResponse(res)))
      .catch((err) => reject(handleError(err)));
  });

export const Delete = (url, headers = {}, body = {}) =>
  new Promise((resolve, reject) => {
    axios({
      method: "delete",
      url,
      headers,
      data: { ...body },
    })
      .then((res) => resolve(handleResponse(res)))
      .catch((err) => reject(handleError(err)));
  });

const handleError = (error) => {
  try {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data.message;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return error.request.response;
    } else {
      // Something happened in setting up the request that triggered an Error
      return error.message;
    }
  } catch (error) {
    return error.message;
  }
};

const handleResponse = (res) => {
  try {
    if (res.data.data) return res.data.data;
    else if (res.data) return res.data;
    return res;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getHeaders = (token = "") => {
  const headers = {};
  headers["Content-Type"] = "application/json";
  if (token !== "") headers["Authorization"] = `Bearer ${token}`;
  return headers;
};
