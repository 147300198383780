import service from "../utils/services/payments";
import { receivePayments } from "./companySlice";

export const getAllPayments = async (idCompany, token, dispatch) =>
  await service.getByIdCompany(idCompany, token).then((res) => {
    dispatch(receivePayments(res));
    return res;
  });

export const updatePayment = async (data, token) =>
  await service.update(data, token);
