/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { selectCompany, getCompany } from "../../../../reducers/companySlice";
import {
  createIngefactura,
  deleteIngefactura,
  getIngefactura,
  updateIngefactura,
} from "../../../../utils/services/company";
import { useDispatch } from "react-redux";
import {
  selectLoadingUI,
  loadingOn,
  loadingOff,
} from "../../../../reducers/uiSlice";
import { Notification } from "../../../../containers/notification";
import { useSnackbar } from "notistack";
import { selectUser } from "../../../../reducers/userSlice";
import {
  selectChangesDeposit,
  setChanges,
} from "../../../../reducers/depositSlice";
import { createChangeRecord } from "../../../../reducers/depositChangesSlice";
import dateUtils from "../../../../utils/date";
import { useNavigate } from "react-router-dom";

// CSS

import styles from "./styles.module.css";

//MATERIAL
import InputComponent from "../../../inputs";
import Toolbar from "../../../card/toolbar";
import jwt from "jsonwebtoken";
import config from "../../../../utils/config";
import { Checkbox } from "@material-ui/core";
import { selectSubsidiaries } from "../../../../reducers/subsidiarySlice";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useLoadingState from "../../../../utils/hooks/useLoadingState";

const logo =
  "https://ingefactura.cl/wp-content/uploads/2020/07/logo_ingefactura-e1595028794181.png";

export default function IngefacturaComponent() {
  const [token, setToken] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [isTest, setIsTest] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [subsidiariesInfo, setSubsidiariesInfo] = useState([]);
  const subsidiaries = useSelector(selectSubsidiaries);
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingUI);
  const changes = useSelector(selectChangesDeposit);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useLoadingState();
  useEffect(() => {
    if (company && company._id !== "") initialize();
  }, [company, subsidiaries]);

  const initialize = async () => {
    startLoading();
    const found = await getIngefactura(company._id).catch((err) => {
      console.log(err);
      return null;
    });

    if (found && found.token) {
      setToken(jwt.verify(found.token, config.jwtSecret));
      setIsTest(found.isTest);
      setRazonSocial(found.razonSocial);
      setSubsidiariesInfo(
        found.subsidiariesInfo.map((item) => {
          const foundSubsidiary = subsidiaries.find(
            (s) => s._id.toString() === item.subsidiaryId.toString()
          );
          if (foundSubsidiary)
            return {
              ...item,
              name: foundSubsidiary.Name,
            };
          else return { ...item };
        })
      );
    } else {
      setToken("");
      setSubsidiariesInfo(
        subsidiaries.map((item) => {
          return {
            subsidiaryId: item._id,
            acteco: "",
            rut: "",
            giro: "ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MOVIL DE COMIDAS",
            name: item.Name,
          };
        })
      );
    }
    stopLoading();
  };

  const updateChanges = (fieldName, newValue) => {
    const newChanges = [];
    newChanges.push(...changes);
    var index = -1;
    const change = {
      text: "",
      oldValue: null,
      newValue: null,
      date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
    };

    change.newValue = newValue;
    change.oldValue = company && company[fieldName] ? company[fieldName] : "";

    switch (fieldName) {
      case "Token":
        change.text = "Cambios en el Bearer Token de ingefactura";
        break;
      default:
        break;
    }

    index = newChanges.findIndex(
      (item) => item.text.toString() === change.text.toString()
    );

    if (index !== -1) {
      change.oldValue = newChanges[index].oldValue;
      newChanges[index] = change;
    } else {
      newChanges.push(change);
    }
    dispatch(setChanges(newChanges));
  };

  const handleCancel = () => initialize();

  const handleSave = async () => {
    dispatch(loadingOn());
    const found = await getIngefactura(company._id).catch((err) => {
      console.log(err);
      return null;
    });
    if (found && found.token)
      updateIngefactura({
        companyId: company._id,
        token: jwt.sign(token, config.jwtSecret),
        isTest: isTest,
        subsidiariesInfo,
        razonSocial,
      })
        .then((res) => {
          dispatch(loadingOff());
          getCompany(company._id, dispatch);
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "ingefactura",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    else
      createIngefactura({
        companyId: company._id,
        token: jwt.sign(token, config.jwtSecret),
        isTest: isTest,
        subsidiariesInfo,
        razonSocial,
      })
        .then((res) => {
          dispatch(loadingOff());
          getCompany(company._id, dispatch);
          enqueueSnackbar(
            Notification({
              text: "Datos de la tienda actualizados",
              variant: "success",
              withDetails: false,
            })
          );
          setUpdated(false);
          createChangeRecord(
            {
              user: user,
              username: user.username,
              fullName: `${user.firstName} ${user.lastName}`,
              idUser: user.id,
              idCompany: company._id,
              idObject: company._id,
              module: "settings",
              submodule: "ingefactura",
              createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
              changes: changes,
            },
            user.token,
            dispatch
          ).catch((err) => console.log(err));
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al actualizar datos de la tienda",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
  };

  const handleRemove = async () => {
    dispatch(loadingOn());
    deleteIngefactura(company._id)
      .then(async (res) => {
        dispatch(loadingOff());
        getCompany(company._id, dispatch);
        await initialize();
        enqueueSnackbar(
          Notification({
            text: "Datos de la tienda actualizados",
            variant: "success",
            withDetails: false,
          })
        );
        setUpdated(false);
        createChangeRecord(
          {
            user: user,
            username: user.username,
            fullName: `${user.firstName} ${user.lastName}`,
            idUser: user.id,
            idCompany: company._id,
            idObject: company._id,
            module: "settings",
            submodule: "ingefactura",
            createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
            changes: changes,
          },
          user.token,
          dispatch
        ).catch((err) => console.log(err));
      })
      .catch((err) => {
        enqueueSnackbar(
          Notification({
            text: "Error al actualizar datos de la tienda",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className={styles.parentContainer}>
      <button className={styles.backButton} onClick={() => handleClose()}>
        <span>
          <ArrowBackIcon />
        </span>
        Regresar
      </button>
      <div className={styles.infoContainer}>
        <div className={styles.titleLogoContainer}>
          <Toolbar title="Ingefactura" />
          <img src={logo} alt="logo" className={styles.imageElement} />
        </div>
        <div className={styles.infoDataContainer}>
          <p className={styles.textDescription}>
            Al integrar con Ingefactura, obtendrás la ventaja de poder
            centralizar todas tus ventas en una sola plataforma. Esto significa
            que podrás emitir tus boletas tanto desde tu POS como desde tu sitio
            web, todo en una sincronía perfecta. De esta forma, podrás tener un
            mayor control sobre tus ventas y reducir el tiempo que dedicas a
            gestionar tus facturas y boletas. Además, al contar con esta
            herramienta, podrás optimizar tus procesos y mejorar la experiencia
            de tus clientes al ofrecerles una forma de pago más eficiente y
            segura. ¡Integra con Ingefactura y lleva el control de tus ventas al
            siguiente nivel
          </p>
          <div className={styles.inputStackContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Razon Social</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setRazonSocial(e.target.value);
                  updateChanges("RazonSocial", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={razonSocial === ""}
                value={razonSocial}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Bearer Token</label>

              <InputComponent
                placeholder="Ej. 12345678901234"
                type="text"
                handler={(e) => {
                  setToken(e.target.value);
                  updateChanges("Token", e.target.value);
                  setUpdated(true);
                }}
                disabled={loading}
                validation={token === ""}
                value={token}
              />
            </div>
            {subsidiariesInfo.map((item) => (
              <Fragment>
                <div className={styles.subsidiaryNameContainer}>
                  <p className={styles.subsidiaryName}>
                    Sucursal: <strong>{item.name}</strong>
                  </p>
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.labelInput}>Acteco</label>

                  <InputComponent
                    placeholder="Ej. 12345678901234"
                    type="text"
                    handler={(e) => {
                      setSubsidiariesInfo((p) =>
                        p.map((ps) => {
                          if (ps.subsidiaryId === item.subsidiaryId) {
                            return {
                              ...ps,
                              acteco: e.target.value,
                            };
                          } else return ps;
                        })
                      );
                      updateChanges("Acteco", e.target.value);
                      setUpdated(true);
                    }}
                    disabled={loading}
                    validation={item.acteco === ""}
                    value={item.acteco}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.labelInput}>Rut</label>

                  <InputComponent
                    placeholder="Ej. 12345678901234"
                    type="text"
                    handler={(e) => {
                      setSubsidiariesInfo((p) =>
                        p.map((ps) => {
                          if (ps.subsidiaryId === item.subsidiaryId) {
                            return {
                              ...ps,
                              rut: e.target.value,
                            };
                          } else return ps;
                        })
                      );
                      updateChanges("rut", e.target.value);
                      setUpdated(true);
                    }}
                    disabled={loading}
                    validation={item.rut === ""}
                    value={item.rut}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.labelInput}>Giro</label>

                  <InputComponent
                    placeholder="Ej. 12345678901234"
                    type="text"
                    handler={(e) => {
                      setSubsidiariesInfo((p) =>
                        p.map((ps) => {
                          if (ps.subsidiaryId === item.subsidiaryId) {
                            return {
                              ...ps,
                              giro: e.target.value,
                            };
                          } else return ps;
                        })
                      );
                      updateChanges("giro", e.target.value);
                      setUpdated(true);
                    }}
                    disabled={loading}
                    validation={item.giro === ""}
                    value={item.giro}
                  />
                </div>
              </Fragment>
            ))}
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>Activar modo prueba</label>

              <Checkbox
                checked={isTest}
                onChange={(e) => {
                  setIsTest(e.target.checked);
                  setUpdated(true);
                }}
                value={"isTest"}
                style={{ color: "#122526" }}
              />
            </div>
            <div className={styles.buttonsContainer}>
              {token !== "" ? (
                <button
                  onClick={() => {
                    handleRemove();
                  }}
                  className={styles.deleteButton}
                >
                  Eliminar
                </button>
              ) : null}
              <button
                disabled={!updated}
                onClick={() => {
                  setUpdated(true);
                  handleCancel();
                }}
                className={styles.cancelButton}
              >
                Cancelar
              </button>
              <button
                disabled={!updated}
                className={styles.confirmButton}
                onClick={() => {
                  setUpdated(true);
                  handleSave();
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
