import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const DiscountMarketingPage = lazy (() =>
  import ('../components/marketing/email/email')
);

const ViewEmailPage = lazy (() =>
  import ('../components/marketing/email/view/viewEmail.component')
);

export function EmailMarketingRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <DiscountMarketingPage />
    </Suspense>
  );
}

export function ViewEmailRoute({path}) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ViewEmailPage />
    </Suspense>
  );
}
