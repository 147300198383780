import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const Page = lazy(() => import("../containers/booking"));

export function BookingsRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default BookingsRoute;
