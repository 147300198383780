import config from "../config";
import { get, put, Delete, post } from "../http";

async function getByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/category/getByIdCompany?id=${id}`,
    headers
  );
}

async function create(category, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...category },
  };
  return await post(
    `${config.companyMicroserviceUrlApi}/category/`,
    headers,
    body
  );
}

async function update(categories, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: categories,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/category/`,
    headers,
    body
  );
}

async function updateById(category, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: category,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/category/byId`,
    headers,
    body
  );
}

async function DeleteCategory(category, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...category },
  };
  return await Delete(
    `${config.companyMicroserviceUrlApi}/category/`,
    headers,
    body
  );
}

const categoryService = {
  getByIdCompany,
  create,
  DeleteCategory,
  update,
  updateById,
};

export default categoryService;
