import moment from "moment";

export const gettingFilteredSubsidiariesByUserAccess = (
  user,
  subsidiaries,
  subsidiary
) => {
  if (user?.email === "lumarketo.desarrollo@gmail.com") {
    return subsidiaries;
  } else {
    if (user?.subsidiaries?.length === 1 && user?.subsidiaries[0] === "all") {
      return subsidiaries;
    } else if (subsidiary.includes("all")) {
      return subsidiaries.filter((item) =>
        user?.subsidiaries?.includes(item._id)
      );
    } else {
      return subsidiaries.filter((subsidiarySelected) =>
        subsidiarySelected._id?.includes(subsidiary)
      );
    }
  }
};

export const checkSubsidiaryAutomaticAcceptanceStatus = (subsidiaries) => {
  return subsidiaries.some((subsidiary) => subsidiary.UseAutoOrderAcceptance);
};

export const checkIfSubsidiaryIsInWorkingHours = () => {
  const chileCurrentTime = moment().tz("America/Santiago");

  const nineAM = moment
    .tz("America/Santiago")
    .set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  const twelveAM = moment
    .tz("America/Santiago")
    .add(1, "day")
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const isAfterNineAM = chileCurrentTime.isAfter(nineAM);
  const isBeforeTwelveAM = chileCurrentTime.isBefore(twelveAM);

  return isAfterNineAM && isBeforeTwelveAM;
};

const subsidiariesFunctions = {
  gettingFilteredSubsidiariesByUserAccess,
  checkSubsidiaryAutomaticAcceptanceStatus,
  checkIfSubsidiaryIsInWorkingHours,
};
export default subsidiariesFunctions;
