import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';
// import service from '../utils/services/';

export const seoSlice = createSlice({
  name: 'seo',
  initialState: initialState.seo,
  reducers: {
    notUpdated: (state) => {
      state.updated = false;
    },
    updated: (state) => {
      state.updated = true;
    },
    receiveMetaTags: (state, action) => {
      state.metaTags.splice(0, state.metaTags.length);
      state.metaTags.push(...action.payload);
    },
  },
});

export const {notUpdated, updated} = seoSlice.actions;

// export const update = async (data, token, dispatch) => {
//     dispatch(notUpdated());
//     return await service.update(data, token);
//   };

//   const receiveMetaTagsDispatch = json => ({
//     type: companyConstants.RECEIVE_METATAGS,
//     payload: json
//   });

//   const receiveMetaTags = MetaTags => dispatch => {
//     dispatch(receiveMetaTagsDispatch(MetaTags));
//   };

//   const getAllMetaTags = async (idCompany, token, dispatch) => {
//     await service.getByIdCompany(idCompany, token)
//       .then(res => dispatch(receiveMetaTags(res)))
//       .catch(err => dispatch(alertActions.error('Error al obtener marcas')));
//   };

export const selectSeo = (state) => state.seo;

export default seoSlice.reducer;
