import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {SnackbarContent, useSnackbar} from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import './notification.css';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 0,
    marginLeft: 5,
    backgroundColor: 'transparent',
  },
}));

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {closeSnackbar} = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [content, setContent] = useState({
    text: '',
    variant: 'info',
    details: '',
    withDetails: false,
  });

  useEffect(() => {
    setContent(JSON.parse(props.message));
  }, [props, props.message]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Alert
        severity={content.variant}
        action={
          <>
            {content.withDetails && (
              <IconButton
                aria-label="Show more"
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </>
        }
        className={classes.alertAction}
      >
        <AlertTitle className="text">{content.text}</AlertTitle>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography gutterBottom className="details">
            {content.details}
          </Typography>
        </Collapse>
      </Alert>
    </SnackbarContent>
  );
});

export function Notification({text, variant, details, withDetails}) {
  return JSON.stringify({text, variant, details, withDetails});
}

export default SnackMessage;
