import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const ShippingSettingsPage = lazy(() =>
  import("../components/settings/shipping/shipping.component")
);
const ShippingModulePage = lazy(() =>
  import("../components/settings/shipping/shippingModule.component")
);

const ViewShippingModulePage = lazy(() =>
  import("../components/settings/shipping/itemShippingModule.component")
);

const ShippingFulfillmentSettingsPage = lazy(() =>
  import("../components/settings/shippingFulfillment/methods.Settings")
);
const PolygonModulePage = lazy(() =>
  import("../components/settings/shipping/polygons")
);

const ShippingPreferencePage = lazy(() =>
  import("../components/settings/shipping/preference")
);

export function ViewShippingModuleRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ViewShippingModulePage />
    </Suspense>
  );
}

export function ShippingModuleRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ShippingModulePage />
    </Suspense>
  );
}

export default function ShippingSettingsRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ShippingSettingsPage />
    </Suspense>
  );
}

export function ShippingFulfillmentRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ShippingFulfillmentSettingsPage />
    </Suspense>
  );
}

export function PolygonRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <PolygonModulePage />
    </Suspense>
  );
}


export function ShippingPreferenceRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ShippingPreferencePage />
    </Suspense>
  );
}
