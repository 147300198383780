import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const Page = lazy(() => import("../containers/resetPassword/resetPassword"));

function ResetPasswordRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default ResetPasswordRoute;
