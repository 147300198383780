import React, { useState, useEffect } from "react"

export const TabNotification = ({messageHistory}) => {
    const [toggleComponent, setToggleComponent] = useState(false)

    useEffect(() => {
        if (messageHistory && messageHistory.length > 0) {
            // setHistoryOrders(messageHistory)
            const interval = setInterval(() => {
                setToggleComponent(p => !p);
            }, 2000);

            return () => clearInterval(interval);
        } else {
            // setHistoryOrders(0)
        }

    }, [messageHistory]);

     useEffect(() => {
         var link = document.querySelector("link[rel~='icon']");
         if (!link) {
             link = document.createElement('link');
             link.rel = 'gif';
             document.head.appendChild(link);
         }
          link.href = 'https://s3.amazonaws.com/haya.lumarketo.cl/icons8-box-important.gif';
          link.href = !toggleComponent && messageHistory.length > 0 ? 'https://s3.amazonaws.com/haya.lumarketo.cl/icons8-box-important-32.png': "https://s3.amazonaws.com/haya.lumarketo.cl/favicon.ico"
          document.title = !toggleComponent && messageHistory.length > 0 ? messageHistory.length + " pedido(s) por aceptar"  : "Inicio";

     }, [toggleComponent, messageHistory])
        return (
            <div> 
            </div>
        )
}