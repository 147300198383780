import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';
import service from '../utils/services/category';
import {receiveCategories} from './companySlice';
export const categorySlice = createSlice({
  name: 'categories',
  initialState: initialState.categories,
  reducers: {},
});

export const update = async (data, token, dispatch) => {
  // dispatch(notUpdated());
  return await service.update(data, token);
};

export const getAllCategories = async (idCompany, token, dispatch) =>
  await service.getByIdCompany(idCompany, token).then((res) => {
    dispatch(receiveCategories(res));
    return res;
  });

export const selectCategories = (state) => state.categories;

export default categorySlice.reducer;
