import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import service from "../utils/services/shipping";

export const shippingSlice = createSlice({
  name: "shipping",
  initialState: initialState.shipping,
  reducers: {
    remove: (state, action) => {
      const index = state.findIndex((item) => item._id === action.payload._id);
      if (index > -1) state.splice(index, 1);
    },
    receiveShipping: (state, action) => {
      const index = state.findIndex((item) => item._id === action.payload._id);
      if (index > -1) state[index] = action.payload;
    },
    receive: (state, action) => {
      state.splice(0, state.length);
      state.push(...action.payload);
    },
  },
});

export const { receive, receiveShipping, remove } = shippingSlice.actions;

export const getAllShipping = (companyId, token, dispatch) => {
  return service
    .getByIdCompany(companyId, token)
    .then((res) => {
      dispatch(receive(res));
      return res;
    })
    .catch((err) => err);
};

export const getShipping = (id, token, dispatch) =>
  service.getById(id, token).then((res) => {
    dispatch(receiveShipping(res));
    return res;
  });

export const createShipping = (
  data,
  token
) =>
  service.create(
    {
      ...data
    },
    token
  );

export const updateShipping = (
  data,
  token
) =>
  service.update(
    data,
    token
  );

export const removeShipping = (id, token, dispatch) =>
  service.remove(id, token).then((res) => {
    dispatch(remove({ _id: id }));
    return res;
  });

export const selectShipping = (state) => state.shipping;

export default shippingSlice.reducer;
