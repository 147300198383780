/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import uuid from "react-uuid";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import {
  useTheme,
  createTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { loadingOff, loadingOn, selectLoadingUI } from "../../reducers/uiSlice";
import { Notification } from "../../containers/notification";
import css from "./styles.module.css";
import { selectUser } from "../../reducers/userSlice";
import productService from "../../utils/services/products";
import { selectImgHistory, getHistory } from "../../reducers/imgHistorySlice";
import ImageSkeleton from "../skeleton/Image";
import { selectCompany } from "../../reducers/companySlice";
import { UploadImg } from "../../constants/web.constants";

function PlusImgButton({
  callback,
  width,
  height,
  id = "",
  loadingLogo = false,
  path = "",
  alt = "",
  text = "Cargar imagen",
  subtext = "",
  uploadImgPath = UploadImg,
  preview = true,
  withHistory = true
}) {
  const theme = useTheme();
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingUI);
  const user = useSelector(selectUser);
  const imgHistory = useSelector(selectImgHistory);
  const company = useSelector(selectCompany);
  const inputId = `input-${Date.now() * 3}`;
  const [firstUpdate, setFirstUpdate] = useState(false);

  useEffect(() => {
    if (
      !firstUpdate &&
      company &&
      company._id &&
      company._id !== "" &&
      user &&
      user.token &&
      user.token !== "" &&
      withHistory
    ) {
      getHistory(user.token || "", loadingLogo, company._id, dispatch).catch(
        (err) => console.log(err)
      );
      setFirstUpdate(true);
    }
  }, [company, user, firstUpdate, withHistory]);

  const handleChange = (event) => {
    const readerManager = new FileReader();
    const file = event.target.files[0];

    if (file.size > 100000) {
      enqueueSnackbar(
        Notification({
          text: "La Imagen es muy grande!",
          variant: "error",
          withDetails: false,
        })
      );
      event.target.value = "";
    } else {
      try {
        readerManager.onloadend = () => {
          const result = readerManager.result;
          uploadImg(file, result);
        };

        readerManager.readAsDataURL(file);
        dispatch(loadingOn());
      } catch (error) {
        console.log(error);
      } finally {
        event.target.value = null;
      }
    }
  };

  const uploadImg = (file, base64, tinified = false) => {
    const name = `${uuid()}-${file.name}`;
    productService
      .uploadImgFromBase64(
        tinified ? `data:${file.type};base64,${base64}` : base64,
        !loadingLogo ? name : "LoadingLogo.gif",
        file.type,
        name,
        user.token || "",
        company._id
      )
      .then(async (res) => {
        if (callback)
          callback({
            location: res.Location,
            key: !loadingLogo ? name : "LoadingLogo.gif",
          });
        enqueueSnackbar(
          Notification({
            text: "Nueva Imagen subida correctamente.",
            variant: "success",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
        handleClose();
        if (company && company._id && company._id !== "" && withHistory) {
          await getHistory(
            user.token || "",
            loadingLogo,
            company._id,
            dispatch
          ).catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          Notification({
            text: "Error al subir la imagen.",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };

  const handleClose = () => {
    if (!loading) setOpen(false);
  };

  const handleOpen = () => setOpen(true);

  return (
    <Fragment>
      <div className={classes.root} id={id}>
        {!withHistory ?
          <div className={classes.root}>
            <input
              className={classNames(
                css.margin,
                css.textField,
                css.input,
                classes.image
              )}
              id={inputId}
              disabled={loading}
              accept="image/*"
              type="file"
              max-file-size="100"
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor={inputId}>
              <Button
                component="span"
                focusRipple
                key={uuid()}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                disabled={loading}
                onClick={() => handleOpen()}
                style={{
                  width: width ? width : "",
                  height: height ? height : "",
                  backgroundImage: preview
                    ? !hovered
                      ? path !== ""
                        ? `url(${path})`
                        : `url(${uploadImgPath})`
                      : "none"
                    : `url(${uploadImgPath})`,
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <span className={classes.imageBackdrop} />
                {hovered && (
                  <UploadImageComponent
                    text={text}
                    subtext={subtext}
                    uploadImgPath={uploadImgPath}
                    classes={classes}
                  />
                )}
              </Button>
            </label>
          </div> : null}
        {withHistory ? <ButtonBase
          focusRipple
          key={uuid()}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          disabled={loading}
          onClick={() => handleOpen()}
          style={{
            width: width ? width : "",
            height: height ? height : "",
            backgroundImage: preview
              ? !hovered
                ? path !== ""
                  ? `url(${path})`
                  : `url(${uploadImgPath})`
                : "none"
              : `url(${uploadImgPath})`,
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <span className={classes.imageBackdrop} />
          {hovered && (
            <UploadImageComponent
              text={text}
              subtext={subtext}
              uploadImgPath={uploadImgPath}
              classes={classes}
            />
          )}
        </ButtonBase> : null}
      </div>
      {withHistory ? <MuiThemeProvider theme={themeDialog}>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className={css.dialog}
        >
          <DialogTitle id="form-dialog-title">Imágenes</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Aqui podrá elegir imágenes de su historial o añadir nuevas.
              Recuerda que el tamaño máximo de las imágenes debe ser de 1Mb.
            </DialogContentText>
            <div className={css.imgListContainer}>
              <Grid container spacing={1}>
                <Grid key={"addingImg"} item xs={4}>
                  {!loadingLogo ? (
                    <input
                      className={classNames(
                        css.margin,
                        css.textField,
                        css.input
                      )}
                      id={inputId}
                      disabled={loading}
                      accept="image/*"
                      type="file"
                      max-file-size="100"
                      onChange={(e) => handleChange(e)}
                    />
                  ) : (
                    <input
                      className={classNames(
                        css.margin,
                        css.textField,
                        css.input
                      )}
                      id={inputId}
                      disabled={loading}
                      accept="image/gif"
                      type="file"
                      max-file-size="100"
                      onChange={(e) => handleChange(e)}
                    />
                  )}
                  <label htmlFor={inputId} className={css.label}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={css.addButton}
                      disabled={loading}
                    >
                      <AddIcon className={css.addMore} />
                    </Button>
                  </label>
                </Grid>
                {imgHistory.map((item) => (
                  <Grid
                    key={item._id}
                    item
                    xs={4}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ImageSkeleton
                      src={item.Url}
                      alt={item && item.Alt ? item.Alt : item._id}
                      styles={css.ImgButton}
                      handleClick={() => {
                        if (!loading) {
                          callback({ location: item.Url, key: item.Alt });
                          handleClose();
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                callback({
                  location: "",
                  key: "",
                });
                handleClose();
              }}
              color="primary"
              disabled={loading}
            >
              Quitar imagen
            </Button>
            <Button onClick={handleClose} color="primary" disabled={loading}>
              Salir
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider> : null}
    </Fragment>
  );
}

const UploadImageComponent = memo(
  ({ text, classes, uploadImgPath, subtext }) => {
    return (
      <span className={classes.imageButton}>
        <span className={classes.imageMarked} />
        <span className={classes.imageTitle}>
          <img
            src={uploadImgPath}
            alt="cargar imagen"
            className={css.uploadImgIcon}
          />
        </span>{" "}
        <span
          style={{
            position: "relative",
            bottom: "30%",
          }}
        >
          <h6 className={css.buttonTitle}>{text}</h6>
          <h6 className={css.buttonSubTitle}>{subtext}</h6>
        </span>
      </span>
    );
  },
  (p, n) => {
    return p.text === n.text && p.uploadImgPath === n.uploadImgPath;
  }
);

const themeDialog = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiPaper: {
      root: {
        minWidth: 615,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginBottom: 5,
    justifyContent: "center",
  },
  image: {
    position: "relative",
    border: "0.368766px solid #000000",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // [theme.breakpoints.down("xs")]: {
    //   width: "100% !important", // Overrides inline-style
    //   height: 100,
    // },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.55,
        backgroundColor: "#122526",
      },
      "& $imageMarked": {
        top: "20%",
        opacity: 0.4,
        // backgroundColor: "#122526",
      },
      "& $imageTitle": {
        // border: "4px solid currentColor",
        display: "unset",
        color: "#fff",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "block",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "#122526",
    opacity: 0.01,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    // top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    // "-webkit-box-orient": "vertical",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    width: "100%",
    height: "100%",
    // "-webkit-line-clamp": 2,
  },
  imageMarked: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    transition: theme.transitions.create("opacity"),
  },
}));

export default PlusImgButton;
