import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core";

const SliderComponent = withStyles({
  root: {
    color: "#122526",
    height: 8,
    width: "90%",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    privateValueLabel: {
      label: {
        color: "#fff",
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  markLabel: {
    color: "#fff",
  },
  label: {
    color: "#fff",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  PrivateValueLabel: {
    label: {
      color: "#fff",
    },
  },
})(Slider);

export default SliderComponent;
