import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import service from '../utils/services/customerService';

export const customerServiceSlice = createSlice({
    name: 'customerService',
    initialState: initialState.customerService,
    reducers: {
        receiveCustomerService: (state, action) => {
            state.splice(0, state.length);
            state.push(...action.payload);
        },
    },
});

export const { receiveCustomerService } = customerServiceSlice.actions;

export const getAllRequestByIdCompany = async (id, token) =>
    await service.getByIdCompany(id, token);

export const selectCustomerService = (state) => state.customerService;

export default customerServiceSlice.reducer;
