import slugify from "slugify";

export const compare = (value1, value2) => {
  const trimedString1 = value1.toString().trim().toUpperCase();
  const trimedString2 = value2.toString().trim().toUpperCase();
  return trimedString1.includes(trimedString2);
};

export const removeLastSlash = (string) => {
  const lastChr = string.substr(string.length - 1, 1);
  if (lastChr.includes("/")) return string.replace(/.$/, "");
  return string;
};

export const getColorRGB = (color) => {
  if (color) return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  return `rgb(255,255,255)`;
};

export const getColorObjectRGB = (color) => {
  let values = color.replace("rgb(", "").replace(")", "").split(",");
  if (values && values.length > 2)
    return {
      r: Number(values[0].trim()),
      g: Number(values[1].trim()),
      b: Number(values[2].trim()),
    };
  return {
    r: 255,
    g: 255,
    b: 255,
  };
};

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export const getRgbToHex = (color) => {
  return (
    "#" +
    componentToHex(color.r) +
    componentToHex(color.g) +
    componentToHex(color.b)
  );
};

export const getWidth = (resolution) => {
  const width = `${resolution.split("x")[0]}`;
  if (width.includes("px") || width.includes("vh") || width.includes("%"))
    return width;
  else return `${width}px`;
};

export const getHeight = (resolution) => {
  const height = `${resolution.split("x")[1]}`;
  if (height.includes("px") || height.includes("vh") || height.includes("%"))
    return height;
  else return `${height}px`;
};

export const shorter = (max, s1, s2) => {
  var arr1 = s1.split(" "),
    arr2 = s2.split(" "),
    i = 0,
    l1 = arr1.length,
    unicas = [];
  for (; i < l1; i++) {
    if (arr2.indexOf(arr1[i]) === -1 && arr1[i].length <= max)
      unicas.push(arr1[i]);
  }
  return unicas;
};

export const slugifyText = (text, replace = "-", lower = true) =>
  slugify(text, {
    replacement: replace,
    remove: '/[*+~.(){}[]#$%^&,="!:@]/g',
    strict: true,
    lower,
  });

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st, chunkSize) =>
  st.match(new RegExp(`.{${chunkSize}}`, "g"));

const convertHexUnitTo256 = (hexStr) =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16);

const getAlphafloat = (a, alpha) => {
  if (typeof a !== "undefined") {
    return a / 255;
  }
  if (typeof alpha != "number" || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

export const hexToRGBA = (hex, alpha) => {
  if (!isValidHex(hex)) {
    throw new Error("HEX inválido");
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
  return { r, g, b, a: getAlphafloat(a, alpha) };
};

export const goTo = (name, brand, id, size, color) =>
  `/producto/${name.split(" ").join("-")}/${(brand && brand !== ""
    ? brand
    : "sin-marca"
  )
    .split(" ")
    .join("-")}/${id}?size=${size}&color=${color}`;

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const stringUtils = {
  capitalize,
  compare,
  removeLastSlash,
  getColorRGB,
  getHeight,
  getWidth,
  shorter,
  slugifyText,
  validateEmail,
  hexToRGBA,
  goTo,
};

export default stringUtils;
