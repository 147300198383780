import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';
import service from '../utils/services/email';

export const emailSlice = createSlice({
  name: 'email',
  initialState: initialState.email,
  reducers: {
    receiveEmails: (state, action) => {
      state.splice(0, state.length);
      state.push(...action.payload);
    },
  },
});

export const {receiveEmails} = emailSlice.actions;

export const update = async (data, token) => await service.update(data, token);

export const create = async (data, token) => await service.create(data, token);

export const remove = async (data, token) => await service.remove(data, token);

export const getAllEmails = async (idCompany, token, dispatch) =>
  await service
    .getByIdCompany(idCompany, token)
    .then((res) => dispatch(receiveEmails(res)));

export const getById = async (id, token) => await service.getById(id, token);

export const selectEmail = (state) => state.email;

export default emailSlice.reducer;
