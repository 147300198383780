import config from '../config.js'
import { get, post, put, Delete } from '../http';

const getStatus = async () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await get(`${config.addressMicroserviceUrlApi}/system/status`, headers);
}

const getDistance = async (data, token) => {
  const headers = {
    'Content-Type': 'application/json',
    "authorization": `Bearer ${token}`
  };

  return await get(`${config.addressMicroserviceUrlApi} /address/getDistance ? lat1 = ${data.lat1}& lng1=${data.lng1}& lat2=${data.lat2}& lng2=${data.lng2} `,
    headers);
}


async function create(address, token) {
  const headers = {
    'Content-Type': 'application/json',
    "authorization": `Bearer ${token}`
  };
  const body = {
    data: { ...address }
  };
  return await post(`${config.addressMicroserviceUrlApi}/address/`, headers, body);
}

async function remove(address, token) {
  const headers = {
    'Content-Type': 'application/json',
    "authorization": `Bearer ${token}`
  };
  const body = {
    data: { ...address }
  };
  return await Delete(`${config.addressMicroserviceUrlApi}/address/`, headers, body);
}

async function update(address, token) {
  const headers = {
    'Content-Type': 'application/json',
    "authorization": `Bearer ${token}`
  };
  const body = {
    data: { ...address }
  };
  return await put(`${config.addressMicroserviceUrlApi}/address/`, headers, body);
}

async function getById(id, token) {
  const headers = {
    'Content-Type': 'application/json',
    "authorization": `Bearer ${token}`
  };
  return await get(`${config.addressMicroserviceUrlApi}/address/?id=${id} `, headers);
}

async function getByIdUser(id, token) {
  const headers = {
    'Content-Type': 'application/json',
    "authorization": `Bearer ${token}`
  };
  return await get(`${config.addressMicroserviceUrlApi}/address/user/?id=${id}`, headers);
}


const functions = {
  getDistance,
  create,
  remove,
  update,
  getById,
  getByIdUser,
  getStatus
}

export default functions;