import { createSlice } from "@reduxjs/toolkit";
import { resolutions } from "../constants/web.constants";
import initialState from "./initialState";

export const webSlice = createSlice({
  name: "web",
  initialState: initialState.web,
  reducers: {
    setWebIndex: (state, payload) => {
      state.index = payload.payload;
    },
    setWebSubIndex: (state, payload) => {
      state.subIndex = payload.payload;
    },
    setSelectedComponent: (state, payload) => {
      state.selectedComponent = payload.payload;
    },
    setDesktopComponents: (state, payload) => {
      state.desktopComponents = payload.payload;
    },
    setMobileComponents: (state, payload) => {
      state.mobileComponents = payload.payload;
    },
    setTabletComponents: (state, payload) => {
      state.tabletComponents = payload.payload;
    },
    setSideBarDestinationDraggableId: (state, payload) => {
      state.sideBarDestinationDragaggleId = payload.payload;
    },
    setSideBarSourceDraggableId: (state, payload) => {
      state.sideBarSourceDragaggleId = payload.payload;
    },
    removeComponent: (state, payload) => {
      switch (payload.payload.resolution) {
        case resolutions.desktop:
          const foundIndex = state.desktopComponents.findIndex(
            (item) => item.index === payload.payload.index
          );
          state.desktopComponents.splice(foundIndex, 1);
          state.desktopComponents = state.desktopComponents.map(
            (item, i) => {
              return { ...item, index: i };
            }
          );
          state.index =
            state.desktopComponents.length > 0
              ? state.desktopComponents[0]._id
              : null;
          break;
        case resolutions.tablet:
          const foundTabletIndex = state.tabletComponents.findIndex(
            (item) => item.index === payload.payload.index
          );
          state.tabletComponents.splice(foundTabletIndex, 1);
          state.tabletComponents = state.tabletComponents.map(
            (item, i) => {
              return { ...item, index: i };
            }
          );
          state.index =
            state.tabletComponents.length > 0
              ? state.tabletComponents[0]._id
              : null;
          break;
        case resolutions.mobile:
          const foundMobileIndex = state.mobileComponents.findIndex(
            (item) => item.index === payload.payload.index
          );
          state.mobileComponents.splice(foundMobileIndex, 1);
          state.mobileComponents = state.mobileComponents.map(
            (item, i) => {
              return { ...item, index: i };
            }
          );
          state.index =
            state.mobileComponents.length > 0
              ? state.mobileComponents[0]._id
              : null;
          break;
        default:
          break;
      }
      state.updated = true;
      state.subIndex = 0;
    },
    updateComponentsFromResolution: (state, payload) => {
      switch (payload.payload.resolution) {
        case resolutions.desktop:
          state.desktopComponents[payload.payload.index] = {
            ...state.desktopComponents[payload.payload.index],
            ...payload.payload.data,
          };
          break;
        case resolutions.tablet:
          state.tabletComponents[payload.payload.index] = {
            ...state.tabletComponents[payload.payload.index],
            ...payload.payload.data,
          };
          break;
        case resolutions.mobile:
          state.mobileComponents[payload.payload.index] = {
            ...state.mobileComponents[payload.payload.index],
            ...payload.payload.data,
          };
          break;
        default:
          break;
      }
      state.updated = true;
    },
    setUpdated: (state, payload) => {
      state.updated = payload.payload;
    },
    setEditTab: (state, payload) => {
      state.editTab = payload.payload;
    },
    setShowConfigDialog: (state, payload) => {
      state.showConfigDialog = payload.payload;
    },
    reorderComponentsFromSiderBar: (state, payload) => {
      state.sideBarDestinationDragaggleId = -1;
      const components = getComponents(
        payload.payload.resolution,
        state
      );
      const items = reorder(
        components,
        payload.payload.sourceIndex,
        payload.payload.destinationIndex
      );

      switch (payload.payload.resolution) {
        case resolutions.desktop:
          state.desktopComponents = items;
          break;
        case resolutions.tablet:
          state.tabletComponents = items;
          break;
        case resolutions.mobile:
          state.mobileComponents = items;
          break;
        default:
          break;
      }
      state.updated = true;
    },
  },
});

export const {
  setShowConfigDialog,
  setWebIndex,
  setWebSubIndex,
  setSelectedComponent,
  setDesktopComponents,
  setMobileComponents,
  setTabletComponents,
  updateComponentsFromResolution,
  removeComponent,
  setUpdated,
  setEditTab,
  reorderComponentsFromSiderBar,
  setSideBarDestinationDraggableId,
  setSideBarSourceDraggableId,
} = webSlice.actions;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const selectSideBarDestinationDragaggleId = (state) =>
  state.web.sideBarDestinationDragaggleId;
export const selectSideBarSourrceDragaggleId = (state) =>
  state.web.sideBarSourceDragaggleId;
export const selectShowConfigDialog = (state) => state.web.showConfigDialog;
export const selectWebIndex = (state) => state.web.index;
export const selectWebSubIndex = (state) => state.web.subIndex;
export const selectSelectedComponent = (state) => state.web.selectedComponent;
export const selectDesktopComponents = (state) =>
  state.web.desktopComponents;
export const selectMobileComponents = (state) =>
  state.web.mobileComponents;
export const selectTabletComponents = (state) =>
  state.web.tabletComponents;
export const selectUpdated = (state) => state.web.updated;
export const selectEditTab = (state) => state.web.editTab;
export const selectComponents = (resolution) => (state) => {
  switch (resolution) {
    case resolutions.desktop:
      return state.web.desktopComponents;
    case resolutions.tablet:
      return state.web.tabletComponents;
    case resolutions.mobile:
      return state.web.mobileComponents;
    default:
      return state.web.desktopComponents;
  }
};

const getComponents = (resolution, state) => {
  switch (resolution) {
    case resolutions.desktop:
      return state.desktopComponents;
    case resolutions.tablet:
      return state.tabletComponents;
    case resolutions.mobile:
      return state.mobileComponents;
    default:
      return state.desktopComponents;
  }
};

export const getComponent = (resolution, id) => (state) => {
  switch (resolution) {
    case resolutions.desktop:
      return state.web.desktopComponents.find((item) => item._id === id);
    case resolutions.tablet:
      return state.web.tabletComponents.find((item) => item._id === id);
    case resolutions.mobile:
      return state.web.mobileComponents.find((item) => item._id === id);
    default:
      return null;
  }
};

export default webSlice.reducer;
