import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const OrdersPage = lazy (() => import ('../containers/ordersToVerify'));

export default function OrdersRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <OrdersPage />
    </Suspense>
  );
}
