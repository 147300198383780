import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';
import service from '../utils/services/order';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initialState.checkout,
  reducers: {
    receiveServicesCheckout: (state, action) => {
      state.services.splice(0, state.services.length);
      state.services.push(...action.payload);
    },
  },
});

export const {receiveServicesCheckout} = checkoutSlice.actions;

export const getCheckoutServicesByIdCompany = async (id, token, dispatch) => {
  const result = await service.getCheckoutServicesByIdCompany(id, token);
  dispatch(receiveServicesCheckout(result));
};

export const selectServicesCheckout = (state) => state.checkout.services;

export default checkoutSlice.reducer;
