import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const MethodsSettingsPage = lazy(() =>
  import("../components/settings/payments/methods.Settings")
);

const MercadopagoSettingsPage = lazy(() =>
  import("../components/settings/payments/mercadoPagoPaymentSettings")
);

export function MercadopagoPaymentSettingsRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <MercadopagoSettingsPage />
    </Suspense>
  );
}

export function MethodsSettingsRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <MethodsSettingsPage />
    </Suspense>
  );
}
