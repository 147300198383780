import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const Page = lazy(() =>
    import("../containers/menu")
);

export default function MenuRoute() {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Page />
        </Suspense>
    );
}