import { isString } from "lodash";
import config from "../config";
import { get, put, Delete, post, getHeaders, getBlob } from "../http";
import jwt from "jsonwebtoken";

async function updateOrder(data, token) {
  const body = {
    ...data,
  };
  const headers = {
    authorization: `Bearer ${token}`,
  };
  return await put(
    `${config.ordersMicroserviceUrlApi}/v2/order/`,
    headers,
    body
  );
}

async function getOrderById(id, token) {
  const headers = {
    authorization: `Bearer ${token}`,
  };
  return await get(
    `${config.ordersMicroserviceUrlApi}/v2/order/?id=${id}`,
    headers
  );
}

export async function getBoleta(id, companyId) {
  return await get(
    `${config.ordersMicroserviceUrlApi}/v2/order/getBoleta?id=${id}&companyId=${companyId}`,
    {}
  );
}

async function getOrdersByIdCompany(
  {
    idCompany,
    limit,
    page,
    order,
    orderBy,
    searchText,
    searchField,
    byStatus,
    byPayment,
    byDate,
    startDate,
    endDate,
    subsidiaryId,
    origin,
    populate,
    query,
  },
  token
) {
  const headers = {
    authorization: `Bearer ${token}`,
  };
  var newQuery = "";
  if (query && query !== "")
    newQuery = `${newQuery}&query=${jwt.sign(query, config.jwtSecret)}`;
  if (subsidiaryId && subsidiaryId !== "")
    newQuery = `${newQuery}&subsidiaryId=${subsidiaryId}`;
  if (idCompany && idCompany !== "")
    newQuery = `${newQuery}&idCompany=${idCompany}`;
  if (limit !== undefined && limit !== "")
    newQuery = `${newQuery}&limit=${limit}`;
  if (page !== undefined && page !== "") newQuery = `${newQuery}&page=${page}`;
  if (order && order !== "") newQuery = `${newQuery}&order=${order}`;
  if (orderBy && orderBy !== "") newQuery = `${newQuery}&orderBy=${orderBy}`;
  if (searchText && searchText !== "")
    newQuery = `${newQuery}&searchText=${searchText}`;
  if (searchField && searchField !== "")
    newQuery = `${newQuery}&searchField=${searchField}`;
  if (byStatus && isString(byStatus) &&  byStatus !== "")
    newQuery = `${newQuery}&byStatus=${byStatus}`;
  if (byPayment && byPayment !== "")
    newQuery = `${newQuery}&byPayment=${byPayment}`;
  if (byDate && byDate !== "") newQuery = `${newQuery}&byDate=${byDate}`;
  if (startDate && startDate !== "")
    newQuery = `${newQuery}&startDate=${startDate}`;
  if (endDate && endDate !== "") newQuery = `${newQuery}&endDate=${endDate}`;
  if (origin && origin !== "") newQuery = `${newQuery}&origin=${origin}`;
  if (populate && populate !== "")
    newQuery = `${newQuery}&populate=${populate}`;

  return await get(
    `${
      config.ordersMicroserviceUrlApi
    }/v2/order/ByIdCompany?${newQuery.substring(1)}`,
    headers,
    {},
    true
  );
}

async function updateStatus(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = { ...data };

  return put(
    `${config.ordersMicroserviceUrlApi}/v2/order/status`,
    headers,
    body
  );
}

async function deleteCheckout(checkout, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = { checkout };
  return Delete(
    `${config.ordersMicroserviceUrlApi}/order/checkout`,
    headers,
    body
  );
}

async function getOrderStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(`${config.ordersMicroserviceUrlApi}/system/status`, headers);
}

async function getServiceCheckoutByIdCheckout(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.ordersMicroserviceUrlApi}/order/getServiceCheckoutByIdCheckout?id=${id}`,
    headers
  );
}

async function getServiceCheckoutById(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.ordersMicroserviceUrlApi}/order/getServiceCheckoutById?id=${id}`,
    headers
  );
}

async function getCheckoutServicesByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.ordersMicroserviceUrlApi}/order/getCheckoutServicesByIdCompany?id=${id}`,
    headers
  );
}

async function getCheckoutById(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.ordersMicroserviceUrlApi}/order/getCheckoutById?id=${id}`,
    headers
  );
}

async function verifyOrder(id, verify) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    data: { id, verify },
  };
  return await put(
    `${config.ordersMicroserviceUrlApi}/v2/order/verifyOrder`,
    headers,
    body
  );
}

export async function getOrdersByIdUser(idUser, token) {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.ordersMicroserviceUrlApi}/v2/order/ByIdUser?id=${idUser}`,
    headers
  );
}

const createLocalOrderV2 = async (order, quotes, token) => {
  const headers = {
    authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const body = { order, quotes };

  return await post(
    `${config.ordersMicroserviceUrlApi}/v2/order/local`,
    headers,
    body
  );
};

const partialLocalOrderV2 = async (partial, orderId, token) => {
  const headers = {
    authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const body = { partial, orderId };

  return await put(
    `${config.ordersMicroserviceUrlApi}/v2/order/partialLocal`,
    headers,
    body
  );
};

const createTestOrderV2 = async (
  companyId,
  userId,
  subsidiaryId,
  productsQty
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = { companyId, userId, subsidiaryId, productsQty };

  return await post(
    `${config.ordersMicroserviceUrlApi}/v2/order/createTestOrderV2`,
    headers,
    body
  );
};

async function getOrdersBySubsidiaryV2(companyId, subsidiaryId, token = "") {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.ordersMicroserviceUrlApi}/v2/order/bySubsidiary?idCompany=${companyId}&subsidiaryId=${subsidiaryId}`,
    headers
  );
}

async function getUsersMap(companyId, subsidiaryId, token = "") {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.ordersMicroserviceUrlApi}/v2/order/getUsersMap?idCompany=${companyId}&subsidiaryId=${subsidiaryId}`,
    headers
  );
}

export async function getOrdersByBankDiscount(
  companyId,
  discountId,
  token = ""
) {
  return await get(
    `${config.ordersMicroserviceUrlApi}/v2/order/getByBankDiscount?companyId=${companyId}&discountId=${discountId}`,
    getHeaders(token)
  );
}

export const saveSupportFlow = async (supportData) =>
  await post(
    `${config.ordersMicroserviceUrlApi}/v2/order/support`,
    getHeaders(),
    supportData
  );

async function getIncidents(
  { companyId, limit, page, order, orderBy, searchText, searchField },
  token
) {
  const headers = {
    authorization: `Bearer ${token}`,
  };

  var query = "";
  if (companyId && companyId !== "") query = `${query}&companyId=${companyId}`;
  if (limit !== undefined && limit !== "") query = `${query}&limit=${limit}`;
  if (page !== undefined && page !== "") query = `${query}&page=${page}`;
  if (order && order !== "") query = `${query}&order=${order}`;
  if (orderBy && orderBy !== "") query = `${query}&orderBy=${orderBy}`;
  if (searchText && searchText !== "")
    query = `${query}&searchText=${searchText}`;
  if (searchField && searchField !== "")
    query = `${query}&searchField=${searchField}`;

  return await get(
    `${config.ordersMicroserviceUrlApi}/v2/order/incidents?${query.substring(
      1
    )}`,
    headers,
    {},
    true
  );
}

const createManualOrderToken = (companyId) =>
  get(
    `https://api.lumarketo.cl/manualOrderToken?companyId=${companyId}`,
    {},
    {},
    true
  );

  async function getOrdersWithoutPagination(
    {
      idCompany,
      limit,
      page,
      order,
      orderBy,
      searchText,
      searchField,
      byStatus,
      byPayment,
      byDate,
      startDate,
      endDate,
      subsidiaryId,
      origin,
      populate,
      query,
    },
    token
  ) {
    const headers = {
      authorization: `Bearer ${token}`,
    };
    var newQuery = "";
    if (query && query !== "")
      newQuery = `${newQuery}&query=${jwt.sign(query, config.jwtSecret)}`;
    if (subsidiaryId && subsidiaryId !== "")
      newQuery = `${newQuery}&subsidiaryId=${subsidiaryId}`;
    if (idCompany && idCompany !== "")
      newQuery = `${newQuery}&idCompany=${idCompany}`;
    if (limit !== undefined && limit !== "")
      newQuery = `${newQuery}&limit=${limit}`;
    if (page !== undefined && page !== "") newQuery = `${newQuery}&page=${page}`;
    if (order && order !== "") newQuery = `${newQuery}&order=${order}`;
    if (orderBy && orderBy !== "") newQuery = `${newQuery}&orderBy=${orderBy}`;
    if (searchText && searchText !== "")
      newQuery = `${newQuery}&searchText=${searchText}`;
    if (searchField && searchField !== "")
      newQuery = `${newQuery}&searchField=${searchField}`;
    if (byStatus && isString(byStatus) &&  byStatus !== "")
      newQuery = `${newQuery}&byStatus=${byStatus}`;
    if (byPayment && byPayment !== "")
      newQuery = `${newQuery}&byPayment=${byPayment}`;
    if (byDate && byDate !== "") newQuery = `${newQuery}&byDate=${byDate}`;
    if (startDate && startDate !== "")
      newQuery = `${newQuery}&startDate=${startDate}`;
    if (endDate && endDate !== "") newQuery = `${newQuery}&endDate=${endDate}`;
    if (origin && origin !== "") newQuery = `${newQuery}&origin=${origin}`;
    if (populate && populate !== "")
      newQuery = `${newQuery}&populate=${populate}`;
  
    return await get(
      `${
        config.ordersMicroserviceUrlApi
      }/v2/order/withoutPagination?${newQuery.substring(1)}`,
      headers,
      {},
      true
    );
  }

  async function getCsvFinance(
    {
      idCompany,
      order,
      orderBy,
      searchText,
      searchField,
      byStatus,
      byPayment,
      byDate,
      startDate,
      endDate,
      subsidiaryId,
      origin,
      populate,
      query,
    },
    token
  ) {
    const headers = {
      authorization: `Bearer ${token}`,
    };
    var newQuery = "";
    if (query && query !== "")
      newQuery = `${newQuery}&query=${jwt.sign(query, config.jwtSecret)}`;
    if (subsidiaryId && subsidiaryId !== "")
      newQuery = `${newQuery}&subsidiaryId=${subsidiaryId}`;
    if (idCompany && idCompany !== "")
      newQuery = `${newQuery}&idCompany=${idCompany}`;
    if (order && order !== "") newQuery = `${newQuery}&order=${order}`;
    if (orderBy && orderBy !== "") newQuery = `${newQuery}&orderBy=${orderBy}`;
    if (searchText && searchText !== "")
      newQuery = `${newQuery}&searchText=${searchText}`;
    if (searchField && searchField !== "")
      newQuery = `${newQuery}&searchField=${searchField}`;
    if (byStatus && isString(byStatus) &&  byStatus !== "")
      newQuery = `${newQuery}&byStatus=${byStatus}`;
    if (byPayment && byPayment !== "")
      newQuery = `${newQuery}&byPayment=${byPayment}`;
    if (byDate && byDate !== "") newQuery = `${newQuery}&byDate=${byDate}`;
    if (startDate && startDate !== "")
      newQuery = `${newQuery}&startDate=${startDate}`;
    if (endDate && endDate !== "") newQuery = `${newQuery}&endDate=${endDate}`;
    if (origin && origin !== "") newQuery = `${newQuery}&origin=${origin}`;
    if (populate && populate !== "")
      newQuery = `${newQuery}&populate=${populate}`;
  
    return await getBlob(
      `${
        config.ordersMicroserviceUrlApi
      }/v2/order/csv-finance?${newQuery.substring(1)}`,
      headers,
      {},
      true
    );
  }

const orderService = {
  getOrdersByBankDiscount,
  getUsersMap,
  getOrdersBySubsidiaryV2,
  createTestOrderV2,
  getOrdersByIdUser,
  updateOrder,
  getOrderById,
  getOrdersByIdCompany,
  getOrderStatus,
  getServiceCheckoutByIdCheckout,
  getServiceCheckoutById,
  getCheckoutServicesByIdCompany,
  deleteCheckout,
  getCheckoutById,
  verifyOrder,
  updateStatus,
  getBoleta,
  createLocalOrderV2,
  partialLocalOrderV2,
  saveSupportFlow,
  getIncidents,
  createManualOrderToken,
  getOrdersWithoutPagination,
  getCsvFinance
};

export default orderService;
