import { Toolbar } from "@material-ui/core";
// import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
// import Routes, { SideBarIcons } from "../../constants/routes";
// import webConstants from "../../constants/web.constants";
import styles from "./toolbar.module.css";

const InfoToolbar = () => {
  return (
    <Toolbar className={styles.tutoToolbar}>
      <div className="row">
        <div className="col-12 text-center">
          <a
            href="https://www.youtube.com/watch?v=uMuoc1Wg3KQ"
            target="_blank"
            rel="noreferrer"
          >
            <h6 className={styles.tutoLink}>Ver video tutorial</h6>
          </a>
        </div>
      </div>
    </Toolbar>
  );
};

export default InfoToolbar;
