/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCompany, getCompany } from "../../../../reducers/companySlice";
import companyService from "../../../../utils/services/company";
import { useDispatch } from "react-redux";
import {
  selectLoadingUI,
  loadingOn,
  loadingOff,
} from "../../../../reducers/uiSlice";
import Toolbar from "../../../card/toolbar";
import { Notification } from "../../../../containers/notification";
import { useSnackbar } from "notistack";
import { selectUser } from "../../../../reducers/userSlice";
import {
  selectChangesDeposit,
  setChanges,
} from "../../../../reducers/depositSlice";
import { createChangeRecord } from "../../../../reducers/depositChangesSlice";
import dateUtils from "../../../../utils/date";
import InputComponent from "../../../inputs";
import logo from "../../../../assets/img/analytics.png";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";

// CSS

import styles from "./styles.module.css";

export default function GoogleAnalytics() {
  const [gaID, setGaID] = useState("");
  const [updated, setUpdated] = useState(false);
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingUI);
  const changes = useSelector(selectChangesDeposit);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (company.GoogleAnalytics) initialize();
  }, [company]);

  const initialize = () => {
    if (company.GoogleAnalytics) {
      setGaID(company.GoogleAnalytics.GAID);
    }
  };

  const updateChanges = (fieldName, newValue) => {
    const newChanges = [];
    newChanges.push(...changes);
    var index = -1;
    const change = {
      text: "",
      oldValue: null,
      newValue: null,
      date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
    };

    change.newValue = newValue;
    change.oldValue =
      company && company.GoogleAnalytics && company.GoogleAnalytics[fieldName]
        ? company.GoogleAnalytics[fieldName]
        : "";

    switch (fieldName) {
      case "GAID":
        change.text = "Cambios en el Traking ID de la tienda";
        break;
      default:
        break;
    }

    index = newChanges.findIndex(
      (item) => item.text.toString() === change.text.toString()
    );

    if (index !== -1) {
      change.oldValue = newChanges[index].oldValue;
      newChanges[index] = change;
    } else {
      newChanges.push(change);
    }
    dispatch(setChanges(newChanges));
  };

  const handleCancel = () => initialize();

  const handleSave = () => {
    dispatch(loadingOn());
    companyService
      .updateCompany(
        company._id,
        {
          _id: company._id,
          GoogleAnalytics: {
            ...company.GoogleAnalytics,
            GAID: gaID,
          },
        },
        user.token
      )
      .then((res) => {
        dispatch(loadingOff());
        getCompany(company._id, dispatch);
        enqueueSnackbar(
          Notification({
            text: "Datos de la tienda actualizados",
            variant: "success",
            withDetails: false,
          })
        );
        setUpdated(false);
        createChangeRecord(
          {
            user: user,
            username: user.username,
            fullName: `${user.firstName} ${user.lastName}`,
            idUser: user.id,
            idCompany: company._id,
            idObject: company._id,
            module: "settings",
            submodule: "google",
            createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
            changes: changes,
          },
          user.token,
          dispatch
        ).catch((err) => console.log(err));
      })
      .catch((err) => {
        enqueueSnackbar(
          Notification({
            text: "Error al actualizar datos de la tienda",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };
  const handleClose = () => {
    navigate(-1);
  };
  return (
    <div className={styles.parentContainer}>
      <button className={styles.backButton} onClick={() => handleClose()}>
        <span>
          <ArrowBackIcon />
        </span>
        Regresar
      </button>

      <div className={styles.infoContainer}>
        <div className={styles.titleLogoContainer}>
          <Toolbar title="Google Analytics" />
          <img src={logo} alt="logo" className={styles.imageElement} />
        </div>
        <div className={styles.infoDataContainer}>
          <p className={styles.textDescription}>
            Google Analytics es una herramienta gratuita para realizar un
            seguimiento de las interacciones realizadas en tu tienda.
          </p>
          <div className={styles.inputStackContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.labelInput}>ID de seguimiento</label>

              <InputComponent
                placeholder="Ej. UA-XXXXXX-Y"
                type="text"
                handler={(e) => {
                  setUpdated(true);
                  updateChanges("GAID", e.target.value);
                  setGaID(e.target.value);
                }}
                disabled={loading}
                validation={gaID === ""}
                value={gaID}
              />
            </div>
            <div className={styles.buttonsContainer}>
              <button
                disabled={!updated}
                className={styles.cancelButton}
                onClick={() => {
                  setUpdated(true);
                  handleCancel();
                }}
              >
                Cancelar
              </button>
              <button
                disabled={!updated}
                className={styles.confirmButton}
                onClick={() => {
                  setUpdated(true);
                  handleSave();
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
