import { lazy, Suspense } from 'react';
import LoadingPage from '../containers/loading';

const Page = lazy(() => import('../containers/plans'));

function PlansRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default PlansRoute;
