/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import storage from "../utils/storage";
import useQuery from "../utils/hooks/queryParameters";
import { selectCompany } from "../reducers/companySlice";
import { useDispatch, useSelector } from "react-redux";
import { loadingOff, loadingOn, selectSubsidiary } from "../reducers/uiSlice";
import { login, logout, success } from "../reducers/userSlice";
import config from "../utils/config";
import { useSnackbar } from "notistack";
import { Notification } from "../containers/notification";
import jwt from "jsonwebtoken";

function PrivateRoute({
  Component,
  redirectPath,
  inverseValidation = false,
  useRoleValidation = false,
  roles = [],
  roleRedirectPath,
  props = {},
}) {
  const company = useSelector(selectCompany);
  const [redirect, setRedirect] = useState(false);
  const query = useQuery(useLocation);
  const mail = query.get("mail");
  const hash = query.get("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const subsidiaryId = useSelector(selectSubsidiary);

  useEffect(() => {
    if (!mail || !hash || mail === "" || hash === "") {
      const lumarketo = storage.get(true);
      if (lumarketo && lumarketo.user && lumarketo.user.token) {
        console.log("user logged");
        if (useRoleValidation) {
          const found = roles.find((role) => role === lumarketo.user.role);
          setRedirect(!inverseValidation ? !found : found);
        } else setRedirect(!inverseValidation ? false : true);
      } else setRedirect(!inverseValidation ? true : false);
    } else if (mail && hash && mail !== "" && hash !== "") {
      handleLogin(mail, hash.replace('"', "").replace('"', ""));
    }
  }, [mail, hash, company]);

  const handleLogin = (email, password) => {
    if (company._id) {
      dispatch(loadingOn());
      login(email, password, "Chile", company._id, dispatch)
        .then((res) => {
          const decodedUser = jwt.verify(res, config.jwtSecret);
          if (decodedUser) {
            if (
              decodedUser.role.includes("admin") ||
              decodedUser.role.includes("picker") ||
              decodedUser.role.includes("manager")
            ) {
              let user = success(res, company._id, subsidiaryId, dispatch);
              storage.set({ user: res });
              enqueueSnackbar(
                Notification({
                  text: `Bienvenido ${user.firstName}`,
                  variant: "success",
                  withDetails: false,
                })
              );
              navigate("/");
            } else {
              setRedirect(true);
              enqueueSnackbar(
                Notification({
                  text: "Su usuario no tiene permisos para acceder.",
                  variant: "error",
                  withDetails: false,
                })
              );
              logout(dispatch);
            }
          } else {
            enqueueSnackbar(
              Notification({
                text: "Hubo un error al obtener la información del usuario.",
                variant: "error",
                withDetails: false,
              })
            );
          }
          dispatch(loadingOff());
        })
        .catch((err) => {
          console.log(err);
          setRedirect(true);
          enqueueSnackbar(
            Notification({
              text: err,
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    }
  };

  return redirect ? (
    <Navigate push to={useRoleValidation ? roleRedirectPath : redirectPath} />
  ) : (
    <Component {...props} />
  );
}

PrivateRoute.propTypes = {
  Component: PropTypes.elementType,
  path: PropTypes.string,
  redirectPath: PropTypes.string,
};

export default PrivateRoute;
