import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';
const Page = lazy (() => import ('../containers/categories'));

function CategoriesRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default CategoriesRoute;
