import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const WebPage = lazy (() => import ('../containers/web'));

export default function WebRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <WebPage />
    </Suspense>
  );
}
