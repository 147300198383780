import { Toolbar } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Routes from "../../constants/routes";
import styles from "./toolbar.module.css";
import arrowBackIcon from "../../assets/img/arrowBack.svg";
import { useSelector } from "react-redux";
import { selectCompany } from "../../reducers/companySlice";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge } from "react-bootstrap";

const ModuleWebToolbar = () => {
  const company = useSelector(selectCompany);

  return (
    <Toolbar className={styles.toolbar}>
      <div>
        <div className={styles.navContainer}>
          <div>
            <Link
              component={RouterLink}
              to={`${Routes.HOME.path}`}
              style={{
                textDecoration: "none",
                marginLeft: 0,
              }}
            >
              <h6 className={styles.goBack}>
                <span className={styles.arrowIcon}>
                  <img src={arrowBackIcon} alt="arrow" />
                </span>
                Regresar
              </h6>
            </Link>
          </div>
          <div>
            <div className={styles.verticalDivider} />
          </div>
          <div>
            <h6 className={styles.name}>
              Nombre de la tienda:{" "}
              <span>
                <p style={{ fontWeight: 400, marginLeft: 5, marginBottom: 0 }}>
                  {" "}
                  {company.Name}
                </p>
              </span>
            </h6>
          </div>
        </div>
      </div>
      <div>
        <img
          src="https://s3.amazonaws.com/haya.lumarketo.cl/white_transparent22-svvfix.svg"
          alt="Lumarketo"
          width={200}
        />
      </div>
      <div>
        <div className={styles.informationContainer}>
          <div>
            <a
              href="https://wa.me/56958765629?text=%C2%A1Hola!%20Tengo%20una%20duda%20con%20la%20plataforma."
              rel="noopener noreferrer"
              target="_blank"
            >
              <button className="btn blueEditionCancelButton2">
                <p
                  className="blueEditionCancelButtonText2"
                  style={{ fontSize: 11 }}
                >
                  Agendar una video-llamada con un diseñador(a)
                </p>
              </button>
            </a>
          </div>
          <div>
            <div className={styles.verticalDivider} />
          </div>
          <div>
            <h6 className={styles.informationLabel}>
              Atención
              <span>
                <Badge
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  color="secondary"
                >
                  <NotificationsIcon style={{ color: "#ff" }} />
                </Badge>
              </span>
            </h6>{" "}
          </div>
        </div>
      </div>
    </Toolbar>
  );
};

export default ModuleWebToolbar;
