import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const CustomerServicePage = lazy (() =>
  import ('../containers/customerService')
);

const CustomerServiceInfoPage = lazy (() =>
  import ('../containers/customerService/info.component')
);

function CustomerServiceRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <CustomerServicePage />
    </Suspense>
  );
}

export function CustomerServiceInfoRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <CustomerServiceInfoPage />
    </Suspense>
  );
}

export default CustomerServiceRoute;
