import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers";

export const store = () => {
  const middleware = [];
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
  });
};
