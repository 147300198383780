import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const Page = lazy (() =>
  import ('../components/deposit/productForm/viewProduct.component')
);

export function ProductRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default ProductRoute;
