import React from "react";
import {
  withStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import classnames from "classnames";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import cssStyles from "./styles.module.css";

function RefundDialog({
  handleClose,
  classes,
  open,
  handleAccept,
  orderNumber,
  fullName,
  operationId,
  paymentMethod,
  loading,
}) {
  return (
    <MuiThemeProvider theme={theme} key={orderNumber}>
      <Dialog aria-labelledby="dimensions-dialog-title" open={open}>
        <DialogTitle id="dimensions-dialog-title">Rechazar orden</DialogTitle>
        <DialogContent>
          <h6 className="dynamicFontClass">
            ¿Está usted seguro de <strong>rechazar</strong> y de generar el
            reembolso del pedido {orderNumber} de {fullName} con el código de
            transacción {operationId}?
          </h6>
          <p
            className="noteText"
            style={{ padding: "0 20px", marginTop: 15, display: "flex" }}
          >
            NOTA: Se hará el reintegro automático desde su cuenta{" "}
            {paymentMethod} y se le notificará al cliente.
          </p>

          {paymentMethod === "mercadopago" && (
            <p
              className="noteText"
              style={{
                padding: "0 20px",
                marginTop: 15,
                display: "flex",
                color: "red",
              }}
            >
              Tiene 48 horas para hacer el reembolso automático por este medio.
              De lo contrario deberá hacer el reembolso manualmente.
            </p>
          )}
          {paymentMethod === "getnet" && (
            <p
              className="noteText"
              style={{
                padding: "0 20px",
                marginTop: 15,
                display: "flex",
                color: "red",
              }}
            >
              Tiene 24 horas para hacer el reembolso automático por este medio.
              De lo contrario deberá hacer el reembolso manualmente.
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <div
            className={classnames(
              classes.margin,
              classes.padding,
              cssStyles.containerButtons
            )}
          >
            <button
              disabled={loading}
              color="primary"
              onClick={() => handleClose()}
              className="btn blueEditionCancelButton"
            >
              <p className="blueEditionCancelButtonText">Cancelar</p>
            </button>
            <button
              disabled={loading}
              color="primary"
              onClick={() => handleAccept()}
              className="btn blueEditionSaveButton"
            >
              {!loading ? (
                <p
                  className="blueEditionSaveButtonText"
                  style={{ textTransform: "initial" }}
                >
                  Rechazar y hacer devolución
                </p>
              ) : (
                <div className="col-12 text-center">
                  <CircularProgress
                    style={{
                      color: "#fff",
                      height: 25,
                      width: 25,
                    }}
                  />
                </div>
              )}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

const styles = {
  textField: {
    width: "100%",
  },
  margin: {
    margin: 5,
  },
  padding: {
    padding: 10,
  },
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto, sans-serif !important",
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "rgba(255, 117, 0, 0.5)",
        },
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: "column !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: "-5%",
        marginLeft: "5%",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#122526 !important",
        "&$focused": {
          color: "#122526 !important",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottom: "2px solid #122526",
        },
        "&:after": {
          borderBottom: "2px solid #122526",
        },
        "&:before": {
          // borderBottom: '2px solid #122526'
        },
      },
    },
    MuiFormControl: {
      root: {
        width: 100,
        margin: "0px 5px",
      },
      marginNormal: {
        marginTop: -8,
        marginBottom: 15,
      },
    },
  },
  MuiPaper: {
    root: {
      margin: 4,
    },
  },
  MuiDialogTitle: {
    root: {
      textAlign: "center",
    },
  },
});

export default withStyles(styles)(RefundDialog);
