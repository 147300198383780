/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { Notification } from "../../containers/notification";
import { useSnackbar } from "notistack";
import { loadingOff, loadingOn, selectLoadingUI } from "../../reducers/uiSlice";
import css from "./styles.module.css";
import uuid from "react-uuid";
import productService from "../../utils/services/products";
import { selectUser } from "../../reducers/userSlice";
import { selectCompany } from "../../reducers/companySlice";

function PlusVideoButton({ width, height, path = "", alt = "", callback }) {
  const classes = useStyles();
  const loading = useSelector(selectLoadingUI);
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const inputId = `input-${Date.now() * 3}`;
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const readerManager = new FileReader();
    const file = event.target.files[0];

    if (file.size > 1000024) {
      enqueueSnackbar(
        Notification({
          text: "El video es muy grande!",
          variant: "error",
          withDetails: false,
        })
      );
      event.target.value = "";
    } else {
      try {
        readerManager.onloadend = () => {
          const result = readerManager.result;
          uploadImg(file, result);
        };

        readerManager.readAsDataURL(file);
        dispatch(loadingOn());
      } catch (error) {
        console.log(error);
      } finally {
        event.target.value = null;
      }
    }
  };

  const uploadImg = (file, base64, tinified = false) => {
    const name = `${uuid()}-${file.name}`;
    productService
      .uploadVideoFromBase64(
        tinified ? `data:${file.type};base64,${base64}` : base64,
        name,
        file.type,
        name,
        user.token || "",
        company._id
      )
      .then(async (res) => {
        if (callback)
          callback({
            location: res.Location,
            key: name,
          });
        enqueueSnackbar(
          Notification({
            text: "Nuevo video subido correctamente.",
            variant: "success",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          Notification({
            text: "Error al subir el video.",
            variant: "error",
            withDetails: false,
          })
        );
        dispatch(loadingOff());
      });
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <input
          className={classNames(css.margin, css.textField, css.input)}
          id={inputId}
          disabled={loading}
          accept="video/mp4"
          type="file"
          max-file-size="1024"
          onChange={handleChange}
          style={{ display: "none" }}
          ref={hiddenFileInput}
        />
        <ButtonBase
          focusRipple
          key={uuid()}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          disabled={loading}
          style={{
            width: width ? width : "",
            height: height,
          }}
          onClick={handleClick}
        >
          <span className={classes.imageSrc}>
            <video
              width={width}
              height={height}
              autoPlay={true}
              controls={false}
              muted={true}
              loop={true}
            >
              <source src={path} />
              Your browser does not support the video tag.
            </video>
          </span>
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              Cargar video
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      </div>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
  },
  image: {
    border: "1px dashed #000",
    position: "relative",
    height: 110,
    marginBottom: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.35,
      },
      "& $imageMarked": {
        opacity: 1,
      },
      "& $imageTitle": {
        // border: "4px solid currentColor",
        display: "unset",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#122526",
    opacity: 0.01,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
    display: "none",
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

export default PlusVideoButton;
