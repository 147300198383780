import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const ModuleWebPage = lazy(() =>
  import("../components/web/home/homeV2.component")
);

export default function WebRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ModuleWebPage />
    </Suspense>
  );
}
