import {lazy, Suspense} from 'react';
import LoadingPage from '../containers/loading';

const EmailSettingsPage = lazy (() =>
  import ('../components/settings/email/emailSettings.component')
);

export default function EmailSettingsRoute () {
  return (
    <Suspense fallback={<LoadingPage />}>
      <EmailSettingsPage />
    </Suspense>
  );
}
