import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export const now = () => moment.now();

export const timestamp = (date) => moment(date).unix();

export const format = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");
export const formatDate = (date, format = "YYYY-MM-DD") => moment(date).format(format);
const timeFormat = (time, format = "hh:mm:ss a") => moment(time).format(format);

const formatFromUTC = (date) => moment(date).local().format("YYYY-MM-DD");
const timeFormatFromUTC = (time, format = "hh:mm:ss") =>
  moment(time).local().format(format);

const isBetweenDate = (date, start, end) =>
  moment(date).isBetween(moment(start), moment(end));
const isBetweenDateWithDuration = (date, amount, duration) => {
  var beforeTime = moment(date),
    afterTime = moment(date).add(Number(amount), duration);
  return moment(now()).isBetween(beforeTime, afterTime);
};

const getDay = () => {
  switch (moment(now()).day()) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 0:
      return "Sunday";
    default:
      return "Monday";
  }
};

const isBetweenHour = (datetime, start, end) => {
  var format = 'HH:mm:ss';
  var time = moment(moment.tz(datetime, 'America/Santiago').format(format), format),
    beforeTime = moment(start, format),
    afterTime = moment(end, format);
  return time.isBetween(beforeTime, afterTime);
};


const getUTC = (date) => moment(date).utc().format();

const translateDuration = (duration) => {
  switch (duration) {
    case "año":
      return "year";
    case "años":
      return "years";
    case "meses":
      return "months";
    case "semanas":
      return "weeks";
    case "mes":
      return "month";
    case "semana":
      return "week";
    default:
      return "";
  }
};

const translateDay = (day) => {
  switch (day) {
    case "Monday":
      return "Lunes";
    case "Tuesday":
      return "Martes";
    case "Wednesday":
      return "Miércoles";
    case "Thursday":
      return "Jueves";
    case "Friday":
      return "Viernes";
    case "Saturday":
      return "Sábado";
    case "Sunday":
      return "Domingo";
    default:
      return "Monday";
  }
};

const isBefore = (date) => moment(now()).isBefore(date);

const isAfter = (after, date = now()) => moment(date).isAfter(after);

const prepareIntervals = (interval, startTime, endTime) => {
  var intervals = [];

  var inputDataFormat = "hh:mm:ss";
  var outputFormat = "hh:mm a";

  var tmp = moment(interval, inputDataFormat);
  var dif = tmp - moment().startOf("day");

  var startIntervalTime = moment(startTime, inputDataFormat).add(-dif, "ms");
  var endIntervalTime = moment(startTime, inputDataFormat);
  var finishTime = moment(endTime, inputDataFormat);

  while (startIntervalTime < finishTime) {
    var format = {
      startTime: startIntervalTime.format(outputFormat),
      endTime: endIntervalTime.format(outputFormat),
    };
    intervals.push(format);
    startIntervalTime.add(dif, "ms");
    endIntervalTime.add(dif, "ms");
  }

  return intervals;
};


const getMoment = (date) => moment(date);

export const translateMomentMonth = (month) => {
  switch (month) {
    case 0:
      return "Enero";
    case 1:
      return "Febrero";
    case 2:
      return "Marzo";
    case 3:
      return "Abril";
    case 4:
      return "Mayo";
    case 5:
      return "Junio";
    case 6:
      return "Julio";
    case 7:
      return "Agosto";
    case 8:
      return "Septiembre";
    case 9:
      return "Octubre";
    case 10:
      return "Noviembre";
    case 11:
      return "Diciembre";
    default:
      return "Enero";
  }
};

const dateUtils = {
  prepareIntervals,
  now,
  format,
  timeFormat,
  isBetweenHour,
  isBetweenDate,
  getUTC,
  timeFormatFromUTC,
  formatFromUTC,
  isBetweenDateWithDuration,
  translateMomentMonth,
  translateDuration,
  isBefore,
  isAfter,
  timestamp,
  formatDate,
  getDay,
  translateDay,
  getMoment,
  moment
};

export default dateUtils;
