import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';

export const fontSlice = createSlice ({
  name: 'fonts',
  initialState: initialState.fonts,
  reducers: {
    receiveFonts: (state, action) => {
      state.splice (0, state.length);
      state.push (...action.payload);
    },
    receiveFont: (state, action) => {
      const index = state.findIndex (item => item === action.payload);
      if (index !== -1) state[index] = action.payload;
      else state.push (action.payload);
    },
    deleteFont: (state, action) => {
      const index = state.findIndex (item => item === action.payload);
      state.splice (index, 1);
    },
  },
});

export const {receiveFont, receiveFonts, deleteFont} = fontSlice.actions;

export const selectFonts = state => state.fonts;

export default fontSlice.reducer;
