import React from "react";
import { withStyles } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Downshift from "downshift";
import MuiTextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import uuid from "react-uuid";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
    width: "103%",
  },
  container: {
    flexGrow: 1,
    position: "relative",
    width: "100%",
  },
  paper: {
    position: "absolute",
    zIndex: 12,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
    maxHeight: 150,
    overflowY: "auto",
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
    width: "100%",
    backgroundColor: "#122526",
    color: "#fff",
    ">svg": {
      color: "#fff",
    },
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, width, ...other } = inputProps;

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        style={{ width }}
        variant="outlined"
        className="inputText"
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    </MuiThemeProvider>
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.value) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function MultiAutocompleteComponent({ inputValue,
  handleChange,
  selectedItem,
  classes,
  handleInputChange,
  handleKeyDown,
  getSuggestions,
  handleDelete,
  placeholder,
  width,
  disabled }) {
  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            disabled: disabled || false,
            classes,
            InputProps: getInputProps({
              startAdornment: <div
                className="row"
                style={{ width: "100%" }}
                key={uuid()}
              >{selectedItem.map((item) => (

                <div className="col-12">
                  <MuiThemeProvider theme={theme}>
                    <Chip
                      className={classes.chip}
                      style={{
                        color: "#fff",
                        backgroundColor: "#122526",
                      }}
                      color="primary"
                      key={uuid()}
                      tabIndex={-1}
                      label={item.label}
                      onDelete={handleDelete(item.value)}
                    />
                  </MuiThemeProvider>
                </div>
              ))}</div>,
              onChange: handleInputChange,
              onKeyDown: handleKeyDown,
              placeholder,
              width,
            }),
            width
          })}
          {isOpen ? (
            <Paper className={classes.paper} square>
              {getSuggestions(inputValue2).map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({ item: suggestion }),
                  highlightedIndex,
                  selectedItem: selectedItem2,
                })
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

MultiAutocompleteComponent.propTypes = {
  inputValue: PropTypes.any.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  selectedItem: PropTypes.array.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiChip: {
      root: {
        color: "#fff",
        backgroundColor: "rgb(35, 47, 62)",
        width: 'auto !important"'
      },
      deleteIcon: {
        color: "#fff !important",
      },
      deleteIconColorPrimary: {
        color: "#fff",
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: 35,
      },
      notchedOutline: {
        border: "0.1px solid gray",
        borderColor: "gray !important",
        borderRadius: 0,
      },
      // "$&focused": {
      //   border: "2px solid #122526",
      //   borderColor: "#122526",
      // },
      adornedEnd: {
        padding: "0 !important",
      },
      input: {
        padding: 10,
      },
    },
    MuiTextField: {
      root: {
        minHeight: 35,
        margin: "10px 0px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        color: "#7A7A7A",
      },
    },
    MuiInput: {
      root: {
        border: "none",
      },
    },
    MuiPaper: {
      root: {
        zIndex: 1200
      }
    }
  },
});

const TextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(MuiTextField);

export default withStyles(styles)(MultiAutocompleteComponent);
