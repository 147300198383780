import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const DiscountMarketingPage = lazy(() =>
  import("../components/marketing/discount/discount")
);

const ViewDiscountPage = lazy(() =>
  import("../components/marketing/discount/view/viewDiscount.component")
);

export function DiscountMarketingRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <DiscountMarketingPage />
    </Suspense>
  );
}

export function ViewDiscountRoute({ mode = "update" }) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ViewDiscountPage mode={mode} />
    </Suspense>
  );
}
