import config from "../config";
import { get, put, Delete, post } from "../http";

async function getByIdCompany(id) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.companyMicroserviceUrlApi}/payment/getByIdCompany?id=${id}`,
    headers
  );
}

async function create(payment, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...payment },
  };
  return await post(
    `${config.companyMicroserviceUrlApi}/payment/`,
    headers,
    body
  );
}

async function createRefund(
  { amount, idCompany, commerceOrder, email },
  token
) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    amount,
    idCompany,
    commerceOrder,
    email,
    urlCallback: `${config.paymentServiceMicroserviceUrlApi}/v1/flow/callBack-refund?idCompany=${idCompany}`,
  };
  return await post(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/create-refund`,
    headers,
    body
  );
}

async function getRefund({ token, idCompany }, userToken) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${userToken}`,
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/get-refund?token=${token}&idCompany=${idCompany}`,
    headers
  );
}

async function getOrder({ token, idCompany }, userToken) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${userToken}`,
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/get-order?token=${token}&idCompany=${idCompany}`,
    headers
  );
}

async function getPaymentMercadopago({ companyId, paymentId }, userToken) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${userToken}`,
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/mercadopago?paymentId=${paymentId}&companyId=${companyId}`,
    headers
  );
}

async function update(payment, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: payment,
  };
  return await put(
    `${config.companyMicroserviceUrlApi}/payment/`,
    headers,
    body
  );
}

async function DeleteCategory(payment, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...payment },
  };
  return await Delete(
    `${config.companyMicroserviceUrlApi}/payment/`,
    headers,
    body
  );
}

async function getStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/system/status`,
    headers
  );
}

async function createMPPreapprovalPlan({
  companyId,
  frequency,
  frequencyType,
  amount,
}) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await post(
    `${config.paymentServiceMicroserviceUrlApi}/v1/mercadopago/preapprovalPlan`,
    headers,
    {
      companyId,
      frequency,
      frequencyType,
      amount,
    }
  );
}

async function getMPPreapproval(planId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/mercadopago/preapproval?planId=${planId}`,
    headers
  );
}

async function getMPPreapprovalPlan(planId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/mercadopago/preapprovalPlan?planId=${planId}`,
    headers
  );
}

async function createFlowPlan({
  amount,
  interval,
  intervalCount,
  planId,
  urlCallBack,
  customerName,
  email,
  userId,
  hayaLink,
  name,
  companyId,
  internalPlanId,
  trial_period_days,
  days_until_due,
  registerTDC,
}) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await post(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/create-plan`,
    headers,
    {
      amount,
      interval,
      intervalCount,
      planId,
      urlCallBack,
      customerName,
      email,
      userId,
      hayaLink,
      name,
      companyId,
      internalPlanId,
      trial_period_days,
      days_until_due,
      registerTDC,
    }
  );
}

async function deleteFlowPlan(planId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await Delete(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/delete-plan`,
    headers,
    {
      planId,
    }
  );
}

async function getTDCRegisterStatus(token) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/get-register-status?token=${token}`,
    headers
  );
}

async function createFlowSubscriptionPlan({
  planId,
  customerId,
  subscriptionStart,
}) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await post(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/create-subscription`,
    headers,
    {
      planId,
      customerId,
      subscriptionStart,
    }
  );
}

async function getFlowSubscription(subscriptionId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/flow/get-subscription?subscriptionId=${subscriptionId}`,
    headers
  );
}

async function getGetnetPayment(orderId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.paymentServiceMicroserviceUrlApi}/v1/getnet?orderId=${orderId}`,
    headers
  );
}

export const getAllCompanyPayments = async ({
  page,
  limit,
  order,
  orderBy,
  searchField,
  searchText,
  id,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `https://api.lumarketo.cl/companyPayment/all?page=${page}&limit=${limit}&order=${order}&orderBy=${orderBy}&searchText=${searchText}&searchField=${searchField}&id=${id}`,
    headers
  );
};

const paymentsService = {
  getByIdCompany,
  create,
  DeleteCategory,
  update,
  createRefund,
  getRefund,
  getOrder,
  getStatus,
  getPaymentMercadopago,
  createMPPreapprovalPlan,
  getMPPreapproval,
  getMPPreapprovalPlan,
  createFlowPlan,
  getTDCRegisterStatus,
  createFlowSubscriptionPlan,
  getFlowSubscription,
  deleteFlowPlan,
  getGetnetPayment,
};

export default paymentsService;
