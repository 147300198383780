import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputComponent from "../../inputs";
import PlusImgButton from "../../button/plusImg";
import { CircularProgress, InputAdornment } from "@material-ui/core";
import useQuery from "../../../utils/hooks/queryParameters";
import { createTemplate, getTemplates } from "../../../utils/services/company";
import { useSnackbar } from "notistack";
import { Notification } from "../../../containers/notification";
import successIcon from "../../../assets/img/icon-alert-success.png";
import errorIcon from "../../../assets/img/icon-alert-error.png";
import { useDispatch, useSelector } from "react-redux";
import { loadingOn, loadingOff } from "../../../reducers/uiSlice";
import { selectCompany } from "../../../reducers/companySlice";

export default function CreateTemplateComponentDialog({ show, handleClose }) {
  const company = useSelector(selectCompany);
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [validatingTitle, setValidatingTitle] = useState(false);
  const [availableTitle, setAvailableTitle] = useState(false);
  const query = useQuery(useLocation);
  const token = query.get("token");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    async function validate() {
      setValidatingTitle(true);
      const templates = await getTemplates().catch((err) => {
        console.log(err);
        return [];
      });
      if (templates && templates.length > 0) {
        const found = templates.find((item) => item.Name === title);
        setAvailableTitle(!found);
      }
      setValidatingTitle(false);
    }
    if (title !== "") validate();
  }, [title]);

  const handleClick = async () => {
    dispatch(loadingOn());
    const result = await createTemplate({
      Name: title,
      Img: icon,
      Home: company.Home,
    }).catch((err) => {
      console.log(err);
      enqueueSnackbar(
        Notification({
          text: err,
          variant: "error",
          withDetails: false,
        })
      );
      return null;
    });
    if (result) {
      enqueueSnackbar(
        Notification({
          text: "Template creado exitosamente.",
          variant: "success",
          withDetails: false,
        })
      );
      handleClose();
    }
    dispatch(loadingOff());
  };

  return (
    <span>
      <Dialog
        open={show}
        aria-labelledby="form-dialog-title"
        onClose={() => handleClose()}
      >
        <DialogTitle id="form-dialog-title">Guardar Template</DialogTitle>
        <DialogContent>
          <Typography variant="h6" align="center">
            ¿Esta seguro de que desea guardar este Template?
          </Typography>
          <div className="row" style={{ padding: "10px 5%" }}>
            <div className="col-3">
              <h6
                className="inputTitle"
                style={{ lineHeight: 3, fontWeight: 600 }}
              >
                Nombre:
              </h6>
            </div>
            <div className="col-9">
              <InputComponent
                validation={false}
                placeholder="Escribe el nombre de la personalización"
                value={title}
                handler={(e) => setTitle(e.target.value)}
                helperText={"Este campo es obligatorio"}
                style={{ color: "#000" }}
                useBlueBackground={false}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {validatingTitle ? (
                        <CircularProgress
                          style={{
                            width: 25,
                            height: 25,
                            color: "#000",
                            margin: 5,
                          }}
                        />
                      ) : availableTitle ? (
                        <img src={successIcon} alt="success" width={25} />
                      ) : (
                        <img src={errorIcon} alt="error" width={25} />
                      )}
                    </InputAdornment>
                  ),
                }}
                width={400}
              />
            </div>
            <div className="col-4">
              <h6
                className="inputTitle"
                style={{ lineHeight: 3, fontWeight: 600 }}
              >
                Imagen:
              </h6>
            </div>
            <div className="col-8">
              <PlusImgButton
                withHistory={false}
                path={icon}
                width={200}
                height={200}
                callback={(img) => setIcon(img.location)}
                text="Subir capture"
                subtext=""
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={() => handleClose()} className="btn backButton">
            Cancelar
          </button>
          <button
            disabled={
              !token ||
              token === "" ||
              title === "" ||
              icon === "" ||
              !availableTitle ||
              validatingTitle
            }
            onClick={() => {
              if (
                !(
                  !token ||
                  token === "" ||
                  title === "" ||
                  icon === "" ||
                  !availableTitle ||
                  validatingTitle
                )
              )
                handleClick();
            }}
            className="btn addButton"
          >
            Guardar
          </button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
