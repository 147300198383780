import { lazy, Suspense } from "react";
import LoadingPage from "../containers/loading";

const Page = lazy(() => import("../containers/sales"));

export function SalesRoute() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Page />
    </Suspense>
  );
}

export default SalesRoute;
