/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import productService from "../../services/products";

const useGetProducts = (company, token, deps = []) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);

      try {
        const fetchProducts = await productService.getProductByIdCompany(
          company?._id,
          token
        );
        if (fetchProducts) {
          setProducts(fetchProducts);
        }
      } catch (error) {
        console.log("error trying to fetch products", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (company && company?._id !== "") {
      getProducts();
    }
  }, [company, ...deps]);

  return { products, isLoading };
};

export const useGetPaginateProducts = (
  companyId,
  limit,
  page,
  order,
  orderBy,
  searchText,
  searchField,
  token,
  deps = []
) => {
  const [products, setProducts] = useState({
    count: 0,
    hasNextPage: false,
    hasPrevPage: false,
    items: [],
    limit: 20,
    next: null,
    page: 1,
    pageCount: 1,
    pagingCounter: 1,
    prev: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);

      try {
        const fetchProducts = await productService.getPaginateProducts(
          companyId,
          limit,
          page,
          order,
          orderBy,
          searchText,
          searchField,
          token
        );
        if (fetchProducts) {
          setProducts(fetchProducts);
        }
      } catch (error) {
        console.log("error trying to fetch products", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (companyId !== "") {
      getProducts();
    }
  }, [
    companyId,
    limit,
    page,
    order,
    orderBy,
    searchText,
    searchField,
    ...deps,
  ]);

  return { products, isLoading };
};

export const useGetSingleProductById = (productId, deps = []) => {
  const [product, setProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getSingleProduct = async () => {
      setIsLoading(true);

      try {
        const fetchProducts = await productService.getProductById(productId);
        if (fetchProducts) {
          setProduct(fetchProducts);
        }
      } catch (error) {
        console.log("error trying to fetch product", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (productId && productId !== "") {
      getSingleProduct();
    }
  }, [productId, ...deps]);

  return { product, isLoading };
};

export default useGetProducts;
