import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//IMPORTS
import App from "../containers/app";
import userConstants from "../constants/user.constants";
import routes from "../constants/routes";
import HomeRoute from "./home";
import LoginRoute from "./login";
import DepositRoute from "./deposit";
import { ProductRoute } from "./product";
import CategoryRoute from "./categories";
import PricesRoute from "./prices";
import InventaryRoute from "./inventary";
import {
  DepositChangesRoute,
  OrderChangesRoute,
  SettingsChangesRoute,
} from "./changesRecords";
import {
  ServicesRoute,
  ViewServicesRoute,
  ServiceUsersRoute,
} from "./services";
import GeneralSettingsRoute from "./generalSettings";
import ShippingSettingsRoute, {
  ShippingModuleRoute,
  ViewShippingModuleRoute,
  ShippingPreferenceRoute,
} from "./shippingSettings";
import PickersSettingsRoute from "./pickersSettings";
import GoogleSettingsRoute from "./googleSettings";
import { MercadopagoPaymentSettingsRoute } from "./paymentsSettings";
import UsersRoute from "./users";
import { ViewDiscountRoute, DiscountMarketingRoute } from "./discountMarketing";
import { ViewCouponRoute, CouponMarketingRoute } from "./couponMarketing";
import { ViewEmailRoute, EmailMarketingRoute } from "./emailMarketing";
import { OrdersRoute, OrderInfoRoute, CreateOrderRoute } from "./orders";
import OrdersToVerifyRoute from "./ordersToVerify";
import WebRoute from "./web";
import CustomerServiceRoute, {
  CustomerServiceInfoRoute,
} from "./customerService";
import EmailSettingsRoute from "./emailSettings";
import ResetPasswordRoute from "./resetPassword";
import PrivateRoute from "./private";
import NotMatchRoute from "./notMatch";
import BsaleRoute from "./bsaleSettings";
import ReviewsRoute from "./reviews";
import SubsidiaryRoute from "./subsidiary";
import ModuleWebRoute from "./moduleWeb";
import DndRoute from "./dnd";
import PlansRoute from "./plans";
import MarketingIntegrationsRoute from "./marketingIntegrations";
import AdditionalsRoute from "./additional";
import MenuRoute from "./menu";
import QrSurveyRoute from "./qrsurvey";
import CompanyPaymentSettingsRoute from "./companyPaymentSettings";
import {
  MailChimpMarketingRoute,
  NewTemplateMailChimpMarketingRoute,
  NewCampaignMailChimpMarketingRoute,
} from "./mailChimpMarketing";
import BookingsRoute from "./booking";
import IncidentsRoute from "./incidents";
import SalesRoute from "./sales";
import SubsidiaryInfoRoute from "./subsidiaryInfo";
import FacebookComponent from "../components/marketing/integrations/facebook";
import FudoComponent from "../components/marketing/integrations/fudo";
import GoogleAnalytics from "../components/marketing/integrations/google/analytics";
import GoogleTagManager from "../components/marketing/integrations/google/tagManager";
import IngefacturaComponent from "../components/marketing/integrations/ingefactura";
import SimphonyOracleComponent from "../components/marketing/integrations/oracle/simphony";
import ToteatComponent from "../components/marketing/integrations/toteat";
import GetnetPaymentSettings from "../components/settings/payments/getnetPaymentSettings";
import MercadoPagoPaymentSettings from "../components/settings/payments/mercadoPagoPaymentSettings";
import Pedidosya from "../components/settings/shippingFulfillment/pedidosya";
import Cabify from "../components/settings/shippingFulfillment/cabify";
import Uber from "../components/settings/shippingFulfillment/uber";
import MailChimpComponent from "../components/marketing/integrations/mailchimp";

export default function RoutesComponent(props) {
  return (
    <Router>
      <App>
        <Routes>
          <Route
            exact
            path={`${routes.INCIDENTS.path}`}
            element={<IncidentsRoute />}
          />
          <Route exact path={`${routes.SALES.path}`} element={<SalesRoute />} />
          <Route
            exact
            path={`${routes.MAILCHIMP_MARKETING.path}`}
            element={<MailChimpMarketingRoute />}
          />
          <Route
            exact
            path={`${routes.MAILCHIMP_MARKETING.path}/:index`}
            element={<MailChimpMarketingRoute />}
          />
          <Route
            exact
            path={`${routes.MAILCHIMP_MARKETING.path}/campaigns/new`}
            element={<NewCampaignMailChimpMarketingRoute />}
          />
          <Route
            exact
            path={`${routes.MAILCHIMP_MARKETING.path}/campaigns/update`}
            element={<NewCampaignMailChimpMarketingRoute />}
          />
          <Route
            exact
            path={`${routes.MAILCHIMP_MARKETING.path}/templates/new`}
            element={<NewTemplateMailChimpMarketingRoute />}
          />
          <Route
            exact
            path={`${routes.MAILCHIMP_MARKETING.path}/templates/update`}
            element={<NewTemplateMailChimpMarketingRoute />}
          />
          <Route
            exact
            path={routes.SHIPPING_PREFERENCE.path}
            element={<ShippingPreferenceRoute />}
          />
          <Route
            exact
            path={routes.QR_SURVEY.path}
            element={<QrSurveyRoute />}
          />
          <Route exact path={routes.LOGIN.path} element={<LoginRoute />} />
          <Route exact path={"/test/dnd"} element={<DndRoute />} />
          <Route
            exact
            path={routes.RESETPASSWORD.path}
            element={<ResetPasswordRoute />}
          />
          <Route
            exact
            path={routes.BOOKINGS.path}
            element={<BookingsRoute />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_FACEBOOK_PIXEL.path}
            element={<FacebookComponent />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_FUDO.path}
            element={<FudoComponent />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_GOOGLE_ANALYTICS.path}
            element={<GoogleAnalytics />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_GOOGLE_TAG_MANAGER.path}
            element={<GoogleTagManager />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_INGEFACTURAS.path}
            element={<IngefacturaComponent />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_MAILCHIMP.path}
            element={<MailChimpComponent />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_ORACLE.path}
            element={<SimphonyOracleComponent />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_TOTEAT.path}
            element={<ToteatComponent />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_GETNET.path}
            element={<GetnetPaymentSettings />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_MERCADOPAGO.path}
            element={<MercadoPagoPaymentSettings />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_PEDIDOSYA.path}
            element={<Pedidosya />}
          />
          <Route
            exact
            path={routes.INTEGRATIONS_CABIFY.path}
            element={<Cabify />}
          />{" "}
          <Route
            exact
            path={routes.INTEGRATIONS_UBER.path}
            element={<Uber />}
          />
          <Route exact path={routes.REVIEWS.path} element={<ReviewsRoute />} />
          <Route
            exact
            path={`${routes.COMPANY_PAYMENTS_SETTINGS.path}`}
            element={
              <PrivateRoute
                Component={CompanyPaymentSettingsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.MENU.path}`}
            element={
              <PrivateRoute
                Component={MenuRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.EMAIL_SETTINGS.path}`}
            element={
              <PrivateRoute
                Component={EmailSettingsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.SUBSIDIARIES.path}`}
            element={
              <PrivateRoute
                Component={SubsidiaryRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.SUBSIDIARIES_INFO.path}`}
            element={
              <PrivateRoute
                Component={SubsidiaryInfoRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.INTEGRATIONS_SHIPPING_MODULE.path}/:id`}
            element={
              <PrivateRoute
                Component={ViewShippingModuleRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.INTEGRATIONS_SHIPPING_MODULE.path}

            element={
              <PrivateRoute
                Component={ShippingModuleRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.CUSTOMER_SERVICE.path}
            element={
              <PrivateRoute
                Component={CustomerServiceRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.CUSTOMER_SERVICE_REQUEST_INFO.path}
            element={
              <PrivateRoute
                Component={CustomerServiceInfoRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.WEB.path}
            element={
              <PrivateRoute
                Component={WebRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.ORDERS_TO_VERIFY.path}
            element={
              <PrivateRoute
                Component={OrdersToVerifyRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.ORDERS.path}
            element={
              <PrivateRoute
                Component={OrdersRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.ORDER_INFO.path}/:orderID`}
            element={
              <PrivateRoute
                Component={OrderInfoRoute}
                redirectPath={routes.LOGIN.path}
              />
            }
          />
          <Route
            exact
            path={routes.CREATE_ORDER.path}
            element={
              <PrivateRoute
                Component={CreateOrderRoute}
                redirectPath={routes.LOGIN.path}
              />
            }
          />
          <Route
            exact
            path={routes.NEW_EMAIL_MARKETING.path}
            element={
              <PrivateRoute
                Component={ViewEmailRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.EMAIL_MARKETING.path}/ver`}
            element={
              <PrivateRoute
                Component={ViewEmailRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.EMAIL_MARKETING.path}
            element={
              <PrivateRoute
                Component={EmailMarketingRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.EMAIL_MARKETING.path}
            element={
              <PrivateRoute
                Component={EmailMarketingRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.NEW_DISCOUNT_MARKETING.path}
            element={
              <PrivateRoute
                props={{ mode: "new" }}
                Component={ViewDiscountRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.NEW_COUPON_MARKETING.path}
            element={
              <PrivateRoute
                props={{ mode: "new" }}
                Component={ViewCouponRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.DISCOUNT_MARKETING.path}/ver`}
            element={
              <PrivateRoute
                Component={ViewDiscountRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.COUPON_MARKETING.path}/ver`}
            element={
              <PrivateRoute
                Component={ViewCouponRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.DISCOUNT_MARKETING.path}
            element={
              <PrivateRoute
                Component={DiscountMarketingRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.COUPON_MARKETING.path}
            element={
              <PrivateRoute
                Component={CouponMarketingRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.USERS.path}
            element={
              <PrivateRoute
                Component={UsersRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.MERCADOPAGO_PAYMENTS.path}
            element={
              <PrivateRoute
                Component={MercadopagoPaymentSettingsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.BSALE_SETTINGS.path}
            element={
              <PrivateRoute
                Component={BsaleRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.PICKERS_SETTINGS.path}
            element={
              <PrivateRoute
                Component={PickersSettingsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.SHIPPING_SETTINGS.path}
            element={
              <PrivateRoute
                Component={ShippingSettingsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.GOOGLE_SETTINGS.path}
            element={
              <PrivateRoute
                Component={GoogleSettingsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.GENERAL_SETTINGS.path}
            element={
              <PrivateRoute
                Component={GeneralSettingsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.SERVICES.path}
            element={
              <PrivateRoute
                Component={ServicesRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.SERVICES.path}/ver`}
            element={
              <PrivateRoute
                Component={ViewServicesRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.SERVICES_USER.path}
            element={
              <PrivateRoute
                Component={ServiceUsersRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.NEW_SERVICES.path}
            element={
              <PrivateRoute
                Component={ViewServicesRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.ORDERS_CHANGES.path}
            element={
              <PrivateRoute
                Component={OrderChangesRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.SETTINGS_CHANGES.path}
            element={
              <PrivateRoute
                Component={SettingsChangesRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.DEPOSIT_CHANGES.path}
            element={
              <PrivateRoute
                Component={DepositChangesRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.INVENTARY.path}
            element={
              <PrivateRoute
                Component={InventaryRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.PRICES.path}
            element={
              <PrivateRoute
                Component={PricesRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.CATEGORY.path}
            element={
              <PrivateRoute
                Component={CategoryRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.CATEGORIES.path}
            element={
              <PrivateRoute
                Component={CategoryRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.DEPOSIT.path}/crear`}
            element={
              <PrivateRoute
                Component={ProductRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={`${routes.DEPOSIT.path}/modificar/:productID`}
            element={
              <PrivateRoute
                Component={ProductRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.DEPOSIT.path}
            element={
              <PrivateRoute
                Component={DepositRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.HOME.path}
            element={
              <PrivateRoute
                Component={HomeRoute}
                redirectPath={routes.LOGIN.path}
              />
            }
          />
          <Route
            exact
            path={routes.MODULE_WEB.path}
            element={
              <PrivateRoute
                Component={ModuleWebRoute}
                redirectPath={routes.LOGIN.path}
              />
            }
          />
          <Route
            exact
            path={routes.INTEGRATIONS_NATIVE.path}
            element={
              <PrivateRoute
                Component={MarketingIntegrationsRoute}
                redirectPath={routes.LOGIN.path}
              />
            }
          />
          <Route
            exact
            path={routes.ADDITIONAL.path}
            element={
              <PrivateRoute
                Component={AdditionalsRoute}
                redirectPath={routes.LOGIN.path}
                useRoleValidation={true}
                roles={[userConstants.ROLES.ADMIN, userConstants.ROLES.MANAGER]}
                roleRedirectPath={routes.HOME.path}
              />
            }
          />
          <Route
            exact
            path={routes.PLANS.path}
            element={
              <PrivateRoute
                Component={PlansRoute}
                redirectPath={routes.LOGIN.path}
              />
            }
          />
          <Route path="*" element={<NotMatchRoute />} />
        </Routes>
      </App>
    </Router>
  );
}
