import config from "../config";
import { get, put, Delete, post } from "../http";

export async function getByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.servicesMicroserviceUrlApi}/services/byIdCompany?id=${id}`,
    headers
  );
}

export async function getById(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.servicesMicroserviceUrlApi}/services/?id=${id}`,
    headers
  );
}

export async function create(service, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ...service,
  };
  return await post(
    `${config.servicesMicroserviceUrlApi}/services/`,
    headers,
    body
  );
}

export async function update(service, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ...service,
  };
  return await put(
    `${config.servicesMicroserviceUrlApi}/services/`,
    headers,
    body
  );
}

export async function remove(service, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    ...service,
  };
  return await Delete(
    `${config.servicesMicroserviceUrlApi}/services/`,
    headers,
    body
  );
}
export async function getStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.servicesMicroserviceUrlApi}/system/status`,
    headers
  );
}

const functions = {
  getByIdCompany,
  create,
  remove,
  update,
  getById,
  getStatus,
};

export default functions;
