import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Remove from "@material-ui/icons/Delete";

export default function WarningDeleteDialog({ handleClick, color = "#fff" }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <IconButton
        size="small"
        style={{
          color,
          borderRadius: "0 !important",
        }}
        onClick={() => handleClickOpen()}
      >
        <Remove fontSize="inherit" style={{ height: 15, width: 15 }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">¡Advertencia!</DialogTitle>
        <DialogContent>
          <Typography variant="h6" align="center">
            ¿Esta seguro de eliminar este elemento?
          </Typography>
          <Typography variant="body1" align="center">
            Una vez eliminado no podrá deshacer esta acción
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="btn backButton">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleClick();
            }}
            className="btn addButton"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
