import {createSlice} from '@reduxjs/toolkit';
import initialState from './initialState';

export const inventaryRecordSlice = createSlice({
  name: 'inventaryRecord',
  initialState: initialState.inventaryRecord,
  reducers: {
    receiveInventaryRecord: (state, action) => {
      state.list.splice(0, state.list.length);
      state.list.push(...action.payload);
    },
    receiveInventaryRecordUpdate: (state, action) => {
      const newState = [];
      newState.push(...state.list);
      const index = state.list.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index !== -1) newState[index] = action.payload;
      state.list.splice(0, state.list.length);
      state.list = newState;
    },
    setData: (state, action) => {
      state.auxiliar = {...action.payload};
    },
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
  },
});

export const {
  receiveInventaryRecord,
  receiveInventaryRecordUpdate,
  setData,
  open,
  close,
} = inventaryRecordSlice.actions;

export const selectInventaryRecord = (state) => state.inventaryRecord;
export const selectListInventaryRecord = (state) => state.inventaryRecord.list;
export const selectOpenInventaryRecord = (state) => state.inventaryRecord.open;

export default inventaryRecordSlice.reducer;
